<template>
    <div class="living">
        <div class="mobile_wrap" v-if="topNavShow">
            <mobNav :activeNav="activeNav"></mobNav>
        </div>

        <div class="mainview_itoken_wrap">
            <div class="living_wrap">
                <div class="living_commer" v-if="uploadimg">
                    <div class="living_commer_imgs">
                        <img class="living_commer_img" :src="uploadimg" alt="">
                        <div class="living_commer_imgerror" v-if="!verfiysucc">
                            <img src="@/assets/mobimg/living_commer_imgerror.png" alt="">
                        </div>
                    </div>
                </div>
                <div class="living_commer" v-else>
                    <div class="img">
                        <!--<div class="img-bg"></div>-->
                        <!--<div class="img-line"></div>-->
                        <img src="@/assets/mobimg/face_tipsnew.gif" alt="">
                    </div>
                </div>

            </div>

            <div class="living_mdesc" :class="!verfiysucc ? 'living_mdescerr' : ''">
                <p>
                    Coloque su cara en el marco y no la mueva
                </p>
                <p>
                    después de hacer el clic <span>"Inciar la verificación"</span>
                </p>
            </div>

            <div class="living_mbtns">
                <div class="living_mbtn" @click="gotoStart">
                    <van-button round color="#00CA88" block style="height: 1.222222rem;">
                        <span style="font-weight: bold;">Tomar foto</span>
                    </van-button>
                </div>
            </div>
            <div class="living_footer_wrap">
                <div class="living_footer">
                    <img src="@/assets/mobimg/living_footer_img.png" alt="">
                </div>
            </div>
        </div>

        <input type="file" accept="image/*" capture="user" ref="videoFile" @change="changeVideo" class="file-input" hidden />


        <van-popup v-model="messageShow" :close-on-click-overlay="false">
            <div class="living_popwrap">
                <div class="living_poptitle">Detalles del préstamo</div>
                <div class="living_popdesc">Para solicitar un préstamo, debe confirmar que está solicitando en persona y asegurarse de que su dinero no ingrese a las cuentas de otras personas. Así que ahora necesitas hacer una verificación facial.</div>
                <div class="living_popimgs">
                    <img src="@/assets/mobimg/living_popimgs.png" alt="">
                </div>
                <div class="living_popbtns" @click="onClosepop">
                    <van-button round color="#00CA88" block style="height: 40px;">
                        <span style="font-weight: bold; font-size: 16px;">Verificación facial
                            <template v-if="nums > 0">
                                （{{ verval }}）
                            </template>
                        </span>
                    </van-button>
                </div>
            </div>
        </van-popup>

    </div>
</template>

<script>

    import mobNav from "@/components/mobNav.vue";
    import scrollUpdown from "@/mixins/index";
    import { verifyLive } from '@/api/file';

    let clock = null;

    export default {
        name: 'compoundeLiving',
        components: { mobNav },
        mixins: [scrollUpdown],
        data() {
            return {
                activeNav: 'living',
                nums: 4,
                verval: '',
                messageShow: true,
                uploadimg: null,
                verfiysucc: true,
            }
        },
        mounted() {
            this.livingVer();
        },
        methods: {
            doLoop() {
                this.nums--;
                if(this.nums > 0){
                    this.verval = this.nums + 's';
                } else {
                    clearInterval(clock);
                    this.verval = '';
                    this.nums = 0;
                }
            },
            livingVer() {
                let _this = this;
                this.verval = this.nums + 's';
                clock = setInterval(_this.doLoop, 1000);
            },
            onClosepop() {
                if(this.nums == 0){
                    this.messageShow = false;
                }
            },
            gotoStart() {
                this.uploadimg = null;
                this.verfiysucc = true;
                this.$refs.videoFile.value = null;
                this.$refs.videoFile.dispatchEvent(new MouseEvent('click'));
            },
            changeVideo() {
                console.log('活体检测')
                let that = this;
                const files = that.$refs.videoFile.files;

                if (files && files.length > 0) {
                    let file = files[0];

                    console.log(file)

                    if (file.type.indexOf("image/") == -1) {
                        that.$toast("Formato de foto incorrecto"); //照片格式错误
                        return;
                    }

                    // TODO:压缩视频
                    if (file.size > 10 * 1024 * 1024) {
                        that.$toast('Tamaño de foto excedido por favor tome otra foto'); //照片大小超限，超出 10 MB,请重新录制
                        return;
                    }
                    that.$toast.loading({
                        message: '上传中...',
                        duration: 0,
                        forbidClick: true
                    });
                    let reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = () => {
                        let result = reader.result;
                        //that.faceReco(result, file.size)
                        that.faceReco(file, file.size, result)

                        /*let img = new Image();
                        img.src = reader.result;
                        img.onload = () => {
                            let originWidth = img.naturalWidth,
                                originHeight = img.naturalHeight,
                                originSize = file.size;
                            this.compressEvent(img, originSize, originWidth, originHeight);
                        };*/
                    }
                }
            },
            compressEvent(img, originSize, originWidth, originHeight) {
                // 将图片绘制到canvas上进行压缩
                let canvas = document.createElement("canvas"),
                    context = canvas.getContext("2d"),
                    maxSize = this.maxSize;
                if (originSize <= maxSize) {
                    return false;
                }
                let mul = originSize / maxSize,
                    targetWidth = originWidth / mul,
                    targetHeight = originHeight / mul;
                canvas.width = targetWidth;
                canvas.height = targetHeight;
                context.clearRect(0, 0, targetWidth, targetHeight); // 清除画布
                context.drawImage(img, 0, 0, targetWidth, targetHeight); // 画图

                let dataURL = canvas.toDataURL("image/jpeg", 0.1); // 将其转化成base64字符串

                let blob = this.dataURItoBlob(dataURL); // 转化为blob格式

                console.log(blob)

                let _this = this;

                _this.$toast.loading({
                    message: 'Bajo verificación...', //验证中
                    duration: 0,
                    forbidClick: true
                });

                let useaccessToken = this.$store.state.common.accessToken;

                let forms = new FormData();
                forms.append("fs1", blob);
                forms.append('fs1_size', originSize)

                verifyLive(forms, useaccessToken, 'Rapidayuda').then(res => {
                    //验证成功
                    _this.$toast.clear();
                    console.log(res)

                    if(res.code == 0){
                        _this.verfiysucc = true;
                        _this.uploadimg = img.src;

                        /*_this.$router.replace({
                            path: '/compoundloan/confirmloan'
                        })*/
                    } else {
                        _this.verfiysucc = false;
                        _this.uploadimg = img.src;
                    }
                }, error => {
                    console.log(error)
                    _this.$toast.clear();
                    _this.verfiysucc = false;
                    _this.uploadimg = img.src;
                });

            },
            dataURItoBlob(dataURI) {
                // base64格式转化为blob对象
                let byteString = atob(dataURI.split(",")[1]);

                let mimeString = dataURI
                    .split(",")[0]
                    .split(":")[1]
                    .split(";")[0];

                let ab = new ArrayBuffer(byteString.length);
                let ia = new Uint8Array(ab);
                for (let i = 0; i < byteString.length; i++) {
                    ia[i] = byteString.charCodeAt(i);
                }

                return new Blob([ab], { type: mimeString });
            },
            faceReco(result, fileSize, base64img){
                let _this = this;
                _this.$toast.loading({
                    message: 'Bajo verificación...', //验证中
                    duration: 0,
                    forbidClick: true
                });

                let useaccessToken = this.$store.state.common.accessToken;
                let postData = {
                    fs1: result,
                    fs1_size: fileSize,
                };

                let forms = new FormData()

                forms.append('fs1', result)
                forms.append('fs1_size', fileSize)


                verifyLive(forms, useaccessToken, 'Rapidayuda').then(res => {
                    //验证成功
                    _this.$toast.clear();
                    console.log(res)

                    if(res.code == 0){
                        _this.verfiysucc = true;
                        _this.uploadimg = base64img;

                        _this.$router.replace({
                            path: '/compoundloan/confirmloan'
                        })
                    } else {
                        _this.verfiysucc = false;
                        _this.uploadimg = base64img;
                    }
                }, error => {
                    console.log(error)
                    _this.$toast.clear();
                    _this.verfiysucc = false;
                    _this.uploadimg = base64img;
                });


                //模拟调用风控sdk
                /*setTimeout(() => {
                    //验证成功
                    _this.$toast.clear();

                    _this.verfiysucc = true;

                    _this.uploadimg = result;

                    _this.$router.push({
                        path: '/compoundloan/confirmloan'
                    })

                }, 200)*/
            }


        }
    }
</script>

<style>
    .living_wrap{
        width: 100%;
        height: auto;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        padding: 1.055556rem 0 0 0;
    }
    .living_commer{
        width: 5.166667rem;
        height: 5.166667rem;
        margin: 0 auto;
    }

    .living_commer .img {
        position: relative;
        margin: 0 auto;
        width: 5.166667rem;
        height: 5.166667rem;
    }
    @keyframes bgmove {
        from {
            height: 0;
        }
        to {
            height: 4.47rem;
        }
    }
    .living_commer .img-bg,
    .living_commer .img-line {
        position: absolute;
        top: 0.361111rem;
        left: 0.361111rem;
        width: 4.47rem;
        overflow: hidden;
    }
    .living_commer .img-bg {
        animation: bgmove 4s linear infinite;
        animation-direction: alternate;
        z-index: 1;
    }
    .living_commer .img-bg:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 4.47rem;
        height: 4.47rem;
        background: linear-gradient(0deg, transparent 0%, rgba(104, 24, 133, .3) 50%, transparent 100%);
        border-radius: 50%;
    }
    .living_commer .img-line {
        z-index: 6;
        height: 4.47rem;
        border-radius: 50%;
    }
    .living_commer .img-line:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 2px;
        border-bottom: 2px solid #2b6814;
        animation: bgmove 4s linear infinite;
        animation-direction: alternate;
    }
    .living_commer .img img {
        position: relative;
        width: 100%;
        height: 100%;
        z-index: 2;
    }
    .living_mdesc{
        width: 100%;
        height: auto;
        padding: 0.444444rem 0 1.027778rem 0;
    }
    .living_mdesc p{
        width: 100%;
        font-size: 0.388889rem;
        font-family: Roboto-Regular, Roboto;
        font-weight: 500;
        color: #1A1A1A;
        line-height: 0.5rem;
        text-align: center;
    }
    .living_mdesc p span{
        color: #00CA88;
    }
    .living_mbtns{
        width: 100%;
        height: auto;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        padding: 0 0.444444rem 1.361111rem 0.444444rem;
    }
    .living_mbtn{
        width: 100%;
        height: auto;
    }
    .living_mbtn span{
        font-size: 0.42rem;
        font-weight: 500;
    }
    .living_footer_wrap{
        width: 100%;
        height: auto;
        position: fixed;
        left: 0;
        bottom: 0.472222rem;
    }
    .living_footer{
        width: 91.11%;
        height: auto;
        margin: 0 auto;
    }
    .living_footer img{
        display: block;
        width: 100%;
    }
    .living_commer_imgs{
        width: 5.166667rem;
        height: 5.166667rem;
        position: relative;
    }
    .living_commer_img{
        display: block;
        width: 100%;
        height: 100%;
        border-radius: 0.555556rem;
    }
    .living_commer_imgerror{
        width: 1.222222rem;
        height: 1.222222rem;
        position: absolute;
        right: -0.416667rem;
        bottom: -0.416667rem;
    }
    .living_commer_imgerror img{
        display: block;
        width: 100%;
        height: 100%;
    }
    .living_mdescerr p{
        color: #DB861F;
    }
    .living_mdescerr p span{
        color: #DB861F;
    }
    .living .van-popup{
        background: rgba(255, 255, 255, 0);
    }
    .living_popwrap{
        width: 80.56vw;
        height: auto;
        background: #ffffff;
        border-radius: 0.555556rem;
        box-sizing: border-box;
        padding: 0.694444rem 0.555556rem 0.555556rem 0.555556rem;
    }
    .living_poptitle{
        width: 100%;
        height: 1.0rem;
        font-size: 0.444444rem;
        font-weight: 500;
        color: #1A1A1A;
        line-height: 0.444444rem;
        text-align: center;
    }
    .living_popdesc{
        width: 100%;
        height: auto;
        padding-bottom: 0.555556rem;
        font-size: 12px;
        font-weight: 400;
        color: #808080;
        line-height: 14px;
    }
    .living_popimgs{
        width: 100%;
        height: auto;
        padding-bottom: 0.555556rem;
    }
    .living_popimgs img{
        display: block;
        width: 100%;
        height: 2.055556rem;
    }
    .living_popbtns{
        width: 100%;
        height: 1.222222rem;
    }

</style>
