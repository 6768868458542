import axios from 'axios'
import { Toast } from 'vant';
import Store from '@/store'
import { getAccessToken, setAccessToken, removeAllToken } from '@/utils/auth'

/**
 * 加载环境变量
 */
axios.defaults.withCredentials = false;

console.log(process.env.NODE_ENV)
const service = axios.create({
    baseURL: process.env.NODE_ENV === 'production' ? process.env.VUE_APP_BASE_API : '/api',
    withCredentials: false,
    timeout: 20000,
});
window.axiosCalcelTokenArr = [];
// 请求前拦截
service.interceptors.request.use(
    config => {
        console.log(config)
        if (!config.data) {
            config.data = { _: new Date().getTime() }
        }

        return config
    },
    error => {
        return Promise.reject(error)
    }
);

const successRes = res => {
    // 接口请求成功
    return res.data
};
const successChange = res => {
    // 删除，修改记录成功
    return res.data
};
const warning = res => {
    // 请求失败，字段缺失
    Toast(res.data.message);
    return Promise.reject(res.data.message)
};
const danger = res => {
    // 请求失败，原因message
    console.log(res);
    let error = null;
    try {
        let _message = 'Excepción de red';
        let code = '600';
        if (res.data) {
            _message = res.data.message || 'Excepción de red'
            code = res.data.code || 600
        }
        error = new Error(_message);
        error.code = code;
        if (!isSilence(res)) {
            Toast.fail(_message)
        }
        console.error(error);
        if (res.data.data) {
            console.error(res.data.data)
        }
    } catch (err) {
        error = err
    }
    return Promise.reject(error)
};


const isSilence = (res) => {
    let requestParams = {};
    try {
        requestParams = Object.assign({}, res.config.params || {}, JSON.parse(res.config.data || '{}'))
    } catch (error) {
        console.log(error);
    }
    return !!requestParams['silence']
};

const loginout = () => {
    Store.commit('common/SET_ACCESS_TOKEN', '');
    Store.commit('common/SET_COUPONS', null);
    Store.commit('common/SET_LOANA_AMOUNT', '');
    Store.commit('common/SET_LOAN_DAYS', null);
    Store.commit('common/SET_BANK_DATA', null);
    Store.commit('common/SET_ISREPEAT_LOAN', false);

    localStorage.clear();

};



// 响应拦截
service.interceptors.response.use(
    res => {
        console.log(res)
        if (res.data instanceof Blob) {
            return res.data;
        }

        switch (res.data.code) {
            case 0:
                return successRes(res);
            case 200:
                return successRes(res);
            case 400114:
                return loginout()
            case 201:
                return danger(res);
            case 202:
                return danger(res);
            case 203:
                return danger(res);
            case 204:
                return danger(res);
            case 301:
                return danger(res);
            case 302:
                return danger(res);
            default:
                return danger(res)
        }
    },
    err => {
        console.log(err)
        // 处理403 重新登录逻辑
        Toast.fail(`Excepción de red, inténtelo de nuevo más tarde.`);
        return Promise.reject(err)
    }
);

export default service

