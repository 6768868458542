<template>
  <div class="common_problem_wrap_mobile">
      <div class="scale-1px">
        <van-collapse :border="mob_hasborder" :accordion="mob_isaccordion" v-model="mob_activeNames" @change="collapseChange">
          <van-collapse-item :border="mob_hasborder" name="1">
            <template #title>
              <div class="common_title_wrap" style="padding-left: 0.444444rem;">
                <div class="common_title_left">
                  <div>
                    <div class="common_title_lname">Rapidayuda</div>
                    <div class="common_title_ldesc">Préstamos 100% en línea y depósitos eficientes</div>
                  </div>
                </div>
              </div>
            </template>

            <template #right-icon>
              <div class="common_title_right" style="padding-right: 0.444444rem;">
                <div class="common_trview">
                  <img v-if="collapseIsOpen('1')" src="@/assets/mobimg/mob_navclose_icon.png" alt="" />
                  <img v-else src="@/assets/mobimg/mob_navopen_icon.png" alt="" />
                </div>
              </div>
            </template>

            <div class="common_problem_view_mobile scaletop-1px">
              <div class="common_children_wrap" style="padding-left: 0;">
                <div class="children_cont_items scale-1px" @click="getActive('couponslist')" v-if="is_login">
                  <div class="children_cont_item_left" style="font-weight: 500;" :class="activeNav == 'index' ? 'children_citactive' : ''">Mi cupon</div>
                  <div class="children_cont_item_right">
                    <div class="children_cont_item_arrow">
                      <img src="@/assets/mobimg/nav_arrow.png" alt="">
                    </div>
                  </div>
                </div>
                <div class="children_cont_items scale-1px" @click="getActive('help')">
                  <div class="children_cont_item_left" style="font-weight: 500;" :class="activeNav == 'Aviso' ? 'children_citactive' : ''">Centro de ayuda</div>
                  <div class="children_cont_item_right">
                    <div class="children_cont_item_arrow">
                      <img src="@/assets/mobimg/nav_arrow.png" alt="">
                    </div>
                  </div>
                </div>
                <!--<div class="children_cont_items scale-1px" @click="getActive('about')">
                  <div class="children_cont_item_left" style="font-weight: 500;" :class="activeNav == 'sobre' ? 'children_citactive' : ''" >Añadelo en tu pantalla</div>
                  <div class="children_cont_item_right">
                    <div class="children_cont_item_arrow">
                      <img src="@/assets/mobimg/nav_arrow.png" alt="">
                    </div>
                  </div>
                </div>-->
                <div class="children_cont_items scale-1px" @click="copy('copy')">
                  <div class="children_cont_item_left" style="font-weight: 500;" :class="activeNav == 'enlace' ? 'children_citactive' : ''" >Copiar el enlace</div>
                  <div class="children_cont_item_right">
                    <div class="children_cont_item_arrow">
                      <img src="@/assets/mobimg/nav_arrow.png" alt="">
                    </div>
                  </div>
                </div>

                <div class="children_cont_items scale-1px" @click="getDownload('Rapidayuda')">
                  <div class="children_cont_item_left" style="font-weight: 500;">Rapidayuda App</div>
                  <div class="children_cont_item_right">
                    <div class="children_cont_item_download">
                        <div class="children_cont_item_dltext">Descargar</div>
                        <div class="children_cont_item_dlicon">
                          <img src="@/assets/mobimg/nav_download.png" alt="">
                        </div>
                    </div>
                  </div>
                </div>

                <div class="children_cont_items scale-1px" @click="getDownload('VivaVida')">
                  <div class="children_cont_item_left" style="font-weight: 500;">Viva Vida App</div>
                  <div class="children_cont_item_right">
                    <div class="children_cont_item_download">
                        <div class="children_cont_item_dltext">Descargar</div>
                        <div class="children_cont_item_dlicon">
                          <img src="@/assets/mobimg/nav_download.png" alt="">
                        </div>
                    </div>
                  </div>
                </div>

                <div class="children_cont_items scale-1px" @click="onLoginOut" v-if="is_login">
                  <div class="children_cont_item_left" style="font-weight: 500;">Salir del login</div>
                  <div class="children_cont_item_right">
                    <div class="children_cont_item_arrow">
                      <img src="@/assets/mobimg/nav_arrow.png" alt="">
                    </div>
                  </div>
                </div>

                <div class="mobnavfooter">
                  <div class="mobnavfooter_img">
                    <img src="@/assets/mobimg/mobnav_footer.png" alt="">
                  </div>
                </div>

              </div>



            </div>



          </van-collapse-item>


        </van-collapse>


      </div>
  </div>

</template>

<script>
  import { getAccessToken, getIsRepeatLoan } from '@/utils/auth'

  export default {
    name: "mobNav",
    props: ["activeNav"],
    data() {
      return {
        is_login: false,
        mob_isaccordion: true,
        mob_hasborder: false,
        mob_activeNames: [],
        mob_chidone_Names: [],
      };
    },
    mounted() {
      // 判断是否登录状态
      const accessToken = getAccessToken();

      const isValid = Boolean(accessToken);

      if(isValid){
        this.is_login = true;
      }

    },
    methods: {
      onLoginOut() {
        let usepageType = this.$store.state.common.pageType;

        this.$store.commit('common/SET_ACCESS_TOKEN', '');
        this.$store.commit('common/SET_COUPONS', null);
        this.$store.commit('common/SET_LOANA_AMOUNT', '');
        this.$store.commit('common/SET_LOAN_DAYS', null);
        this.$store.commit('common/SET_BANK_DATA', null);
        this.$store.commit('common/SET_ISREPEAT_LOAN', false);

        localStorage.clear();


        if(usepageType == 'h502'){
          this.$router.replace({
            path: '/compoundloan/indextob'
          })
        } else {
          this.$router.replace({
            path: '/compoundloan/indextoa'
          })
        }


      },
      getDownload(appName){
        console.log('下载'+appName)
        if(appName == 'Rapidayuda'){
          window.location.href = 'https://rapidayuda-apk.onelink.me/lt89/ahplxorx';
        } else if(appName == 'VivaVida'){
          window.location.href = 'https://vivavida.onelink.me/v0MW/4nomodtd';
        }
      },
      collapseIsOpen(index){
        let useActiveNames = this.mob_activeNames;
        let isOpen = useActiveNames.indexOf(index);

        if(isOpen == -1){
          return false;
        } else {
          return true;
        }
      },
      chidoneIsOpen(index) {
        let useActiveNames = this.mob_chidone_Names;
        let isOpen = useActiveNames.indexOf(index);

        if(isOpen == -1){
          return false;
        } else {
          return true;
        }
      },
      collapseChange(e){
        console.log(e)
      },
      getActive(val) {
        let usePath = this.$route.path;
        if(usePath.indexOf(val) == -1){
          let url = "";
          switch (val) {
            case "couponslist":
              url = "/compoundloan/couponlist";
              break;
            case "help":
              url = "/help";
              break;
          }

          console.log(url)

          if(val == 'couponslist'){
            this.$router.push({
              path: url
            });
          } else if(val == 'help'){
            window.location.href = '/h5/customerService/CentroDeAyuda.html'
          }
        }
      },
      copy() {
        let value = 'http://www.rapidayuda.com/compoundloan/indextoa';
        var domUrl = document.createElement("input");
        domUrl.value = value;
        domUrl.id = "creatDom";
        document.body.appendChild(domUrl);
        domUrl.select(); // 选择对象
        document.execCommand("Copy"); // 执行浏览器复制命令
        let creatDom = document.getElementById("creatDom");
        creatDom.parentNode.removeChild(creatDom);
        this.$toast("Copia con éxito");
        return;
      },
    },
  };
</script>

<style>
  .mobile_wrap .children_cont_items{
    width: 100%;
    height: 1.555556rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .children_cont_item_left{
    width: auto;
    height: 1.555556rem;
    font-size: 0.444444rem;
    color: #808080;
    line-height: 1.555556rem;
  }
  .mobile_wrap .children_citactive{
    color: #2FA96B;
    font-weight: bold;
  }
  .children_cont_item_right{
    width: auto;
    height: 1.555556rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .children_cont_item_arrow{
    width: 0.277778rem;
    height: 0.388889rem;
  }
  .children_cont_item_arrow img{
    display: block;
    width: 100%;
  }
  .children_cont_item_download{
    width: auto;
    height: 0.5rem;
    display: flex;
    justify-content: flex-start;
  }
  .children_cont_item_dltext{
    width: auto;
    height: 0.5rem;
    padding-right: 0.111111rem;
    font-size: 0.388889rem;
    font-weight: 400;
    color: #00CA88;
    line-height: 0.5rem;
  }
  .children_cont_item_dlicon{
    width: 0.5rem;
    height: 0.5rem;
  }
  .children_cont_item_dlicon img{
    display: block;
    width: 100%;
  }
  .mobnavfooter{
    width: 100%;
    height: auto;
    padding-top: 1.555556rem;
  }
  .mobnavfooter_img{
    width: 9.111111rem;
    height: 3.694444rem;
  }
  .mobnavfooter_img img{
    display: block;
    width: 9.111111rem;
    height: 3.694444rem;
  }

</style>
