import request from '@/utils/request'

/** 用户登录 */
export function loginsms(data) {
    return request({
        url: 'h5/v2/auth/login/sms',
        method: 'post',
        data,
    })
}

/**
 * 发送短信验证码
 * @param {*} data
 * @returns
 */
export function getSmscode(data) {
    return request({
        url: 'h5/v2/auth/sms_code/get',
        method: 'post',
        data,
    })
}
