/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string}
 */
export function parseTime(time, cFormat) {
    if (arguments.length === 0) {
        return null
    }
    const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
    let date
    if (typeof time === 'undefined' || time === null || time === 'null') {
        return ''
    } else if (typeof time === 'object') {
        date = time
    } else {
        if ((typeof time === 'string') && (/^[0-9]+$/.test(time))) {
            time = parseInt(time)
        }
        if ((typeof time === 'number') && (time.toString().length === 10)) {
            time = time * 1000
        }
        date = new Date(time)
    }
    const formatObj = {
        y: date.getFullYear(),
        m: date.getMonth() + 1,
        d: date.getDate(),
        h: date.getHours(),
        i: date.getMinutes(),
        s: date.getSeconds(),
        a: date.getDay()
    }
    const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
        let value = formatObj[key]
        // Note: getDay() returns 0 on Sunday
        if (key === 'a') { return ['日', '一', '二', '三', '四', '五', '六'][value ] }
        if (result.length > 0 && value < 10) {
            value = '0' + value
        }
        return value || 0
    })
    return time_str
}


/**
 * JavaScript 获取指定日期的前几天或者后几天的日期
 * @param date {string} 日期 如：2022-07-12
 * @param day {number} 天数 如：1 后一天| -1 前一天，默认后1天
 * @param format {string} 时间格式 如：2022-07-13，默认 -
 * @returns {string}
 */

export function getNextDate(date, day = 1, format = "{y}-{m}-{d}") {
    if (date) {
        //处理传入年月日格式 例如 date = 2022年07月12日
        date = date.match(/\d+/g).join("-");
        //方式二处理传入年月日格式 (需要针对是否是年月日格式判断)
        //date = date.replace(/[年月日]/g, "-").slice(0, -1);
        //方式三处理传入年月日格式
        //date = date.replace(/[年月]/g, "-").replace("日", "");
        // console.log(date, "date"); // 2022-07-12
        const nDate = new Date(date);
        nDate.setDate(nDate.getDate() + day);

        const formatObj = {
            y: nDate.getFullYear(),
            m: nDate.getMonth() + 1,
            d: nDate.getDate(),
        };
        return format.replace(/{([ymd])+}/g, (result, key) => {
            const value = formatObj[key];
            return value.toString().padStart(2, "0");
        });
    } else {
        console.log("date为空或格式不正确");
    }
}

//console.log(getNextDate("2022年7月12日")); // 2022-07-13

//console.log(getNextDate("2022年7月12日",-10)); // 2022-07-02

//console.log(getNextDate("2022-7-12", 4, "{y}年{m}月{d}日")); // 2022年07月16日

//console.log(getNextDate("2022-7-12", 1, "{y}/{m}/{d}")); // 2022/07/13


export function getQueryString(name) {
    var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
    var r = window.location.search.substr(1).match(reg);
    if (r != null) {
        return unescape(r[2]);
    }
    return null;
}






