export default {
    data() {
        return {
            // 控制导航栏是否显示的变量
            topNavShow: true,
            // 声明一个数组存放比对数据
            scrollData: [],
            // 一个响应速度的参数，值越大响应越慢
            responseSpeed: 20,
        }
    },
    created() {
        let _this = this;
        // 监听页面滚动
        window.addEventListener("scroll", function () {
            var scrollTop =
                document.documentElement.scrollTop ||
                window.pageYOffset ||
                document.body.scrollTop;

            // 只有不在最顶部时才会生效
            if (scrollTop > 100) {
                console.log(_this.scrollData);
                if (_this.scrollData.length == _this.responseSpeed) {
                    if (
                        _this.scrollData[0] <
                        _this.scrollData[_this.responseSpeed - 1]
                    ) {
                        // 下划，屏幕往上走，隐藏
                        _this.topNavShow = false;
                    }
                    if (
                        _this.scrollData[0] >
                        _this.scrollData[_this.responseSpeed - 1]
                    ) {
                        // 上划，屏幕往下走，显示
                        _this.topNavShow = true;
                    }
                }
                if (_this.scrollData.length >= _this.responseSpeed) {
                    _this.scrollData = [];
                } else {
                    _this.scrollData.push(scrollTop);
                }
            } else {
                _this.topNavShow = true;
            }


        });
    },
}
