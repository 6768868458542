<template>
  <div class="foot">
    <div class="footLeft">
      <img style="width: 181px" src="../assets/image/logo2.png" alt="" />
    </div>
    <div  class="footLeft">

      <!--https://rapidayuda.onelink.me/YAPd/keaxjjus-->
      <a
        href="https://rapidayuda.onelink.me/YAPd/keaxjjus"
        target="_blank"
        class="playBtn"
        ><img src="@/assets/image/xz_ico.png" alt=""
      /></a>
    </div>
    <div class="footRight">
      <div class="top">
        <img src="../assets/image/youxiang.png" /> Queja de cobranza:
      </div>
      <div>rapidayuda.cobranza.mx@gmail.com</div>
    </div>
  </div>
</template>

<script>
import "./scss/foot.scss";
export default {
  name: "footPage",
  data() {
    return {};
  },
};
</script>
