
<template>
    <div class="coupons_wrap">
        <van-tabs v-model="active" @click="ontabClick">
            <van-tab title="Disponible" name="effect">
                <div class="couponslist_cont" v-if="messageList.length">
                    <template v-for="(item, index) in messageList">

                            <div class="couponslist_items" :key="index">
                                    <div class="couponslist_item">
                                        <div class="couponslist_itop coupons_itop_scale-1px">
                                            <div class="clitop_tview">
                                                <div class="clitop_tvleft">{{item.name}}</div>
                                                <div class="clitop_tvright">$<span>{{item.distribute_amount}}</span></div>
                                            </div>
                                            <div class="clitop_dview">
                                                <!--<div class="clitop_dvtimes">Validez: 23:49:46</div>-->
                                                <div class="clitop_dvdate">
                                                    {{item.effective_date | formateDate}}-{{item.expire_date | formateDate}}
                                                </div>

                                            </div>
                                        </div>
                                        <div class="couponslist_idown">
                                            <div class="clidown_head">
                                                <div class="clidown_hleft" @click="onShowmsg(item.couponIdStr)">
                                                    <div class="clidown_hlname">Más detalles</div>
                                                    <div class="clidown_hlicon">
                                                        <template v-if="item.isshow">
                                                            <img src="@/assets/mobimg/mesg_openicon.png" alt="">
                                                        </template>
                                                        <template v-else>
                                                            <img src="@/assets/mobimg/mesg_closeicon.png" alt="">
                                                        </template>
                                                    </div>
                                                </div>


                                                <div class="clidown_hright">

                                                </div>

                                            </div>
                                            <div class="clidown_message" v-if="item.isshow">
                                                <template v-if="item.tag">
                                                    {{item.tag}}
                                                </template>
                                                <template v-else>--</template>
                                            </div>
                                        </div>
                                    </div>
                            </div>



                        <!--<div class="couponslist_items" :key="index">
                            <div class="couponslist_item">
                                <div class="couponslist_itop coupons_itop_scale-1px">
                                    <div class="clitop_tview">
                                        <div class="clitop_tvleft">{{item.name}}</div>
                                        <div class="clitop_tvright">$<span>{{item.amount}}</span></div>
                                    </div>
                                    <div class="clitop_dview">
                                        <template v-if="item.datetype == 1">
                                            <div class="clitop_dvtimes">Validez: 23:49:46</div>
                                        </template>
                                        <template v-else>
                                            <div class="clitop_dvdate">09/06/2022-15/06/2022</div>
                                        </template>
                                    </div>
                                </div>
                                <div class="couponslist_idown" @click="onShowmsg(item.id)">
                                    <div class="clidown_head">
                                        <div class="clidown_hleft">
                                            <div class="clidown_hlname">Más detalles</div>
                                            <div class="clidown_hlicon">
                                                <template v-if="item.isshow">
                                                    <img src="@/assets/mobimg/mesg_openicon.png" alt="">
                                                </template>
                                                <template v-else>
                                                    <img src="@/assets/mobimg/mesg_closeicon.png" alt="">
                                                </template>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="clidown_message" v-if="item.isshow">El cupón se puede utilizar durante el período de validez</div>
                                </div>
                            </div>
                        </div>-->


                    </template>
                </div>
                <div v-else class="couponslist_cont">
                    <div class="couponslist_nodata">
                        <div class="coo_nodata_img">
                            <img src="@/assets/mobimg/nocoupons.png" alt="">
                        </div>
                        <div class="coo_nodata_text">Aún no hay cupón disponible</div>
                    </div>
                </div>
            </van-tab>
            <van-tab title="Vencido" name="expire">
                <div class="couponslist_cont expirelist_cont" v-if="expireList.length">
                    <template v-for="(item, index) in expireList">
                        <div class="couponslist_items" :key="index">
                            <div class="couponslist_item">
                                <div class="couponslist_itop coupons_itop_scale-1px">
                                    <div class="clitop_tview">
                                        <div class="clitop_tvleft">{{item.name}}</div>
                                        <div class="clitop_tvright">$<span>{{item.distribute_amount}}</span></div>
                                    </div>
                                    <div class="clitop_dview">
                                        <!--<div class="clitop_dvtimes">Validez: 23:49:46</div>-->
                                        <div class="clitop_dvdate">
                                            {{item.effective_date | formateDate}}-{{item.expire_date | formateDate}}
                                        </div>
                                    </div>
                                </div>
                                <div class="couponslist_idown" @click="onShowmsg(item.couponIdStr)">
                                    <div class="clidown_head">
                                        <div class="clidown_hleft">
                                            <div class="clidown_hlname">Más detalles</div>
                                            <div class="clidown_hlicon">
                                                <template v-if="item.isshow">
                                                    <img src="@/assets/mobimg/mesg_openicon02.png" alt="">
                                                </template>
                                                <template v-else>
                                                    <img src="@/assets/mobimg/mesg_closeicon02.png" alt="">
                                                </template>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="clidown_message" v-if="item.isshow">
                                        <template v-if="item.tag">
                                            {{item.tag}}
                                        </template>
                                        <template v-else>--</template>
                                    </div>
                                </div>

                                <div class="expire_imgcont">
                                    <template v-if="item.is_available == 2">
                                        <img src="@/assets/mobimg/expire_usado.png" alt="">
                                    </template>
                                    <template v-else-if="item.is_available == 3">
                                        <img src="@/assets/mobimg/expire_usado.png" alt="">
                                    </template>
                                    <template v-else>
                                        <img src="@/assets/mobimg/expire_vencido.png" alt="">
                                    </template>
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
                <div v-else class="couponslist_cont expirelist_cont">
                    <!--<van-empty description="Datos no disponibles" />-->
                    <div class="couponslist_nodata">
                        <div class="coo_nodata_img">
                            <img src="@/assets/mobimg/nocoupons.png" alt="">
                        </div>
                        <div class="coo_nodata_text">Aún no hay cupón disponible</div>
                    </div>
                </div>
            </van-tab>
        </van-tabs>
    </div>
</template>

<script>
    import { parseTime, getNextDate, getQueryString } from "@/utils/index";
    import { couponList, couponUnused } from '@/api/loan';
    import { loadingShow, loadingHide } from '@/utils/loading'

    export default {
        name: 'couponlist',
        data() {
            return {
                activeNav: 'couponlist',
                active: 'effect',
                messageList: [
                    /*{
                        id: 1,
                        name: 'Cupón de pago',
                        datetype: 1,
                        date: '23:49:46',
                        amount: 30,
                        message: 'El cupón se puede utilizar durante el período de validez',
                        isshow: false,
                    },
                    {
                        id: 2,
                        name: 'Cupón de monto adicional',
                        datetype: 2,
                        date: '09/06/2022-15/06/2022',
                        amount: 100,
                        message: 'El cupón se puede utilizar durante el período de validez',
                        isshow: false,
                    },
                    {
                        id: 3,
                        name: 'Cupón de pago',
                        datetype: 2,
                        date: '09/06/2022-15/06/2022',
                        amount: 10,
                        message: 'El cupón se puede utilizar durante el período de validez',
                        isshow: false,
                    },*/
                ],
                expireList: [
                    /*{
                        id: 4,
                        name: 'Cupón de monto adicional',
                        datetype: 2,
                        date: '09/06/2022-15/06/2022',
                        amount: 100,
                        message: 'El cupón se puede utilizar durante el período de validez',
                        isshow: false,
                    },
                    {
                        id: 5,
                        name: 'Cupón de pago',
                        datetype: 2,
                        date: '09/06/2022-15/06/2022',
                        amount: 10,
                        message: 'El cupón se puede utilizar durante el período de validez',
                        isshow: false,
                    },*/
                ]
            }
        },
        mounted() {
            this.getCouponList(1);
        },
        filters: {
            formateDate(val){
                let useDate = '';
                if(val){
                    useDate = parseTime(val, '{d}/{m}/{y}');
                }
                return useDate
            }
        },
        methods: {
            ontabClick(e){
                console.log(e)
                if(e == 'effect'){
                    this.getCouponList(1);
                } else if(e == 'expire'){
                    this.getCouponList(4);
                }

            },
            getCouponList(type) { //获取优惠券列表
                let _this = this;

                let useaccessToken = this.$store.state.common.accessToken;
                let postData = {
                    access_token: useaccessToken,
                    offset: 0,
                    pageSize: 100,
                    list_type: type, //1未使用 2已使用 3已过期
                    //index_frame: 1,
                    //coupon_type: 4, //1减免、4提额
                };

                loadingShow()

                couponList(postData).then(res => {
                    console.log(res)

                    loadingHide()

                    if(res.code == 0){
                        let useData = res.data;
                        let useList = useData.data;

                        if(useList && useList.length){
                            for(let i=0; i<useList.length; i++){
                                useList[i].isshow = false;
                            }
                        }
                        if(type == 1){
                            _this.messageList = useList;
                        } else if(type == 4){
                            _this.expireList = useList;
                        }

                    }

                }, error => {
                    loadingHide()
                })

            },
            onShowmsg(id){
                if(this.active == 'effect'){
                    let usemessageList = this.messageList;
                    for(let i=0; i<usemessageList.length; i++){
                        if(usemessageList[i].couponIdStr == id){
                            usemessageList[i].isshow = !usemessageList[i].isshow;
                        } else {
                            usemessageList[i].isshow = false;
                        }
                    }
                } else if(this.active == 'expire'){
                    let useexpireList = this.expireList;
                    for(let i=0; i<useexpireList.length; i++){
                        if(useexpireList[i].couponIdStr == id){
                            useexpireList[i].isshow = !useexpireList[i].isshow;
                        } else {
                            useexpireList[i].isshow = false;
                        }

                    }
                }

            },

        }
    }
</script>

<style>
    .coupons_wrap .van-tabs__wrap{
        background: #F6F6F6;
    }
    .coupons_wrap .van-tab{
        color: #1A1A1A;
        font-weight: 500;
        background: #F6F6F6;
        border-bottom: .5px solid #EAEAEA;
    }
    .coupons_wrap .van-tabs__line{
        width: 45px;
        height: 3px;
        background: #00CA88;
    }
    .coupons_wrap .van-tab--active{
        color: #00CA88;
    }
    .couponslist_cont{
        width: 100%;
        height: calc(100vh - 44px);
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        padding: 0.416667rem 0.444444rem 0.444444rem 0.444444rem;
        overflow-x: hidden;
        overflow-y: scroll;
        background: #F6F6F6;
    }
    .couponslist_items{
        width: 100%;
        height: auto;
        padding-bottom: 0.444444rem;
    }
    .couponslist_item{
        width: 100%;
        height: auto;
        background: #ffffff;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        padding: 0.388889rem 0.444444rem 0 0.444444rem;
        border-radius: 0.333333rem;
        border: 1px solid #EFF1F3;
        position: relative;
    }
    /* 1px边框 底部*/
    .coupons_itop_scale-1px{
        position: relative;
        border:none;
    }
    .coupons_itop_scale-1px:after{
        content: '';
        position: absolute;
        bottom: 0;
        background: #ECECEC;
        width: 100%;
        height: 1px;
        -webkit-transform: scaleY(0.5);
        transform: scaleY(0.5);
        -webkit-transform-origin: 0 0;
        transform-origin: 0 0;
    }
    .couponslist_itop{
        width: 100%;
        height: 1.583333rem;
    }
    .clitop_tview{
        width: 100%;
        height: 0.694444rem;
        display: flex;
        justify-content: space-between;
    }
    .clitop_tvleft{
        width: auto;
        height: 0.666667rem;
        font-size: 0.416667rem;
        font-weight: 500;
        color: #1A1A1A;
        line-height: 0.666667rem;
    }
    .clitop_tvright{
        width: auto;
        height: 0.666667rem;
        font-size: 0.666667rem;
        font-weight: 500;
        color: #FB4840;
        line-height: 0.666667rem;
    }
    .clitop_dview{
        width: 100%;
        height: 0.555556rem;
    }
    .clitop_dvdate{
        width: 100%;
        height: 0.555556rem;
        font-size: 0.333333rem;
        font-weight: 500;
        color: #808080;
        line-height: 0.555556rem;
    }
    .clitop_dvtimes{
        display: inline-block;
        width: auto;
        float: left;
        height: 0.555556rem;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        padding: 0 0.111111rem 0 0.111111rem;
        font-size: 0.333333rem;
        font-weight: 500;
        color: #FB4840;
        line-height: 0.555556rem;
        background: rgba(251, 72, 64, 0.1);
        border-radius: 0.055556rem;
    }
    .couponslist_idown{
        width: 100%;
        height: auto;
    }
    .clidown_head{
        width: 100%;
        height: 1.027778rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .clidown_hleft{
        width: auto;
        height: 0.472222rem;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    .clidown_hlname{
        width: auto;
        height: 0.472222rem;
        font-size: 0.333333rem;
        font-weight: 500;
        color: #808080;
        padding-right: 0.166667rem;
        line-height: 0.472222rem;
    }
    .clidown_hlicon{
        width: 0.277778rem;
        height: 0.277778rem;
    }
    .clidown_hlicon img{
        display: block;
        width: 0.277778rem;
        height: 0.277778rem;
    }
    .clidown_hright{
        width: 0.472222rem;
        height: auto;
    }
    .clidown_hright img{
        display: block;
        width: 0.472222rem;
        height: 0.472222rem;
    }
    .clidown_message{
        width: 100%;
        height: auto;
        padding-bottom: 0.361111rem;
        font-size: 0.305556rem;
        font-weight: 500;
        color: #808080;
        line-height: 0.388889rem;
    }
    .expirelist_cont .clitop_tvleft{
        color: #B3B3B3;
    }
    .expirelist_cont .clitop_tvright{
        color: #FDC8C5;
    }
    .expirelist_cont .clitop_dvdate{
        color: #C2C2C2;
    }
    .expirelist_cont .clidown_hlname{
        color: #C2C2C2;
    }
    .expirelist_cont .clidown_message{
        color: #C2C2C2;
    }
    .expire_imgcont{
        width: 2.111111rem;
        height: 1.527778rem;
        position: absolute;
        right: 0;
        bottom: 0;
    }
    .expire_imgcont img{
        display: block;
        width: 2.111111rem;
        height: 1.527778rem;
    }
    .couponslist_nodata{
        width: 100%;
        padding-top: 0.555556rem;
    }
    .coo_nodata_img{
        width: 7.138889rem;
        height: 4.722222rem;
        margin: 0 auto;
    }
    .coo_nodata_img img{
        display: block;
        width: 100%;
    }
    .coo_nodata_text{
        width: 100%;
        height: 0.555556rem;
        font-size: 0.388889rem;
        font-weight: 400;
        color: #808080;
        text-align: center;
    }
</style>
