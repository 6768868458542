<template>
  <div class="transparenciaTwo">
    <headNav activeNav="transparencia"></headNav>
    <img src="../assets/image/5_banner.png" class="banner" />
    <div class="wrapper">
      <div class="wrapper_top">
        <img src="../assets/image/transparenciaTwo_one.png" />
        <p style="margin-bottom: 20px">
          Los despachos de cobranza tienen como función ser intermediario entre
          una entidad que otorga créditos y un Deudor, esto con el propósito de
          requerir extrajudicialmente el pago de la deuda y negociar o
          reestructurar los créditos, préstamos o financiamientos.
        </p>
        <p>
          Las llamadas de cobranza le quitan tranquilidad del deudor y a las
          personas que lo rodean, y la única forma en que las llamadas
          desaparecerán en su totalidad será liquidando la deuda. No obstante lo
          anterior, los despachos de cobranza tienen un código de ética y
          responsabilidades que cumplir, y
          <span style="font-weight: 600"
            >deberán de realizar sus operaciones de manera respetuosa y
            amable</span
          >.
        </p>
      </div>
      <div class="wraaper_middle">
        <div class="card">
          <div class="top">
            <img src="../assets/image/transparenciaTwo_two.png" alt="" />
            <span>DEBEN</span>
          </div>
          <p>
            En el primer contacto que se establezcan, ya sea por escrito o
            verbalmente con el cliente, según sea el caso:
          </p>
          <p>• Identificar el personal encargado de la cobranza;</p>

          <p>
            • Mencionar el contrato u operación motivo de la deuda en que se
            basa la acción de Cobranza; Indicar, Monto del adeudo y fecha de
            cálculo;
          </p>

          <p>• Mencionar las condiciones para liquidar el adeudo;</p>

          <p>• Dirigirse al Deudor de manera respetuosa y educada;</p>

          <p>
            • Comunicarse en un horario de 7:00 a 22:00, de acuerdo al horario
            del cliente;
          </p>

          <p>• Documentar por escrito con el Deudor el acuerdo de pago,</p>

          <p>
            • Tratar los datos personales de conformidad con la normativa
            aplicable en la materia.
          </p>
        </div>
        <div class="card">
          <div class="top">
            <img src="../assets/image/transparenciaTwo_three.png" alt="" />
            <span>NO DEBEN</span>
          </div>
          <div>
            <p>
              • Utilizar nombres o denominaciones que se asemejen a las de
              instituciones públicas;
            </p>
            <p>
              • Utilizar números de teléfono que aparezcan como "confidencial",
              "oculto", "privado" ;
            </p>
            <p>
              • Amenazar, ofender o intimidar al Cliente, sus familiares,
              compañeros de trabajo o cualquier otra persona que no tenga
              relación con la deuda;
            </p>
            <p>• Realizar llamadas sus contactos;</p>
            <p>
              • Recibir por ningún medio y de manera directa el pago del adeudo;
            </p>
            <p>
              • Realizar gestiones de cobro, negociación o reestructuración con
              menores de edad o adultos mayores, salvo que en el supuesto se
              trate de los Deudores.
            </p>
            <p>
              • Realizar las gestiones de cobro, negociación o reestructuración,
              de los créditos, préstamos o financiamientos, en un domicilio,
              teléfono o correo electrónico distinto al proporcionado por la
              Entidad Financiera o el Deudor.
            </p>
          </div>
        </div>
      </div>
      <div class="wrapper_bottom">
        <h1>PREGUNTAS FRECUENTES CON LOS DESPACHOS DE COBRANZA</h1>
        <div class="card">
          <h6>¿En qué momento debo presentar mi queja?</h6>
          <hr />
          <div>
            <p>
              Si tiene alguna duda o insatisfacción con el servicio de cobranza,
              puede comunicarse con nuestro servico de cliente por correo
              electrónico
              <span style="color: #41a8ef; text-decoration: underline"
                >rapidayuda.cobranza.mx@gmail.com</span
              >
              y proporcionar evidencia correspondiente (imagen, mensajes etc.).
              Prestamos mucha antención de su experiencia y le responderemos lo
              antes posible después de la investigación.
            </p>
            <p>
              Si por algún medio “red social, WhatsApp o mensaje de texto” te
              contactan ofreciendo un préstamo ¡Cuidado! Podría ser un fraude,
              nuestro personal no puede aprobar su solicitud por estos medios.
            </p>
          </div>
        </div>
        <div class="card">
          <h6>
            ¿Pueden hablarme a cualquier día, hora y dejar recado con quien les
            conteste para cobrarme un adeudo?
          </h6>
          <hr />
          <div>
            <p>
              Los Despachos de Cobranza no pueden marcarte antes de las 7:00 am
              o después de las 10:00 pm. Si la persona que toma la llamada es
              ajena a la deuda, nos reservamos la información de la gestión de
              cobranza.
            </p>
          </div>
        </div>
        <div class="card">
          <h6>Intimidación de revelar datos y fotos personales</h6>
          <hr />
          <div>
            <p>
              El despacho de cobranza actúa como intermediario entre la Entidad
              Financiera y el Deudor, con el propósito de requerir el pago de la
              deuda, así como, negociar y reestructurar el crédito, sin embargo
              la formalización del convenio deberá realizarse con la Entidad
              Financiera que otorgó el crédito.
            </p>
          </div>
        </div>
      </div>
    </div>
    <footPage></footPage>
  </div>
</template>
<script>
import headNav from "@/components/headNav.vue";
import footPage from "@/components/foot.vue";
import "@/components/scss/transparenciaTwo.scss";

export default {
  name: "transparenciaTwo",
  components: { headNav, footPage },
  data() {
    return {};
  },
};
</script>
