import request from '@/utils/requestFile'

/** 活体认证 */
export function verifyLive(data, access_token, source){
    return request({
        url: `h5/v2/account/live/verify?access_token=${access_token}&source=${source}`,
        method: 'post',
        data,
    })
}
