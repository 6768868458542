//import { getUserInfo, logout } from '@/api/login'
import { removeAllToken } from '@/utils/auth'
import Router from '../../router/routers'
import Store from '../index'

const state = {
    accessToken: window.localStorage.getItem('accessToken') || '',
    coupons: window.localStorage.getItem('coupons') || '',
    loanAmount:  window.localStorage.getItem('loanAmount') || '',
    loanDays: window.localStorage.getItem('loanDays') || '',
    bankData: window.localStorage.getItem('bankData') || '',
    isRepeatLoan: window.localStorage.getItem('isRepeatLoan') || false,
    onlinecode: window.localStorage.getItem('onlinecode') || '',
    pageIndexa: window.localStorage.getItem('pageIndexa') || true,
    pageIndexb: window.localStorage.getItem('pageIndexb') || true,
    pageToken: window.localStorage.getItem('pageToken') || true,
    pageExamine: window.localStorage.getItem('pageExamine') || true,
    pagePayment: window.localStorage.getItem('pagePayment') || true,
    pageNew: window.localStorage.getItem('pageNew') || true,
    pageType: window.localStorage.getItem('pageType') || 'h501',
}
const mutations = {
    SET_ACCESS_TOKEN(state, val) {
        state.accessToken = val;
        window.localStorage.setItem('accessToken', val)
    },
    SET_COUPONS(state, val){
        state.coupons = JSON.stringify(val || {});
        window.localStorage.setItem('coupons', JSON.stringify(val || {}))
    },
    SET_LOANA_AMOUNT(state, val){
        state.loanAmount = val;
        window.localStorage.setItem('loanAmount', val)
    },
    SET_LOAN_DAYS(state, val){
        state.loanDays = JSON.stringify(val || {});
        window.localStorage.setItem('loanDays', JSON.stringify(val || {}))
    },
    SET_BANK_DATA(state, val){
        state.bankData = JSON.stringify(val || {});
        window.localStorage.setItem('bankData', JSON.stringify(val || {}))
    },
    SET_ISREPEAT_LOAN(state, val){
        state.isRepeatLoan = val;
        window.localStorage.setItem('isRepeatLoan', val)
    },
    SET_ONLINE_CODE(state, val){
        state.onlinecode = JSON.stringify(val || {});
        window.localStorage.setItem('onlinecode', JSON.stringify(val || {}))
    },
    SET_PAGE_INDEXA(state, val){
        state.pageIndexa = val;
        window.localStorage.setItem('pageIndexa', val)
    },
    SET_PAGE_INDEXB(state, val){
        state.pageIndexb = val;
        window.localStorage.setItem('pageIndexb', val)
    },
    SET_PAGE_TOKEN(state, val){
        state.pageToken = val;
        window.localStorage.setItem('pageToken', val)
    },
    SET_PAGE_EXAMINE(state, val){
        state.pageExamine = val;
        window.localStorage.setItem('pageExamine', val)
    },
    SET_PAGE_PAYMENT(state, val){
        state.pagePayment = val;
        window.localStorage.setItem('pagePayment', val)
    },
    SET_PAGE_NEW(state, val){
        state.pageNew = val;
        window.localStorage.setItem('pageNew', val)
    },
    SET_PAGE_TYPE(state, val){
        state.pageType = val;
        window.localStorage.setItem('pageType', val)
    }

}

window.commonState = state
export default {
    namespaced: true,
    state,
    mutations,
}

