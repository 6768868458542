
<template>
    <div>
        <div class="mobile_wrap" v-if="topNavShow">
            <mobNav :activeNav="activeNav"></mobNav>
        </div>
        <van-pull-refresh v-model="isLoading" @refresh="onRefresh" :pulling-text="pulling_text" :loosing-text="loosing_text" :loading-text="loading_text">

        <div class="mainview_itoken_wrap" v-if="orderStatus">
                <template v-if="orderStatus == 2">
                    <div class="examine_one_wrap">
                        <div class="examine_one_img">
                            <img src="@/assets/mobimg/examine_img01.png" alt="">
                        </div>
                        <div class="examine_one_desc">
                            <p>
                                Tenemos validación personal y programada, el proceso se tarda entre 3 minutos a 1 día.
                            </p>
                        </div>
                    </div>

                    <div class="com_fc_down" v-if="$store.state.common.pageExamine">
                        <div class="com_fc_dview">
                            <img src="@/assets/mobimg/comf_appdload.png" alt="" @click="onAppLoad">
                            <div class="com_fc_dvclose" @click="onAppLoadClose">
                                <img src="@/assets/mobimg/comf_rpclose.png" alt="">
                            </div>
                        </div>
                    </div>
                </template>

                <template v-else-if="orderStatus == 3">
                    <div class="examine_one_wrap">
                        <div class="examine_one_img">
                            <img src="@/assets/mobimg/examine_img03.png" alt="">
                        </div>
                        <div class="examine_one_title">Validación rechazada</div>
                        <div class="examine_one_desc">
                            <p>
                                Lo sentimos, por falta de puntaje crédito tu pedido fue rechazado.
                            </p>
                            <p>
                                Podrías solicitar de nuevo después de 7 días en xx/xx/xxxx/. Te recomendamos que confirmes la precisión de tus informaciones personales.
                            </p>
                        </div>
                        <div class="examine_two_title">
                            <div class="examine_two_tview">
                                Recomendamos que utilice las siguientes aplicaciones
                            </div>
                        </div>

                        <div class="examine_two_footer">
                            <div class="examine_two_fview">
                                <div class="examine_two_fitems">
                                    <div class="examine_two_fitem" @click="onAppLoad">
                                        <div class="examine_two_fileft">
                                            <div class="examine_two_filimg">
                                                <img src="@/assets/mobimg/rp_logo.png" alt="">
                                            </div>
                                            <div class="examine_two_filview">
                                                <div class="examine_two_filvtitle">Rapidayuda</div>
                                                <div class="examine_two_filvdesc">Validación rapida con monto alto</div>
                                            </div>
                                        </div>
                                        <div class="examine_two_firight">
                                            <img src="@/assets/mobimg/comf_rparrow.png" alt="">
                                        </div>
                                    </div>
                                </div>
                                <div class="examine_two_fitems">
                                    <div class="examine_two_fitem" @click="onAppLoadvv">
                                        <div class="examine_two_fileft">
                                            <div class="examine_two_filimg">
                                                <img src="@/assets/mobimg/vv_logo.png" alt="">
                                            </div>
                                            <div class="examine_two_filview">
                                                <div class="examine_two_filvtitle">Viva Vida</div>
                                                <div class="examine_two_filvdesc">Validación rapida con monto alto</div>
                                            </div>
                                        </div>
                                        <div class="examine_two_firight">
                                            <img src="@/assets/mobimg/comf_rparrow.png" alt="">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>

                <template v-else-if="orderStatus == 6">
                    <div class="examine_one_wrap">
                        <div class="examine_one_img">
                            <img src="@/assets/mobimg/loanfailure.png" alt="">
                        </div>
                        <div class="examine_one_title">Depositando</div>
                        <div class="examine_one_desc">
                            <p>Préstamo fallido, por favor vuelva a aplicar</p>
                        </div>
                    </div>

                    <div class="com_fc_down" v-if="$store.state.common.pageExamine">
                        <div class="com_fc_dview">
                            <img src="@/assets/mobimg/comf_appdload.png" alt="" @click="onAppLoad">
                            <div class="com_fc_dvclose" @click="onAppLoadClose">
                                <img src="@/assets/mobimg/comf_rpclose.png" alt="">
                            </div>
                        </div>
                    </div>

                </template>

                <template v-else>
                    <div class="examine_one_wrap">
                        <div class="examine_one_img">
                            <img src="@/assets/mobimg/examine_img02.png" alt="">
                        </div>
                        <div class="examine_one_title">Depositando</div>
                        <div class="examine_one_desc">
                            <p>
                                Estamos procesando tu depósito. Normalmente, tu dinero llegará en unos minutos. Pero las transferencias bancarias pueden retrasarse. No te preocupes y te avisaremos vía SMS que el depósito es exitoso.
                            </p>
                        </div>
                        <!--<div class="examine_one_message">Tu tarjeta bancaria: XXXXXXXXXXXX</div>-->
                    </div>

                    <div class="com_fc_down" v-if="$store.state.common.pageExamine">
                        <div class="com_fc_dview">
                            <img src="@/assets/mobimg/comf_appdload.png" alt="" @click="onAppLoad">
                            <div class="com_fc_dvclose" @click="onAppLoadClose">
                                <img src="@/assets/mobimg/comf_rpclose.png" alt="">
                            </div>
                        </div>
                    </div>
                </template>
        </div>
        </van-pull-refresh>
    </div>
</template>

<script>

    import mobNav from "@/components/mobNav.vue";
    import scrollUpdown from "@/mixins/index";

    import { loaninfo } from '@/api/loan';

    import { loadingShow, loadingHide } from '@/utils/loading'

    export default {
        name: 'compoundexamine',
        components: { mobNav },
        mixins: [scrollUpdown],
        data() {
            return {
                activeNav: 'examine',
                orderStatus: null,
                isLoading: false,
                pulling_text: 'Bajar para refrescar...',
                loosing_text: 'Libere y refresque...',
                loading_text: 'Cargando en...',
            }
        },
        mounted() {
            this.getloanInfo();
        },
        methods: {
            onAppLoadvv() {
                window.location.href = 'https://vivavida.onelink.me/v0MW/4nomodtd';
            },
            onAppLoad() {
                console.log('下载app')
                window.location.href = 'https://rapidayuda-apk.onelink.me/lt89/ahplxorx';
            },
            onAppLoadClose(){
                this.$store.commit('common/SET_PAGE_EXAMINE', false);
            },
            onRefresh(){
                this.getloanInfo();
            },
            getloanInfo(){ //获取订单状态
                let _this = this;
                let useaccessToken = this.$store.state.common.accessToken;
                let usepageType = this.$store.state.common.pageType;

                //let useaccessToken = '6035915f182f42835b012b81a246346e'

                let postData = {
                    "access_token": useaccessToken,
                    "source":"Rapidayuda",
                    "channel":usepageType
                };

                loadingShow();

                loaninfo(postData).then(res => {
                    console.log(res)
                    loadingHide();
                    _this.isLoading = false;
                    if(res.code == 0){
                        let useresult = res.result;
                        let loan_info = useresult.loan_info;

                        //2 提交审核 审核中
                        //3 拒绝
                        //4 人工审核
                        //22 放款前待确认
                        //5 等待放款 放款中
                        //6 放款失败
                        //7 放款成功 生成还款计划
                        //8 结清
                        //9 逾期
                        let { order_status } = loan_info;

                        _this.orderStatus = order_status;

                        if(order_status == 0 || order_status == 8 || order_status == 10){
                            _this.$router.replace({
                                path: '/compoundloan/indextoken'
                            })
                        } else if(order_status == 7 || order_status == 9 || order_status == 11){ //还款
                            _this.$router.replace({
                                path: '/compoundloan/repayment'
                            });
                        }

                    }
                }, error => {
                    _this.isLoading = false;
                    loadingHide()
                })

            },
        }
    }
</script>

<style>
    .examine_one_wrap{
        width: 100%;
        height: auto;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        padding-top: 1.055556rem
    }
    .examine_one_img{
        width: 7.138889rem;
        height: 4.722222rem;
        margin: 0 auto;
    }
    .examine_one_img img{
        display: block;
        width: 7.138889rem;
        height: 4.722222rem;
    }
    .examine_one_desc{
        width: 91.11%;
        height: auto;
        margin: 0 auto;
    }
    .examine_one_desc p{
        font-size: 14px;
        font-weight: 500;
        color: #808080;
        line-height: 19px;
        text-align: center;
    }
    .examine_one_title{
        width: 100%;
        height: 30px;
        font-size: 16px;
        font-weight: 500;
        color: #1A1A1A;
        line-height: 20px;
        text-align: center;
    }
    .examine_one_message{
        width: 100%;
        height: 19px;
        font-size: 14px;
        font-weight: 500;
        color: #00CA88;
        line-height: 19px;
        text-align: center;
    }
    .examine_two_title{
        width: 100%;
        padding-top: 1.111111rem;
        padding-bottom: 0.111111rem;
    }
    .examine_two_tview{
        width: 81.94%;
        font-size: 16px;
        font-weight: 500;
        color: #1A1A1A;
        line-height: 20px;
        text-align: center;
        margin: 0 auto;
    }
    .examine_two_footer{
        width: 100%;
        height: auto;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        padding: 0 0.444444rem 0.194444rem 0.444444rem;
    }
    .examine_two_fview{
        width: 100%;
        height: auto;
    }
    .examine_two_fitems{
        width: 100%;
        height: auto;
        padding-top: 0.277778rem;
    }
    .examine_two_fitem{
        width: 100%;
        height: 1.833333rem;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        padding: 0.444444rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #ffffff;
        border-radius: 0.333333rem;
    }
    .examine_two_fileft{
        width: auto;
        height: 0.944444rem;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    .examine_two_firight{
        width: 0.277778rem;
        height: 0.388889rem;
    }
    .examine_two_firight img{
        display: block;
        width: 0.277778rem;
        height: 0.388889rem;
    }
    .examine_two_filimg{
        width: 0.944444rem;
        height: 0.944444rem;
    }
    .examine_two_filview{
        width: auto;
        height: auto;
        padding-left: 0.166667rem;
    }
    .examine_two_filvtitle{
        width: auto;
        height: 0.5rem;
        font-size: 12px;
        font-weight: 500;
        color: #1A1A1A;
        line-height: 12px;
    }
    .examine_two_filvdesc{
        width: auto;
        font-size: 12px;
        font-weight: 500;
        color: #808080;
        line-height: 12px;
    }
</style>
