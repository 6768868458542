
import Store from '@/store/index'

const accessTokenKey = 'accessToken';

export function getAccessToken() {
    // return window.localStorage.getItem(accessTokenKey) || null
    return Store.getters.accessToken || '';
}

export function setAccessToken(token = '') {
    window.localStorage.setItem(accessTokenKey, token)
    Store.commit('common/SET_ACCESS_TOKEN', token);
}

export function removeAllToken() {
    window.localStorage.removeItem(accessTokenKey)
    Store.commit('common/SET_ACCESS_TOKEN', '');
}

const isRepeatLoanKey = 'isRepeatLoan';

export function getIsRepeatLoan() {
    return Store.getters.isRepeatLoan || false;
}

export function setIsRepeatLoan(isRepeat = false) {
    window.localStorage.setItem(isRepeatLoanKey, isRepeat)
    Store.commit('common/SET_ISREPEAT_LOAN', isRepeat);
}

export function removeIsRepeatLoan() {
    window.localStorage.removeItem(isRepeatLoanKey)
    Store.commit('common/SET_ISREPEAT_LOAN', false);
}


