<template>
    <div class="verifycode_wrap">
        <div class="verifycode_cont">
            <div class="verifycode_tilte"></div>
            <div class="verifycode_top verifycode_coupons_top">
                <div class="verifycode_tleft">
                    <span>
                        Iniciar sesión registrarse
                    </span>
                </div>
                <div class="verifycode_tright">
                    <img src="@/assets/mobimg/verifycode_tright.png" alt="">
                </div>
            </div>

            <!--<div class="verifycode_form">
                <div class="verifycode_items verifycode_items_scale-1px">
                    <div class="verifycode_item">
                        <div class="verifycode_ioneleft">+52</div>
                        <div class="verifycode_ioneright">
                            <input type="number" placeholder="Ingresa tu celular" >
                        </div>
                    </div>
                </div>
                <div class="verifycode_items" :class="isWarn ? 'verifycode_iwarn_scale-1px' : 'verifycode_items_scale-1px'">
                    <div class="verifycode_item verifycode_itemtwo">
                        <div class="verifycode_itwoleft">
                            <input type="number" placeholder="Ingresa OTP" >
                        </div>
                        <div class="verifycode_itworight" @click="verificationCode" :class="isSendcode ? 'active' : '' " >
                            <input id="sendcode" :class="isSendcode ? 'active' : '' " :disabled="isSendcode" type="button"  :value="codeval" />
                        </div>
                    </div>
                </div>

                <div class="verifycode_fwran_view">
                    Añadelo en tu pantalla
                </div>
            </div>

            <div class="verifycode_google_wrap">
                <div class="verifycode_google_cont">

                </div>
            </div>

            <div class="verifycode_footer_btns">
                <div class="verifycode_footer_btn">
                    <van-button round color="#00CA88" block>Entregar</van-button>
                </div>
            </div>

            <div class="verifycode_footer_xys">
                <div class="verifycode_footer_xy">
                    Ver <span>acuerdo de privacidad</span> y <span>acuerdo de usuario</span>
                </div>
            </div>
            -->

            <div class="coupons_form">
                <div class="coupons_list">
                    <van-radio-group v-model="radioDays" @change="onRadioChange">
                        <van-cell-group>
                            <template v-for="item in couponsList">
                                <div class="coupons_items" :key="item.id">
                                    <div class="coupons_item" :class="item.id == radioDays ? 'coupons_active' : ''">
                                        <van-cell clickable @click="radioDays = item.id">
                                            <template #title>
                                                <div class="coupons_ileft">
                                                    <div class="coupons_ilname">15 días</div>
                                                    <div class="coupons_ildesc">Fecha de reembolso：28 de abril</div>
                                                </div>
                                            </template>

                                            <template #right-icon>
                                                <div class="coupons_iright">
                                                    <div class="coupons_irview">
                                                        <van-radio :name="item.id" >
                                                            <template #icon="props">
                                                                <img v-if="props.checked" src="@/assets/mobimg/radio_ischeck.png" alt="">
                                                                <img v-else src="@/assets/mobimg/radio_nocheck.png" alt="">
                                                            </template>
                                                        </van-radio>
                                                    </div>
                                                </div>
                                            </template>

                                        </van-cell>
                                    </div>
                                </div>
                            </template>
                        </van-cell-group>
                    </van-radio-group>


                    <!--<div class="coupons_items">
                        <div class="coupons_item">
                            <div class="coupons_ileft">
                                <div class="coupons_ilname">15 días</div>
                                <div class="coupons_ildesc">Fecha de reembolso：28 de abril</div>
                            </div>
                            <div class="coupons_iright">
                                <div class="coupons_irview">
                                    <img src="@/assets/mobimg/radio_nocheck.png" alt="">
                                </div>
                            </div>
                        </div>
                    </div>-->


                    <!--<div class="coupons_items">
                        <div class="coupons_item coupons_active">

                        </div>
                    </div>-->
                </div>
            </div>

            <div class="verifycode_footer_btns">
                <div class="verifycode_footer_btn">
                    <van-button round color="#00CA88" block>
                        <span style="font-weight: 500;">Enterado</span>
                    </van-button>
                </div>
            </div>

        </div>
    </div>
</template>

<script>

    let clock = null;

    export default {
        name: 'compoundeuseall',
        data() {
            return {
                nums: 10,
                codeval: 'Recibir OTP',
                isSendcode: false,
                isWarn: true,

                couponsList: [
                    {
                        id: 1,
                    },
                    {
                        id: 2,
                    },
                    {
                        id: 3,
                    },
                    {
                        id: 4,
                    },
                ],
                radioDays: 2,
            }
        },
        mounted() {

        },
        methods: {
            doLoop() {
                this.nums--;
                if(this.nums > 0){
                    this.codeval = this.nums + 's';
                } else {
                    clearInterval(clock);
                    this.isSendcode = false;
                    this.codeval = 'Recibir OTP';
                    this.nums = 10;
                }
            },
            verificationCode() {
                let _this = this;
                this.isSendcode = true;
                this.codeval = this.nums + 's';
                clock = setInterval(_this.doLoop, 1000);
            },
            onRadioChange(e){
                console.log(e)
                this.radioDays = e;
            }
        }
    }
</script>

<style>

    .verifycode_wrap{
        width: 100%;
        height: auto;
    }
    .verifycode_cont{
        width: 100%;
        height: auto;
        background: #FFFFFF;
        border-radius: 0.555556rem 0.555556rem 0px 0px;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        padding-top: 0.222222rem;
    }
    .verifycode_tilte{
        width: 1.25rem;
        height: 0.083333rem;
        background: #CFCFCF;
        border-radius: 0.083333rem;
        margin: 0 auto;
    }
    .verifycode_top{
        width: 100%;
        height: 2.583333rem;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        padding: 0.555556rem 0.444444rem 0 0.444444rem;
        display: flex;
        justify-content: space-between;
    }
    .verifycode_tleft{
        width: auto;
        height: 0.611111rem;
        font-size: 0.527778rem;
        font-weight: bold;
        color: #1A1A1A;
        line-height: 44px;
        display: flex;
        align-items: center;
    }
    .verifycode_tleft span{
        float: left
    }
    .verifycode_tright{
        width: 0.666667rem;
        height: 0.666667rem;
    }
    .verifycode_tright img{
        display: block;
        width: 100%;
    }
    .verifycode_form{
        width: 91.11%;
        height: 5.972222rem;
        margin: 0 auto;
    }
    .verifycode_items_scale-1px{
        position: relative;
        border:none;
    }
    .verifycode_items_scale-1px:after{
        content: '';
        position: absolute;
        bottom: 0;
        background: #E7E7E7;
        width: 100%;
        height: 1px;
        -webkit-transform: scaleY(0.5);
        transform: scaleY(0.5);
        -webkit-transform-origin: 0 0;
        transform-origin: 0 0;
    }
    .verifycode_items{
        width: 100%;
        height: 1.444444rem;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        padding-top: 0.277778rem;
    }
    .verifycode_item{
        width: 100%;
        height: 1.166667rem;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    .verifycode_ioneleft{
        width: 1.055556rem;
        height: 1.166667rem;
        font-size: 0.388889rem;
        font-weight: 400;
        color: #1A1A1A;
        line-height: 1.166667rem;
    }
    .verifycode_ioneright{
        width: 7.777778rem;
        height: 1.166667rem;
    }
    .verifycode_ioneright input{
        display: block;
        width: 100%;
        height: 1.12rem;
        outline: none;
        border: none;
        font-size: 0.388889rem;
        font-weight: 400;
        color: #1A1A1A;
        background: #ffffff;
        padding: 0;
    }
    .verifycode_itemtwo{
        justify-content: space-between;
    }
    .verifycode_itwoleft{
        width: 4.0rem;
        height: 1.166667rem;
    }
    .verifycode_itwoleft input{
        display: block;
        width: 100%;
        height: 1.1rem;
        outline: none;
        border: none;
        font-size: 0.388889rem;
        font-weight: 500;
        color: #1A1A1A;
        background: #ffffff;
    }

    .verifycode_itworight #sendcode.active{
        background: #CFCFCF;
    }
    .verifycode_iwarn_scale-1px{
        position: relative;
        border:none;
    }
    .verifycode_iwarn_scale-1px:after{
        content: '';
        position: absolute;
        bottom: 0;
        background: #DB861F;
        width: 100%;
        height: 1px;
        -webkit-transform: scaleY(0.5);
        transform: scaleY(0.5);
        -webkit-transform-origin: 0 0;
        transform-origin: 0 0;
    }
    .verifycode_fwran_view{
        width: 100%;
        height: auto;
        padding-top: 0.388889rem;
        font-size: 0.388889rem;
        font-family: Roboto-Regular, Roboto;
        font-weight: 500;
        color: #DB861F;
    }
    .verifycode_google_wrap{
        width: 100%;
        height: 2.388889rem;
    }
    .verifycode_google_cont{
        width: 7.305556rem;
        height: 1.944444rem;
        margin: 0 auto;
    }
    .verifycode_footer_btns{
        width: 100%;
        height: 1.666667rem;
    }
    .verifycode_footer_btn{
        width: 91.11%;
        height: 1.222222rem;
        margin: auto;
    }
    .verifycode_footer_xys{
        width: 100%;
        height: auto;
        display: flex;
        justify-content: center;
        padding-bottom: 0.555556rem;
    }
    .verifycode_footer_xy{
        font-size: 14px;
        transform: scale(0.79);
        font-weight: 400;
        color: #00CA88;
    }
    .verifycode_footer_xy span{
        text-decoration: underline;
    }

    .verifycode_coupons_top{
        width: 100%;
        height: 1.6rem;
    }
    .coupons_form{
        width: 100%;
        min-height: 2.611111rem;
        max-height: 10.444444rem;
        overflow-x: hidden;
        overflow-y: scroll;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        padding: 0 0.444444rem 0.888889rem 0.444444rem;
    }
    .coupons_list{
        width: 100%;
        height: auto;
    }
    .coupons_items{
        width: 100%;
        padding-bottom: 0.444444rem;
    }
    .coupons_items_end{
        padding-bottom: 0;
    }
    .coupons_items .coupons_active{
        border: 1px solid #00CA88;
    }
    .coupons_item{
        width: 100%;
        height: 2.166667rem;
        background: #F6F6F6;
        border: 1px solid #F6F6F6;
        border-radius: 0.333333rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .coupons_ileft{
        width: 89.93%;
        height: 2.166667rem;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        padding: 0.555556rem 0 0 0.444444rem;
    }
    .coupons_ilname{
        width: 100%;
        height: 0.666667rem;
        font-size: 0.5rem;
        font-weight: 500;
        color: #1A1A1A;
    }
    .coupons_ildesc{
        width: 100%;
        height: auto;
        font-size: 0.388889rem;
        font-weight: 500;
        color: #808080;
    }
    .coupons_iright{
        width: 10.07%;
        height: 2.166667rem;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    .coupons_irview{
        width: 0.472222rem;
        height: 0.472222rem;
    }
    .coupons_irview img{
        display: block;
        width: 0.472222rem;
        height: 0.472222rem;
    }
    .coupons_items .van-cell{
        padding: 0 !important;
        background-color: #F6F6F6;
        border-radius: 0.333333rem;
    }
    .coupons_list .van-hairline--top-bottom::after, .coupons_list .van-hairline-unset--top-bottom::after{
        border-width: 0 !important;
    }


</style>

