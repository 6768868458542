<template>
    <div class="confirmloan_wrap">
        <div class="confirmloan_cont">
            <div class="confirmloan_ctoptitle">Monto del préstamo</div>
            <div class="confirmloan_ctopdesc"><span>$</span>{{loanAmount}}</div>
            <div class="confirmloan_cmain">
                <div class="confirmloan_cmtitle">Plan de reembolso</div>
                <div class="confirmloan_cmview">

                    <div class="confirmloan_cmitems itoken_checkvi_scale-1px">
                        <div class="confirmloan_cmileft">Plazo（días）</div>
                        <div class="confirmloan_cmiright">{{loanDays}} Días</div>
                    </div>

                    <div class="confirmloan_cmitems itoken_checkvi_scale-1px">
                        <div class="confirmloan_cmileft">Fecha del pago</div>
                        <div class="confirmloan_cmiright">{{payTimes}}</div>
                    </div>

                    <div class="itoken_check_vitems itoken_checkvi_scale-1px" @click="gotoBank">
                        <div class="itoken_check_vitem">
                            <div class="itoken_check_vitop">
                                <div class="itoken_check_vitleft_coupon">
                                    <img src="@/assets/mobimg/confirm_icon01.png" alt="">
                                    <span style="font-size: 0.388889rem;">Tarjeta bancaria</span>
                                </div>
                                <div class="itoken_check_vitright">
                                    <div class="itoken_check_vitrnums" style="font-weight: 400;">
                                        <template v-if="bankCode">
                                            {{bankCode | hideBankcode}}
                                        </template>
                                        <template v-else>
                                            --
                                        </template>
                                    </div>
                                    <div class="itoken_check_vitrarrow">
                                        <img src="@/assets/mobimg/comf_rparrow.png" alt="">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="itoken_check_vitems itoken_check_vitems_coupons itoken_checkvi_scale-1px" @click="gotoCoupons">
                        <div class="itoken_check_vitem">
                            <div class="itoken_check_vitop itoken_check_vitop_new">
                                <div class="itoken_check_vitleft_coupon">
                                    <img src="@/assets/mobimg/itoken_check_vitleft_coupon.png" alt="">
                                    <span style="font-size: 0.388889rem;">Cupón</span>
                                </div>
                                <div class="itoken_check_vitright">
                                    <div class="itoken_check_vitrnums" style="font-weight: 400;">
                                        <template v-if="couponsList && couponsList.length">
                                            <template v-if="couponsObj && couponsObj.coupon_type">
                                                <template v-if="couponsObj.coupon_type == 1">
                                                    <p style="color: #DB861F;">
                                                        <span style="color: #DB861F;">-</span>${{couponsObj.distribute_amount}}
                                                    </p>
                                                </template>
                                                <template v-else-if="couponsObj.coupon_type == 4">
                                                    <p style="color: #DB861F;">
                                                        <span style="color: #DB861F;">+</span>${{couponsObj.distribute_amount}}
                                                    </p>
                                                </template>
                                            </template>
                                            <template v-else>
                                                <span>{{couponsList.length}}</span>disponible(s)
                                            </template>
                                        </template>
                                        <template v-else>
                                            No utilizar el cupón
                                        </template>
                                    </div>
                                    <div class="itoken_check_vitrarrow">
                                        <img src="@/assets/mobimg/comf_rparrow.png" alt="">
                                    </div>
                                </div>
                            </div>
                            <div class="itoken_check_vidown" v-if="couponsObj && couponsObj.coupon_type">
                                <div class="itoken_check_vidleft"></div>
                                <div class="itoken_check_vidright">
                                    <template v-if="couponsObj.coupon_type == 4">
                                        Cupón de monto adicional
                                    </template>
                                    <template v-else-if="couponsObj.coupon_type == 1">
                                        Cupón de pago
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="confirmloan_cmitems" @click="onShowPayment">
                        <div class="confirmloan_cmileft">Monto del pago</div>
                        <div class="confirmloan_cmiright">
                            <div class="confirmloan_cmirview">
                                <div class="confirmloan_cmirvl">
                                    <template v-if="shouPay || shouPay == 0">
                                       <span v-if="coupon_type == 1">$ {{origin_contract_amount}}</span>$ {{shouPay}}
                                    </template>
                                    <template v-else>
                                        --
                                    </template>
                                </div>
                                <div class="confirmloan_cmirvr">
                                    <img src="@/assets/mobimg/confirm_icon02.png" alt="">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="confirmloan_cmcontrato_wrap">
                <div class="confirmloan_cmcontrato_cont">
                    <div class="confirmloan_cmcontrato_cleft">
                        <van-checkbox v-model="contratoChecked">
                            <template #icon="props">
                                <img v-if="props.checked" src="@/assets/mobimg/contrato_ischeck.png" alt="">
                                <img v-else src="@/assets/mobimg/contrato_nocheck.png" alt="">
                            </template>
                            <template #default>
                                He leído y acepto el
                            </template>
                        </van-checkbox>
                    </div>
                    <div class="confirmloan_cmcontrato_cright" @click="gotoLoan"><span>contrato de préstamo</span></div>
                </div>
            </div>

            <van-button round color="#00CA88" block style="height: 1.222222rem;" @click="onConfirm">
                <span style="font-size: 0.444444rem; font-weight: bold;">Recibir el dinero</span>
            </van-button>
        </div>

        <van-popup v-model="confirmShow" position="bottom" round>
            <div class="verifycode_wrap">
                <div class="verifycode_cont">
                    <div class="verifycode_tilte"></div>
                    <div class="verifycode_top verifycode_coupons_top">
                        <div class="verifycode_tleft">
                            <span>
                                Detalles
                            </span>
                        </div>
                        <div class="verifycode_tright" @click="confirmShow = false">
                            <img src="@/assets/mobimg/verifycode_tright.png" alt="">
                        </div>
                    </div>

                    <div class="confirmloan_msgwrap">
                        <div class="confirmloan_msgitems">
                            <div class="confirmloan_msgileft">Monto de préstamo</div>
                            <div class="confirmloan_msgiright">$<span>{{loanAmount}}</span></div>
                        </div>
                        <div class="confirmloan_msgitems">
                            <div class="confirmloan_msgileft">Interés</div>
                            <div class="confirmloan_msgiright">$<span>{{interest_fee}}</span></div>
                        </div>
                        <div class="confirmloan_msgitems">
                            <div class="confirmloan_msgileft">Comisión</div>
                            <div class="confirmloan_msgiright">$<span>{{fee}}</span></div>
                        </div>
                        <div class="confirmloan_msgitems">
                            <div class="confirmloan_msgileft">VAT</div>
                            <div class="confirmloan_msgiright">$<span>{{vat}}</span></div>
                        </div>
                    </div>

                    <div class="verifycode_footer_btns" @click="confirmShow = false">
                        <div class="verifycode_footer_btn">
                            <van-button round color="#00CA88" block>
                                <span style="font-size: 0.444444rem; font-weight: bold;">Enterado</span>
                            </van-button>
                        </div>
                    </div>

                </div>
            </div>
        </van-popup>


    </div>
</template>

<script>
    import { parseTime, getNextDate, getQueryString } from "@/utils/index"
    import store from '@/store';

    import { calculationtrial, couponList, confirmOrder, couponUnused} from '@/api/loan'

    import { uniqueKey } from '@/utils/web-sdk-release-data-1.0.0'

    import { loadingShow, loadingHide } from '@/utils/loading'


    export default {
        name: 'confirmloan',
        data() {
            return {
                radioDays: 30,
                contratoChecked: true,
                confirmShow: false,
                loanAmount: null,
                loanDays: null,
                payTimes: null,
                bankCode: null,
                couponsList: [
                   /* {
                        "id": 1281,
                        "ctime": 1678955599368,
                        "coupon_id": 268,
                        "coupon_type": 1,
                        "valid_days": 4,
                        "distribute_count": 100,
                        "distribute_amount": 150,
                        "effective_date": 1678955599367,
                        "expire_date": 1679291999000,
                        "is_available": 1,
                        "send_amount": 150,
                        "name": "Cupón de pago",
                        "tag": "",
                        "limit_amount": 300
                    }, {
                        "id": 1282,
                        "ctime": 1678955599434,
                        "coupon_id": 269,
                        "coupon_type": 4,
                        "valid_days": 3,
                        "distribute_count": 1000,
                        "distribute_amount": 100,
                        "effective_date": 1678955599434,
                        "expire_date": 1679205599000,
                        "is_available": 1,
                        "send_amount": 100,
                        "name": "Cupón de monto adicional",
                        "tag": "",
                        "limit_amount": 0
                    }*/
                ],
                couponsObj: null,
                payAmount: null,
                interest_fee: null, //利息
                fee: null, //服务费
                vat: null,
                shouPay: null,
                origin_contract_amount: 0,
                coupon_type: 0,
            }
        },
        mounted() {
            let _this = this;
            let useloanAmount = this.$store.state.common.loanAmount; //借款金额
            let useloanDays = this.$store.state.common.loanDays; //借款天数
            let usecoupons = this.$store.state.common.coupons; //优惠券

            if(useloanDays){
                useloanDays = JSON.parse(useloanDays);
            }

            if(usecoupons){
                usecoupons = JSON.parse(usecoupons);
            }

            this.loanAmount = useloanAmount;
            this.loanDays = useloanDays.max_period;
            this.payTimes = useloanDays.paytime;
            this.couponsObj = usecoupons;

            let useaccessToken = this.$store.state.common.accessToken;
            let usepageType = this.$store.state.common.pageType;

            let postData = {};
            if(usecoupons && usecoupons.coupon_id){
                postData = {
                    access_token: useaccessToken,
                    "source":"Rapidayuda",
                    channel: usepageType,
                    coupon_id: String(usecoupons.id),
                }
            } else {
                postData = {
                    access_token: useaccessToken,
                    "source":"Rapidayuda",
                    channel: usepageType,
                }
            }

            this.$toast.loading({
                message: 'Bajo petición...',
                duration: 0,
                forbidClick: true
            });

            calculationtrial(postData).then(res => {
                _this.$toast.clear();
                console.log(res)
                if(res.code == 0) {
                    let result = res.result;
                    _this.loanAmount = result.loan;
                    _this.loanDays = result.period;
                    _this.bankCode = result.bank_no;
                    _this.payTimes = result.repay_time;
                    _this.payAmount = result.origin_contract_amount;
                    _this.interest_fee = result.interest_fee;
                    _this.fee = result.fee;
                    _this.vat = result.vat;
                    _this.coupon_type = result.coupon_type;

                    store.commit('common/SET_LOANA_AMOUNT', result.loan);

                    if(result.coupon_type == 1) {
                        if (result.origin_contract_amount > result.coupon_amount) {
                            _this.shouPay = result.origin_contract_amount - result.coupon_amount;
                            _this.origin_contract_amount = result.origin_contract_amount;
                        } else {
                            _this.shouPay = 0;
                            _this.origin_contract_amount = result.origin_contract_amount;
                        }
                    } else {
                        _this.shouPay = result.origin_contract_amount;
                    }

                    let bankData = {
                        bank_card_type: result.bank_card_type,
                        bank_name: result.bank_name,
                        bank_no: result.bank_no,
                    };

                    store.commit('common/SET_BANK_DATA', bankData);
                }
            }, error => {
                _this.$toast.clear();
            });

            /*setTimeout(() => {
                _this.$toast.clear();
                let result = {
                    "bank_card_type": 1,
                    "bank_name": "STP",
                    "bank_no": "324364575678354345",
                    "contract_amount": 509,
                    "coupon_amount": 0,
                    "coupon_type": 0,
                    "current_step": 0,
                    "fee": 54, //服务费
                    "have_unused_count": 0,
                    "interest_fee": 40, //利息
                    "loan": 400, //借款
                    "origin_contract_amount": 509,
                    "origin_loan": 400,
                    "period": 10,
                    "repay_interest": 40,
                    "repay_time": "30/04/2023",
                    "server_time": 1682061491274,
                    "vat": 15
                }
                _this.loanAmount = result.loan;
                _this.loanDays = result.period;
                _this.bankCode = result.bank_no;
                _this.payTimes = result.repay_time;
                _this.payAmount = result.origin_contract_amount;
                _this.interest_fee = result.interest_fee;
                _this.fee = result.fee;
                _this.vat = result.vat;

                let bankData = {
                    bank_card_type: 1,
                    bank_name: "STP",
                    bank_no: "324364575678354345",
                };

                store.commit('common/SET_BANK_DATA', bankData);

            }, 200);*/

            this.getCouponList();
        },
        filters: {
            hideBankcode(val) {
                if (val) {
                    return `${val.substring(0, 4)}******${val.substring(val.length - 4)}`;
                }
            }
        },
        methods: {
            gotoLoan() {
                //this.$toast('Ir a la app para ver')
                this.$router.push({
                    path: '/compoundloan/loanagreement'
                })
            },
            gotoCoupons() {
                this.$router.push({
                    path: '/compoundloan/coupons',
                    query: {
                        pageType: 'confirmloan'
                    }
                })
            },
            getCouponList() { //获取优惠券列表
                let _this = this;

                let useaccessToken = this.$store.state.common.accessToken;

                /*let postData = {
                    "access_token": useaccessToken,
                    "list_type": 1, //1未使用 2已使用 3已过期
                    "offset": 1,
                    "coupon_type": 4, //1减免、4提额
                };*/
                let postData = {
                    "access_token": useaccessToken,
                    "list_type": 1, //1未使用 2已使用 3已过期
                    //"coupon_type": 4, //1减免、4提额
                };

                couponUnused(postData).then(res => {
                    console.log(res)
                    if(res.code == 0){
                        let useData = res.data;
                        let usableCoupons = useData.usableCoupons;
                        _this.couponsList = usableCoupons;
                    }
                })

            },
            onShowPayment() {
                this.confirmShow = true;
            },
            is_https(){
                return 'https:' == location.protocol ? true: false;
            },
            getUserLocation() {
                let _this = this;
                const result = new Promise(function(resolve, reject){
                    if (window.navigator.geolocation) {
                        if(_this.is_https()){
                            window.navigator.geolocation.getCurrentPosition((position) => {
                                console.log(position)
                                var longitude = position.coords.longitude;
                                var latitude = position.coords.latitude;
                                resolve(position)
                            }, (error) => {
                                console.log(error)
                                resolve(error)
                            })
                        } else {
                            resolve('noHTML5')
                        }

                    } else {
                        this.$toast('Su navegador no soporta el uso de HTML5 para obtener servicios de geolocalización');
                        //您的浏览器不支持使用HTML5来获取地理位置服务
                        resolve('noHTML5')
                    }
                });
                return result;
            },
            onConfirm() {
                console.log(11111)
                let _this = this;
                this.getUserLocation().then(res => {
                    console.log(res)
                    if(res){
                        if(res == 'noHTML5'){ //浏览器不支持使用HTML5
                            console.log(111)
                            _this.confirmorder('-1','-1')
                        } else if(res.message && res.message == 'User denied Geolocation'){ //用户拒绝
                            console.log(res.message)
                            _this.confirmorder('-1','-1')
                        } else if(res.coords){ //获取坐标
                            let latitude = res.coords.latitude;
                            let longitude = res.coords.longitude;
                            _this.confirmorder(longitude,latitude)
                        } else {
                            _this.confirmorder('-1','-1')
                        }
                    } else {
                        _this.confirmorder('-1','-1')
                    }
                });
            },
            confirmorder(longitude, latitude){
                //this.confirmShow = true;
                let usecontratoChecked = this.contratoChecked;
                if(usecontratoChecked){
                    console.log(111)

                    uniqueKey.Init()

                    uniqueKey.record('02fe8175').then(res => {
                        console.log(res)
                        let useaccessToken = this.$store.state.common.accessToken;
                        let usepageType = this.$store.state.common.pageType;
                        let postData = {};

                        let usecoupons = this.$store.state.common.coupons; //优惠券

                        if(usecoupons){
                            usecoupons = JSON.parse(usecoupons);
                        }

                        if(res){
                            if(usecoupons && usecoupons.coupon_id){
                                postData = {
                                    access_token: useaccessToken,
                                    source:"Rapidayuda",
                                    channel: usepageType,
                                    longitude: longitude.toString(),
                                    latitude: latitude.toString(),
                                    token_key: res,
                                    coupon_id: String(usecoupons.id),
                                };
                            } else {
                                postData = {
                                    access_token: useaccessToken,
                                    source:"Rapidayuda",
                                    channel: usepageType,
                                    longitude: longitude.toString(),
                                    latitude: latitude.toString(),
                                    token_key: res,
                                };
                            }

                        } else {
                            if(usecoupons && usecoupons.coupon_id){
                                postData = {
                                    access_token: useaccessToken,
                                    source:"Rapidayuda",
                                    channel: usepageType,
                                    longitude,
                                    latitude,
                                    coupon_id: String(usecoupons.id),
                                };
                            } else {
                                postData = {
                                    access_token: useaccessToken,
                                    source:"Rapidayuda",
                                    channel: usepageType,
                                    longitude,
                                    latitude,
                                };
                            }

                        }

                        loadingShow()

                        confirmOrder(postData).then(res => {
                            console.log(res)
                            loadingHide()
                            if(res.code == 0){
                                this.$store.commit('common/SET_COUPONS', null);

                                this.$router.replace({
                                    path: '/compoundloan/examine'
                                })
                            }
                        }, error => {
                            loadingHide()
                        });
                    });

                } else {
                    this.$toast('Por favor, acepte el acuerdo primero') //请同意协议
                }
            },
            gotoBank() {
                this.$router.push({
                    path: '/compoundloan/bank'
                })
            }
        }
    }
</script>

<style>
    .confirmloan_wrap{
        width: 100%;
        height: 100vh;
        background: #F6F6F6;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        padding: 0.888889rem 0.444444rem 0 0.444444rem;
    }
    .confirmloan_cont{
        width: 100%;
        height: auto;
    }
    .confirmloan_ctoptitle{
        width: 100%;
        height: 0.833333rem;
        font-size: 0.388889rem;
        font-weight: 500;
        color: #5A5A63;
        line-height: 0.388889rem;
    }
    .confirmloan_ctopdesc{
        width: 100%;
        height: 1.444444rem;
        font-size: 0.888889rem;
        font-weight: 500;
        color: #1A1A1A;
        line-height: 0.888889rem;
    }
    .confirmloan_ctopdesc span{
        font-size: 0.5rem;
        font-weight: bold;
        color: #1A1A1A;
        padding-right: 0.138889rem;
    }
    .confirmloan_cmain{
        width: 100%;
        height: auto;
        background: #ffffff;
        border-radius: 0.333333rem;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        padding: 0.611111rem 0.444444rem 0 0.444444rem;
    }
    .confirmloan_cmtitle{
        width: 100%;
        height: 0.527778rem;
        font-size: 0.444444rem;
        font-weight: 500;
        color: #1A1A1A;
        line-height: 0.444444rem;
    }
    .confirmloan_cmview{
        width: 100%;
        height: auto;
    }
    .confirmloan_cmview .itoken_check_vitleft{
        color: #808080;
    }
    .confirmloan_cmitems{
        width: 100%;
        height: 1.611111rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .confirmloan_cmileft{
        width: auto;
        height: auto;
        font-size: 0.388889rem;
        font-weight: 400;
        color: #808080;
    }
    .confirmloan_cmiright{
        width: auto;
        height: auto;
        font-size: 0.388889rem;
        font-weight: 400;
        color: #1A1A1A;
    }
    .confirmloan_cmview .itoken_check_vitems{
        padding-top: 0.611111rem;
        padding-bottom: 0.611111rem;
    }
    .confirmloan_cmirview{
        width: auto;
        height: 0.666667rem;
        display: flex;
        justify-content: flex-start;
    }
    .confirmloan_cmirvl{
        width: auto;
        height: 0.666667rem;
        font-size: 0.388889rem;
        font-weight: 400;
        color: #1A1A1A;
        line-height: 0.666667rem;
    }
    .confirmloan_cmirvl span{
        padding-right: 0.166667rem;
        color: #808080;
        text-decoration:line-through
    }
    .confirmloan_cmirvr{
        width: 0.666667rem;
        height: 0.666667rem;
        margin-left: 0.111111rem;
    }
    .confirmloan_cmirvr img{
        display: block;
        width: 100%;
        height: 100%;
    }
    .confirmloan_cmview .itoken_check_vitleft_coupon img{
        display: block;
        width: 0.444444rem;
        height: 0.444444rem;
    }
    .confirmloan_cmcontrato_wrap{
        width: 100%;
        height: auto;
        padding: 0.305556rem 0 1.361111rem 0;
    }
    .confirmloan_cmcontrato_cont{
        width: 100%;
        height: 0.666667rem;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    .confirmloan_cmcontrato_cleft{
        width: auto;
        height: 0.666667rem;
        display: flex;
        align-items: center;
    }
    .confirmloan_cmcontrato_cleft img{
        display: block;
        width: 0.666667rem;
        height: 0.666667rem;
    }
    .confirmloan_cmcontrato_cleft .van-checkbox{
        width: auto;
        height: 0.666667rem;
    }
    .confirmloan_cmcontrato_cleft .van-checkbox__icon{
        width: 0.666667rem;
        height: 0.666667rem;
    }
    .confirmloan_cmcontrato_cleft .van-checkbox__label{
        width: auto;
        height: 0.666667rem;
        margin-left: 0.111111rem;
        font-size: 0.361111rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 500;
        color: #999999;
        line-height: 0.666667rem;
    }
    .confirmloan_cmcontrato_cright{
        width: auto;
        height: 0.666667rem;
        margin-left: 0.111111rem;
        font-size: 0.361111rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 500;
        color: #999999;
        line-height: 0.666667rem;
        text-decoration: underline;
    }
    .confirmloan_cmcontrato_cright span{
        color: #00CA88;
    }
    .confirmloan_msgwrap{
        width: 100%;
        height: auto;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        padding: 0 0.444444rem 1.666667rem 0.444444rem;
    }
    .confirmloan_msgitems{
        width: 100%;
        height: 1.0rem;
        display: flex;
        justify-content: space-between;
    }
    .confirmloan_msgileft{
        width: auto;
        height: 0.388889rem;
        font-size: 0.388889rem;
        font-weight: 500;
        color: #808080;
        line-height: 0.388889rem;
    }
    .confirmloan_msgiright{
        width: auto;
        height: 0.388889rem;
        font-size: 0.388889rem;
        font-weight: 500;
        color: #1A1A1A;
        line-height: 0.388889rem;
    }
    .confirmloan_msgiright span{
        padding-left: 0.138889rem;
    }
    .confirmloan_cmview .itoken_check_vitems_coupons{
        padding-top: 0.485rem;
        padding-bottom: 0.485rem;
    }
    .itoken_check_vitop_new{
        height: 0.42rem;
    }
</style>
