import request from '@/utils/request'

/** 获取订单状态 */
export function loaninfo(data) {
    return request({
        url: 'h5/v2/orders/loan/info',
        method: 'post',
        data,
    })
}


/** 产品列表 */
export function productInfo(data) {
    return request({
        url: 'h5/v2/product/info/get',
        method: 'post',
        data,
    })
}

/** 优惠券列表 */
export function couponUnused(data) {
    return request({
        url: "gateway/ApiService/api/v3/h5/account/coupon/unused",
        method: 'post',
        data,
    })
}

/** 优惠券列表2 */
export function couponList(data) {
    return request({
        url: 'gateway/ApiService/api/v3/h5/account/coupon/list',
        method: 'post',
        data,
    })
}


/** 创建订单 */
export function createorder(data) {
    return request({
        url: 'h5/v2/orders/order/create',
        method: 'post',
        data,
    })
}


/** 试算订单 */
export function calculationtrial(data) {
    return request({
        url: 'h5/v2/orders/trial/calculation',
        method: 'post',
        data,
    })
}

/** 银行卡列表 */
export function bankList(data) {
    return request({
        url: 'gateway/ApiService/api/v3/h5/payment/bank/list',
        method: 'post',
        data,
    })
}

/** 修改银行卡 */
export function updateCard(data) {
    return request({
        url: 'h5/v2/account/bank/card/update',
        method: 'post',
        data,
    })
}


/** 确认订单 */
export function confirmOrder(data) {
    return request({
        url: 'h5/v2/orders/order/confirm',
        method: 'post',
        data,
    })
}

/** 获取还款信息 */
export function homeOrder(data) {
    return request({
        url: '/h5/v2/ucenter/order/home',
        method: 'post',
        data,
    })
}


/** 还款使用优惠券 */
export function paymentCoupon(data) {
    return request({
        url: '/gateway/ApiService/api/v3/h5/payment/coupon/payment_coupon',
        method: 'post',
        data,
    })
}






