<template>
    <div class="detail_wrap">
        <div class="detail_cont">
            <div class="detail_cview">
                <div class="detail_cvtitle">
                    Referencia de pago
                </div>
                <div class="detail_cvcopys">
                    <div class="detail_cvcopy">
                        <div class="detail_cvcopyleft">
                            <span>4563 3728 3980 3892 83</span>
                        </div>
                        <div class="detail_cvcopyright">Copiar</div>
                    </div>
                </div>
                <div class="detail_cvqrccode">
                    <div class="detail_cvqrccode_view">

                    </div>
                </div>

                <div class="detail_cvttitle">Monto designado</div>
                <div class="detail_cvtamount">
                    <div class="detail_cvtamitems">
                        <div class="detail_cvtamileft">Pendiente de pago</div>
                        <div class="detail_cvtamiright">$<span>830</span></div>
                    </div>
                    <div class="detail_cvtamitems">
                        <div class="detail_cvtamileft">Comisión</div>
                        <div class="detail_cvtamiright">$<span>25</span></div>
                    </div>
                </div>

                <div class="detail_cvtamawran">
                    <div class="repayment_mtthr">
                        <div class="repayment_mtthr_cont detail_mtthr_cont">
                            <div class="repayment_mtthr_cleft">
                                <div class="repayment_mtthr_clicon">
                                </div>
                            </div>
                            <div class="repayment_mtthr_cright">
                                Dicha referencia de pago solo sirve para monto dentro de $XXXX. Si tu pedido supera este monto, por favor pon en contacto con atención al cliente para modificar y realizar pago parcial.
                            </div>
                        </div>
                    </div>
                </div>




            </div>
        </div>
    </div>
</template>

<script>

    export default {
        name: 'repaymentdetail',
        data() {
            return {
                activeNav: 'repaymentdetail',
            }
        },
        mounted() {

        },
        methods: {

        }
    }
</script>

<style>
    .detail_wrap{
        width: 100%;
        height: auto;
        background: #F6F6F6 url(../../assets/mobimg/detail_banner.png) no-repeat top right;
        background-size: 100%;
    }
    .detail_cont{
        width: 100%;
        height: auto;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        padding: 0.555556rem 0.444444rem 0 0.444444rem;
    }
    .detail_cview{
        width: 100%;
        height: auto;
        background: #FFFFFF;
        border-radius: 0.333333rem;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        padding: 0 0.444444rem 0.444444rem 0.444444rem;
    }
    .detail_cvtitle{
        width: 100%;
        height: 1.888889rem;
        font-size: 0.444444rem;
        font-weight: 500;
        color: #343E3A;
        line-height: 1.555556rem;
    }
    .detail_cvcopys{
        width: 100%;
        height: 1.388889rem;
    }
    .detail_cvcopy{
        width: 100%;
        height: 0.833333rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #F6F6F6;
        border-radius: 0.416667rem;
    }
    .detail_cvcopyleft{
        width: 6.333333rem;
        height: 0.833333rem;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        padding-left: 0.444444rem;
        display: flex;
        align-items: center;
    }
    .detail_cvcopyleft span{
        font-size: 0.388889rem;
        font-weight: 500;
        color: #343E3A;
    }
    .detail_cvcopyright{
        width: 1.888889rem;
        height: 0.833333rem;
        background: #00CA88;
        border-radius: 0.416667rem;
        font-size: 0.361111rem;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 0.833333rem;
        text-align: center;
    }
    .detail_cvqrccode{
        width: 100%;
        height: auto;
        min-height: 1.555556rem;
        display: flex;
        justify-content: center;

    }
    .detail_cvqrccode_view{
        display: inline-block;
        width: auto;
        height: auto;
        margin: 0 auto;
    }
    .detail_cvttitle{
        width: 100%;
        height: 1.111111rem;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        padding-top: 0.444444rem;
        font-size: 0.444444rem;
        font-weight: 500;
        color: #343E3A;
        line-height: 0.666667rem;
    }
    .detail_cvtamount{
        width: 100%;
        height: auto;
        padding-top: 0.444444rem;
    }
    .detail_cvtamitems{
        width: 100%;
        height: 0.833333rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .detail_cvtamileft{
        width: auto;
        font-size: 0.388889rem;
        font-weight: 500;
        color: #808080;
    }
    .detail_cvtamiright{
        width: auto;
        font-size: 0.388889rem;
        font-weight: 500;
        color: #343E3A;
    }
    .detail_cvtamiright span{
        padding-left: 0.083333rem;
    }
    .detail_cvtamawran{
        width: 100%;
        margin-top: 0.666667rem;
        background: #F6F6F6;
        border-radius: 0.277778rem;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        padding: 0.222222rem 0.444444rem 0.222222rem 0.222222rem;
    }
    .detail_cvtamawran .repayment_mtthr_cleft{
        width: 0.666667rem;
        height: 100%;
    }
    .detail_cvtamawran .repayment_mtthr_clicon{
        width: 0.666667rem;
        height: 0.666667rem;
        background: #F6F6F6;
    }
    .detail_mtthr_cont{
        align-items: center;
    }
    .detail_cvtamawran .repayment_mtthr_cright{
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        padding-left: 0.111111rem;
    }


</style>
