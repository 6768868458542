import Vue from 'vue'
import VueRouter from 'vue-router'
import home from '../views/home.vue'
import sobre from "../views/sobre.vue"
import aviso from "../views/Aviso.vue"
import ayuda from "../views/ayuda.vue"
import loan from "../views/loan.vue"
import transparenciaOne from "../views/transparenciaOne.vue"
import transparenciaTwo from "../views/transparenciaTwo.vue"
import transparenciaThree from "../views/transparenciaThree.vue"
import compoundloan from "../views/compoundloan.vue"
import compoundeIndex from "../views/compound/indextoa.vue"
import compoundeIndextob from "../views/compound/indextob.vue"
import compounduseall from "../views/compound/useall.vue"
import compoundtoken from "../views/compound/indextoken"
import compoundliving from "../views/compound/livingcertification.vue"
import confirmloan from "../views/compound/confirmloan.vue"
import compoundbank from "../views/compound/bank.vue"
import compoundexamine from "../views/compound/examine.vue"
import compoundcoupons from "../views/compound/coupons.vue"
import compoundcouponlist from "../views/compound/couponlist.vue"
import repayment from "../views/compound/repayment.vue"
import repaymentdetail from "../views/compound/repaymentdetail.vue"
import repaymentonline from "../views/compound/repaymentonline.vue"
import compoundindexnew from "../views/compound/indexnew.vue"
import loanagreement from "../views/compound/loan.vue"

import { getAccessToken, getIsRepeatLoan } from '@/utils/auth' // getToken from cookie

/*const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
    return originalPush.call(this, location).catch(err => err)
}*/

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        redirect: "/home",
    },
    {
        path: '/home',
        name: 'home',
        component: home
    },
    {
        path: '/about',
        name: 'about',
        component: sobre
    },
    {
        path: '/notice',
        name: 'notice',
        component: aviso
    },
    {
        path: '/help',
        name: 'help',
        component: ayuda
    },
    {
        path: '/loan',
        name: 'loan',
        component: loan
    },
    {
        path: '/transparenciaOne',
        name: 'transparenciaone',
        component: transparenciaOne
    },
    {
        path: '/transparenciaTwo',
        name: 'transparenciatwo',
        component: transparenciaTwo
    },
    {
        path: '/transparenciaThree',
        name: 'transparenciathree',
        component: transparenciaThree
    },
    {
        path: '/compoundloan',
        name: 'compoundloan',
        component: compoundloan,
        redirect: '/compoundloan/indextoa',
        children: [
            {

                path: '/compoundloan/indexnew',
                name: 'compoundindexnew',
                component: compoundindexnew,
                meta: {
                    title: 'Rapidayuda'
                }
            },
            {
                path: '/compoundloan/indextoa',
                name: 'compoundeIndex',
                component: compoundeIndex,
                meta: {
                    title: 'Rapidayuda'
                }
            },
            {
                path: '/compoundloan/indextob',
                name: 'compoundeIndextob',
                component: compoundeIndextob,
                meta: {
                    title: 'Rapidayuda'
                }
            },
            {
                path: '/compoundloan/useall',
                name: 'compoundeuseall',
                component: compounduseall,
                meta: {
                    title: 'Rapidayuda'
                }
            },
            {
                path: '/compoundloan/indextoken',
                name: 'compoundeIndextoken',
                component: compoundtoken,
                meta: {
                    title: 'Rapidayuda'
                }
            },
            {
                path: '/compoundloan/living',
                name: 'compoundeLiving',
                component: compoundliving,
                meta: {
                    title: 'Verificación facial'
                }
            },
            {
                path: '/compoundloan/confirmloan',
                name: 'confirmloan',
                component: confirmloan,
                meta: {
                    title: 'Confirmar el monto'
                }
            },
            {
                path: '/compoundloan/bank',
                name: 'compoundbank',
                component: compoundbank,
                meta: {
                    title: 'Información sobre colecciones'
                }
            },
            {
                path: '/compoundloan/examine',
                name: 'compoundexamine',
                component: compoundexamine,
                meta: {
                    title: 'Rapidayuda'
                }
            },
            {
                path: '/compoundloan/coupons',
                name: 'coupons',
                component: compoundcoupons,
                meta: {
                    title: 'Confirmar el monto'
                }
            },
            {
                path: '/compoundloan/couponlist',
                name: 'couponlist',
                component: compoundcouponlist,
                meta: {
                    title: 'Confirmar el monto'
                }
            },
            {
                path: '/compoundloan/repayment',
                name: 'repayment',
                component: repayment,
                meta: {
                    title: 'Rapidayuda'
                }
            },
            {
                path: '/compoundloan/repaymentdetail',
                name: 'repaymentdetail',
                component: repaymentdetail,
                meta: {
                    title: 'Reembolso'
                }
            },
            {
                path: '/compoundloan/repaymentonline',
                name: 'repaymentonline',
                component: repaymentonline,
                meta: {
                    title: 'Reembolso'
                }
            },
            {
                path: '/compoundloan/loanagreement',
                name: 'loanagreement',
                component: loanagreement,
                meta: {
                    title: 'Rapidayuda'
                }
            },
        ]
    }

];


const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

const whiteList = [
    '/',
    '/home',
    '/about',
    '/notice',
    '/help',
    '/loan',
    '/transparenciaOne',
    '/transparenciaTwo',
    '/transparenciaThree',
    '/compoundloan/indextoa',
    '/compoundloan/indextob',
    '/compoundloan/indexnew',
    '/compoundloan/bank'
]; // no redirect whitelist

router.beforeEach((to, from, next) => {
    if (to.meta.title) {
        document.title = to.meta.title
    }

    try {
        // 判断是否登录状态
        const accessToken = getAccessToken();
        const isRepeatLoan = getIsRepeatLoan();

        const useisRepeatLoan = isRepeatLoan == 'false' ? false : true;

        const isValid = Boolean(accessToken);
        console.log(39999)
        if (isValid) {
            if (to.path == '/compoundloan/indextoa' || to.path == '/compoundloan/indextob') {
                if(useisRepeatLoan){ //复贷
                    next({ path: '/compoundloan/indextoken' }) // 到首页
                } else {
                    next({ path: '/compoundloan/indexnew' }) // 到首页
                }

            } else {
                console.log('前往新的页面');
                console.log(to.path)
                next()
            }
        } else {
            if (whiteList.indexOf(to.path) !== -1) { // 在免登录白名单，直接进入
                next()
            } else {
                next({ path: '/compoundloan/indextoa' })
            }
        }
    } catch (error) {
        window._error = error;
        next({ path: '/compoundloan/indextoa' })
    }
});


export default router
