<template>
    <div>
        <router-view/>
    </div>
</template>

<script>

    export default {
        name: 'compoundeloan',
        data () {
            return {

            }
        },
        mounted() {

        },
        methods: {

        }
    }
</script>

<style>
    .mobile_wrap{
        width: 100%;
        position: fixed;
        left: 0;
        top: 0;
        z-index: 1001;
    }
    .mobile_wrap .van-cell{
        padding: 0;
        background-color: #FFFFFF !important;
    }
    .mobile_wrap .van-collapse-item{
        background-color: #FFFFFF !important;
    }
    .mobile_wrap .van-cell--clickable:active {
        background-color: #FFFFFF !important;
    }
    .mobile_wrap .van-collapse-item__content{
        padding: 0;
        background-color: #FFFFFF !important;
    }
    .mobile_wrap .common_problem_wrap_mobile{
        width: 100%;
        height: auto;
        padding-top: 0;
        padding-bottom: 0;
    }
    .mobile_wrap .common_problem_view_mobile{
        width: 100%;
        box-sizing: border-box;
        padding: 0 0.444444rem 0 0.444444rem;
        background: #ffffff;
    }
    .mobile_wrap .common_title_wrap{
        width: 100%;
        height: 1.777778rem;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        padding: 0 0 0 0.694444rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .mobile_wrap .common_title_left{
        width: auto;
        height: 0.666667rem;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    .mobile_wrap .common_title_lname{
        width: 100%;
        height: 0.583333rem;
        font-size: 0.444444rem;
        font-weight: bold;
        color: #1A1A1A;
    }
    .mobile_wrap .common_title_ldesc{
        width: 100%;
        font-size: 0.333333rem;
        font-weight: 400;
        color: #999999;
    }
    .mobile_wrap .common_tleft_text{
        width: auto;
        padding-left: 0.277778rem;
        font-size: 0.388889rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #8C8E94;
    }
    .mobile_wrap .common_title_left .isopen{
        color: #FFFFFF;
    }
    .mobile_wrap .common_title_right{
        width: auto;
        height: 1.777778rem;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        box-sizing: border-box;
        padding-right: 0.833333rem;
    }
    .mobile_wrap .common_trview{
        width: 0.666667rem;
        height: 0.666667rem;
    }
    .mobile_wrap .common_trview img{
        display: block;
        width: 100%;
    }
    .mobile_wrap .common_children_wrap{
        width: 100%;
        height: calc(100vh - 1.777778rem);
        box-sizing: border-box;
        padding-top: 0.555556rem;
        padding-left: 0.555556rem;
        padding-bottom: 0.555556rem;
        padding-right: 0;
    }
    .mobile_wrap .children_title_wrap{
        width: 100%;
        height: auto;
        padding-top: 0.555556rem;
    }
    .mobile_wrap .children_title_left{
        width: 100%;
        height: auto;
        display: flex;
        justify-content: flex-start;
    }
    .mobile_wrap .children_tleft_icon{
        width: 0.5rem;
        height: 0.472222rem;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    .mobile_wrap .children_tleft_icon img{
        display: block;
        width: 0.277778rem;
        height: 0.277778rem;
    }
    .mobile_wrap .children_tleft_text{
        width: 100%;
        height: auto;
        box-sizing: border-box;
        padding-right: 1.055556rem;
        font-size: 0.333333rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(255,255,255,0.8);
        line-height: 0.472222rem;
    }
    .mobile_wrap .children_cont{
        width: 100%;
        height: auto;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        padding: 0.222222rem 1.055556rem 0 0.472222rem;
        overflow-y: scroll;
    }
    /* 1px边框 底部*/
    .scale-1px{
        position: relative;
        border:none;
    }
    .scale-1px:after{
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        background: #DEE0E6;
        width: 100%;
        height: 1px;
        -webkit-transform: scaleY(0.5);
        transform: scaleY(0.5);
        -webkit-transform-origin: 0 0;
        transform-origin: 0 0;
    }
    /* 1px边框 顶部*/
    .scaletop-1px{
        position: relative;
        border:none;
    }
    .scaletop-1px:after{
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        background: #DEE0E6;
        width: 100%;
        height: 1px;
        -webkit-transform: scaleY(0.5);
        transform: scaleY(0.5);
        -webkit-transform-origin: 0 0;
        transform-origin: 0 0;
    }

</style>
