
<template>
    <div class="coupons_wrap">
        <van-tabs v-model="active" @click="ontabClick">
            <van-tab title="Disponible" name="effect">
                <div class="couponslist_cont" v-if="messageList && messageList.length">
                    <div class="couponslist_contview">
                        <van-radio-group v-model="radioCoupons" @change="onRadioChange">
                            <van-cell-group>
                                <template v-for="(item, index) in messageList">
                                    <div class="couponslist_items" :key="index">
                                        <van-cell clickable @click.stop="onCouponscell(item.couponIdStr)">
                                            <div class="couponslist_item">
                                                <div class="couponslist_itop coupons_itop_scale-1px">
                                                    <div class="clitop_tview">
                                                        <div class="clitop_tvleft">
                                                            <!--{{item.name}}-->
                                                            <template v-if="item.coupon_type == 4">
                                                                Cupón de monto adicional
                                                            </template>
                                                            <template v-else-if="item.coupon_type == 1">
                                                                Cupón de pago
                                                            </template>
                                                            <template v-else>
                                                                {{item.name}}
                                                            </template>
                                                        </div>
                                                        <div class="clitop_tvright">$<span>{{item.distribute_amount}}</span></div>
                                                    </div>
                                                    <div class="clitop_dview">
                                                        <!--<div class="clitop_dvtimes">Validez: 23:49:46</div>-->
                                                        <div class="clitop_dvdate">
                                                            {{item.effective_date | formateDate}}-{{item.expire_date | formateDate}}
                                                        </div>

                                                    </div>
                                                </div>
                                                <div class="couponslist_idown">
                                                    <div class="clidown_head">
                                                        <div class="clidown_hleft" @click.stop="onShowmsg(item.couponIdStr)">
                                                            <div class="clidown_hlname">Más detalles</div>
                                                            <div class="clidown_hlicon">
                                                                <template v-if="item.isshow">
                                                                    <img src="@/assets/mobimg/mesg_openicon.png" alt="">
                                                                </template>
                                                                <template v-else>
                                                                    <img src="@/assets/mobimg/mesg_closeicon.png" alt="">
                                                                </template>
                                                            </div>
                                                        </div>


                                                        <div class="clidown_hright">
                                                             <van-radio :name="item.couponIdStr">
                                                                    <template #icon="props">
                                                                        <img v-if="props.checked" src="@/assets/mobimg/radio_ischeck.png" alt="">
                                                                        <img v-else src="@/assets/mobimg/radio_nocheck.png" alt="">
                                                                    </template>
                                                             </van-radio>
                                                            <div class="clidown_hright_dv"></div>
                                                        </div>

                                                    </div>
                                                    <div class="clidown_message" v-if="item.isshow">
                                                        <template v-if="item.tag">
                                                            {{item.tag}}
                                                        </template>
                                                        <template v-else>--</template>
                                                    </div>
                                                </div>
                                            </div>
                                        </van-cell>
                                    </div>
                                </template>
                            </van-cell-group>
                        </van-radio-group>
                    </div>
                </div>
                <div v-else class="couponslist_cont">
                    <div class="couponslist_nodata">
                        <div class="coo_nodata_img">
                            <img src="@/assets/mobimg/nocoupons.png" alt="">
                        </div>
                        <div class="coo_nodata_text">Aún no hay cupón disponible</div>
                    </div>
                </div>
            </van-tab>
            <van-tab title="Vencido" name="expire">
                <div class="couponslist_cont expirelist_view" v-if="expireList.length">
                    <template v-for="(item, index) in expireList">
                        <div class="couponslist_items" :key="index">
                            <div class="couponslist_item">
                                <div class="couponslist_itop coupons_itop_scale-1px">
                                    <div class="clitop_tview">
                                        <div class="clitop_tvleft">
                                            <!--{{item.name}}-->
                                            <template v-if="item.coupon_type == 4">
                                                Cupón de monto adicional
                                            </template>
                                            <template v-else-if="item.coupon_type == 1">
                                                Cupón de pago
                                            </template>
                                            <template v-else>
                                                {{item.name}}
                                            </template>
                                        </div>
                                        <div class="clitop_tvright">$<span>{{item.distribute_amount}}</span></div>
                                    </div>
                                    <div class="clitop_dview">
                                        <!--<div class="clitop_dvtimes">Validez: 23:49:46</div>-->
                                        <div class="clitop_dvdate">{{item.effective_date | formateDate}}-{{item.expire_date | formateDate}}</div>
                                    </div>
                                </div>
                                <div class="couponslist_idown" @click="onShowmsg(item.couponIdStr)">
                                    <div class="clidown_head">
                                        <div class="clidown_hleft">
                                            <div class="clidown_hlname">Motivo de indisponible：</div>
                                            <div class="clidown_hlicon"></div>
                                        </div>
                                    </div>
                                    <div class="clidown_message">
                                        <template v-if="item.tag">
                                            {{item.tag}}
                                        </template>
                                        <template v-else>--</template>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
                <div v-else class="couponslist_cont">
                    <div class="couponslist_nodata">
                        <div class="coo_nodata_img">
                            <img src="@/assets/mobimg/nocoupons.png" alt="">
                        </div>
                        <div class="coo_nodata_text">Aún no hay cupón disponible</div>
                    </div>
                </div>
            </van-tab>
        </van-tabs>

        <div class="effect_usewrap effect_usewrap_scale-1px">
            <div class="effect_useleft">
                <template v-if="radioCheckAmount">
                    <div class="effect_uselamount">${{radioCheckAmount}}</div>
                </template>
                <template v-else>
                    <div class="effect_uselamount">$0</div>
                </template>
                <template v-if="coupon_type">
                    <template v-if="coupon_type == 1">
                        <div class="effect_useldesc">Cupón de pago</div>
                    </template>
                    <template v-else-if="coupon_type == 4">
                        <div class="effect_useldesc">Cupón de monto adicional</div>
                    </template>
                </template>
                <template v-else>
                    <div class="effect_useldesc">No has elegido nigún cupón</div>
                </template>
            </div>
            <div class="effect_useright">
                <van-button round color="#00CA88"  style="height: 0.888889rem;" @click="onComfirm">
                    <span style="font-size: 0.388889rem; font-weight: bold;">Confirmar</span>
                </van-button>
            </div>
        </div>

    </div>
</template>

<script>
    import { parseTime, getNextDate, getQueryString } from "@/utils/index";
    import store from '@/store';

    import { couponList, couponUnused, paymentCoupon } from '@/api/loan';

    export default {
        name: 'couponlist',
        data() {
            return {
                activeNav: 'couponlist',
                active: 'effect',
                messageList: [
                    /*{
                        id: 1281,
                        ctime: 1678955599368,
                        coupon_id: 268,
                        coupon_type: 1,
                        valid_days: 4,
                        distribute_count: 100,
                        distribute_amount: 150,
                        effective_date: 1678955599367,
                        expire_date: 1679291999000,
                        is_available: 1,
                        send_amount: 150,
                        name: "Cupón de pago",
                        tag: "",
                        limit_amount: 300,
                        isshow: false,
                    },
                    {
                        id: 1282,
                        ctime: 1678955599434,
                        coupon_id: 269,
                        coupon_type: 4,
                        valid_days: 3,
                        distribute_count: 1000,
                        distribute_amount: 100,
                        effective_date: 1678955599434,
                        expire_date: 1679205599000,
                        is_available: 1,
                        send_amount: 100,
                        name: "Cupón de monto adicional",
                        tag: "",
                        limit_amount: 0
                    }*/
                ],
                expireList: [
                    /*{
                        id: 1282,
                        ctime: 1678955599434,
                        coupon_id: 269,
                        coupon_type: 4,
                        valid_days: 3,
                        distribute_count: 1000,
                        distribute_amount: 100,
                        effective_date: 1678955599434,
                        expire_date: 1679205599000,
                        is_available: 1,
                        send_amount: 100,
                        name: "Cupón de monto adicional",
                        tag: "",
                        limit_amount: 0
                    }*/
                ],
                radioCoupons: null,
                radioCheckAmount: null,
                radioCheckDesc: null,
                checkCoupons: null,
                coupon_type: null,
            }
        },
        mounted() {
            console.log(getQueryString('pageType'));

            let usecoupons = this.$store.state.common.coupons;

            if(usecoupons){
                usecoupons = JSON.parse(usecoupons);
                this.radioCoupons = usecoupons.coupon_id;
                this.radioCheckAmount = usecoupons.distribute_amount;
                this.radioCheckDesc = usecoupons.name;
                this.checkCoupons = usecoupons;
                this.coupon_type = usecoupons.coupon_type;
            } else {
                this.radioCoupons = null;
                this.radioCheckAmount = null;
                this.radioCheckDesc = null;
                this.checkCoupons = null;
                this.coupon_type = null;
            }
            this.getCouponList();
        },
        filters: {
            formateDate(val){
                let useDate = '';
                if(val){
                    useDate = parseTime(val, '{d}/{m}/{y}');
                }
                return useDate
            }
        },
        methods: {
            getCouponList() { //获取优惠券列表
                let _this = this;

                let useaccessToken = this.$store.state.common.accessToken;
                let postData = {
                    "access_token": useaccessToken,
                    "list_type": 1, //1未使用 2已使用 3已过期
                };

                couponUnused(postData).then(res => {
                    console.log(res)

                    if(res.code == 0){
                        let useData = res.data;
                        let usableCoupons = useData.usableCoupons;

                        if(usableCoupons && usableCoupons.length){
                            for(let i=0; i<usableCoupons.length; i++){
                                usableCoupons[i].isshow = false;
                                usableCoupons[i].coupon_id = usableCoupons[i].couponIdStr;
                            }
                        }
                        _this.messageList = usableCoupons;

                        let unusableCoupons = useData.unusableCoupons;
                        if(unusableCoupons && unusableCoupons.length){
                            for(let i=0; i<unusableCoupons.length; i++){
                                unusableCoupons[i].isshow = false;
                                unusableCoupons[i].coupon_id = unusableCoupons[i].couponIdStr;
                            }
                        }

                        _this.expireList = unusableCoupons;
                    }

                })

            },
            onRadioChange(){},
            ontabClick(e){
                console.log(e)
                this.radioCoupons = null;
                this.radioCheckAmount = null;
                this.radioCheckDesc = null;
                this.checkCoupons = null;
                this.coupon_type = null;
                store.commit('common/SET_COUPONS', null);
            },
            onComfirm() {
                //if(this.checkCoupons){
                    let usecheckCoupons = this.checkCoupons

                    console.log(usecheckCoupons)

                    let pageType = getQueryString('pageType');
                    if(pageType){
                        if(pageType == 'home'){ //返回首页
                            if(usecheckCoupons){
                                store.commit('common/SET_COUPONS', usecheckCoupons);
                            } else {
                                store.commit('common/SET_COUPONS', {});
                            }

                            this.$router.push({
                                path: '/compoundloan/indextoken'
                            })
                        } else if(pageType == 'repayment'){

                            let useaccessToken = this.$store.state.common.accessToken;
                            let usepageType = this.$store.state.common.pageType;

                            let postData = {};

                            if(usecheckCoupons){
                                postData = {
                                    "access_token": useaccessToken,
                                    "source":"Rapidayuda",
                                    "channel": usepageType,
                                    coupon_id: usecheckCoupons.id,
                                };
                            } else {
                                postData = {
                                    "access_token": useaccessToken,
                                    "source":"Rapidayuda",
                                    "channel":usepageType,
                                };
                            }


                            this.$toast.loading({
                                message: 'Cargando en...',
                                forbidClick: true,
                            });

                            paymentCoupon(postData).then(res => {
                                if(res.code == 0){
                                    this.$router.push({
                                        path: '/compoundloan/repayment'
                                    })
                                }
                            });


                        } else if(pageType == 'confirmloan'){

                            if(usecheckCoupons){
                                store.commit('common/SET_COUPONS', usecheckCoupons);
                            } else {
                                store.commit('common/SET_COUPONS', {});
                            }

                            this.$router.push({
                                path: '/compoundloan/confirmloan'
                            })
                        }

                    }

               /*} else {
                    this.$toast("请选择券");
                }*/
            },
            onCouponscell(id) {
                console.log(id)
                console.log(this.radioCoupons)
                let useradioCoupons = this.radioCoupons;
                if(useradioCoupons){
                    if(id != useradioCoupons){
                        this.radioCoupons = id;
                        let usemessageList = this.messageList;
                        for(let i=0; i<usemessageList.length; i++){
                            if(usemessageList[i].couponIdStr == id){
                                this.radioCheckAmount = usemessageList[i].distribute_amount;
                                this.radioCheckDesc = usemessageList[i].name;
                                this.checkCoupons = usemessageList[i];
                                this.coupon_type = usemessageList[i].coupon_type;
                            }
                        }
                    } else {
                        this.radioCoupons = null;
                        this.radioCheckAmount = null;
                        this.radioCheckDesc = null;
                        this.checkCoupons = null;
                        this.coupon_type = null;
                        store.commit('common/SET_COUPONS', null);
                    }
                } else {
                    this.radioCoupons = id;
                    let usemessageList = this.messageList;
                    for(let i=0; i<usemessageList.length; i++){
                        if(usemessageList[i].couponIdStr == id){
                            this.radioCheckAmount = usemessageList[i].distribute_amount;
                            this.radioCheckDesc = usemessageList[i].name;
                            this.checkCoupons = usemessageList[i];
                            this.coupon_type = usemessageList[i].coupon_type;
                        }
                    }
                }
            },
            onShowmsg(id){
                if(this.active == 'effect'){
                    let usemessageList = this.messageList;
                    for(let i=0; i<usemessageList.length; i++){
                        if(usemessageList[i].couponIdStr == id){
                            //console.log(1111)
                            console.log(usemessageList[i].isshow)
                            usemessageList[i].isshow = !usemessageList[i].isshow;
                        } else {
                            usemessageList[i].isshow = false;
                        }
                    }
                } else if(this.active == 'expire'){
                    let useexpireList = this.expireList;
                    for(let i=0; i<useexpireList.length; i++){
                        if(useexpireList[i].couponIdStr == id){
                            useexpireList[i].isshow = !useexpireList[i].isshow;
                        } else {
                            useexpireList[i].isshow = false;
                        }

                    }
                }
            },

        }
    }
</script>

<style>
    .coupons_wrap .van-tabs__wrap{
        background: #F6F6F6;
        padding-top: 0.194444rem;
    }
    .coupons_wrap .van-tab{
        color: #1A1A1A;
        font-weight: 500;
        background: #F6F6F6;
        border-bottom: .5px solid #EAEAEA;
        padding-top: 0.138889rem;
    }
    .coupons_wrap .van-tabs__line{
        width: 45px;
        height: 3px;
        background: #00CA88;
    }
    .coupons_wrap .van-tab--active{
        color: #00CA88;
    }
    .couponslist_cont{
        width: 100%;
        height: calc(100vh - 44px);
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        padding: 0.416667rem 0.444444rem 0.444444rem 0.444444rem;
        overflow-x: hidden;
        overflow-y: scroll;
        background: #F6F6F6;
        position: relative;
    }
    .couponslist_items{
        width: 100%;
        height: auto;
        padding-bottom: 0.444444rem;
    }
    .couponslist_item{
        width: 100%;
        height: auto;
        background: #ffffff;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        padding: 0.388889rem 0.444444rem 0 0.444444rem;
        border-radius: 0.333333rem;
        border: 1px solid #EFF1F3;
        position: relative;
    }
    /* 1px边框 底部*/
    .coupons_itop_scale-1px{
        position: relative;
        border:none;
    }
    .coupons_itop_scale-1px:after{
        content: '';
        position: absolute;
        bottom: 0;
        background: #ECECEC;
        width: 100%;
        height: 1px;
        -webkit-transform: scaleY(0.5);
        transform: scaleY(0.5);
        -webkit-transform-origin: 0 0;
        transform-origin: 0 0;
    }
    .couponslist_itop{
        width: 100%;
        height: 1.583333rem;
    }
    .clitop_tview{
        width: 100%;
        height: 0.694444rem;
        display: flex;
        justify-content: space-between;
    }
    .clitop_tvleft{
        width: auto;
        height: 0.666667rem;
        font-size: 0.416667rem;
        font-weight: 500;
        color: #1A1A1A;
        line-height: 0.666667rem;
    }
    .clitop_tvright{
        width: auto;
        height: 0.666667rem;
        font-size: 0.666667rem;
        font-weight: 500;
        color: #FB4840;
        line-height: 0.666667rem;
    }
    .clitop_dview{
        width: 100%;
        height: 0.555556rem;
    }
    .clitop_dvdate{
        width: 100%;
        height: 0.555556rem;
        font-size: 0.333333rem;
        font-weight: 500;
        color: #808080;
        line-height: 0.555556rem;
    }
    .clitop_dvtimes{
        display: inline-block;
        width: auto;
        float: left;
        height: 0.555556rem;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        padding: 0 0.111111rem 0 0.111111rem;
        font-size: 0.333333rem;
        font-weight: 500;
        color: #FB4840;
        line-height: 0.555556rem;
        background: rgba(251, 72, 64, 0.1);
        border-radius: 0.055556rem;
    }
    .couponslist_idown{
        width: 100%;
        height: auto;
    }
    .clidown_head{
        width: 100%;
        height: 1.027778rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .clidown_hleft{
        width: auto;
        height: 0.472222rem;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    .clidown_hlname{
        width: auto;
        height: 0.472222rem;
        font-size: 0.333333rem;
        font-weight: 500;
        color: #808080;
        padding-right: 0.166667rem;
        line-height: 0.472222rem;
    }
    .clidown_hlicon{
        width: 0.277778rem;
        height: 0.277778rem;
    }
    .clidown_hlicon img{
        display: block;
        width: 0.277778rem;
        height: 0.277778rem;
    }
    .clidown_hright{
        width: 0.472222rem;
        height: auto;
        position: relative;
    }
    .clidown_hright img{
        display: block;
        width: 0.472222rem;
        height: 0.472222rem;
    }
    .clidown_hright_dv{
        width: 0.472222rem;
        height: 0.472222rem;
        position: absolute;
        left: 0;
        top: 0;
    }
    .clidown_message{
        width: 100%;
        height: auto;
        padding-bottom: 0.361111rem;
        font-size: 0.305556rem;
        font-weight: 500;
        color: #808080;
        line-height: 0.388889rem;
    }
    .expirelist_view .clitop_tvleft{
        color: #B3B3B3;
    }
    .expirelist_view .clitop_tvright{
        color: #FDC8C5;
    }
    .expirelist_view .clitop_dvdate{
        color: #C2C2C2;
    }
    .expirelist_view .clidown_hlname{
        color: #FB4840;;
    }
    .expirelist_view .clidown_message{
        color: #1A1A1A;
    }
    .couponslist_contview{
        width: 100%;
        height: auto;
        padding-bottom: 1.8rem;
    }
    /* 1px边框 底部*/
    .effect_usewrap_scale-1px{
        position: relative;
        border:none;
    }
    .effect_usewrap_scale-1px:after{
        content: '';
        position: absolute;
        top: 0;
        background: #DEE0E6;
        width: 100%;
        height: 1px;
        -webkit-transform: scaleY(0.5);
        transform: scaleY(0.5);
        -webkit-transform-origin: 0 0;
        transform-origin: 0 0;
    }
    .effect_usewrap{
        width: 100%;
        height: 1.777778rem;
        background: #ffffff;
        position: fixed;
        left: 0;
        bottom: 0;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        padding: 0 0.444444rem 0 0.444444rem;
        display: flex;
        justify-content: space-between;
    }
    .effect_useleft{
        width: auto;
        height: auto;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        padding-top: 0.444444rem;
    }
    .effect_uselamount{
        width: auto;
        height: 0.555556rem;
        font-size: 0.416667rem;
        font-weight: 500;
        color: #FB4840;
        line-height: 0.416667rem;
    }
    .effect_useldesc{
        width: 100%;
        height: auto;
        font-size: 0.333333rem;
        font-weight: 500;
        color: #808080;
        line-height: 0.333333rem;
    }
    .effect_useright{
        width: 2.75rem;
        height: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
    .couponslist_contview .van-cell{
        padding: 0;
        background: #F6F6F6;
    }
    .couponslist_contview .van-cell-group{
        background: #F6F6F6;
    }
    .couponslist_contview .van-hairline--top-bottom::after, .couponslist_contview .van-hairline-unset--top-bottom::after{
        border-width: 0;
    }
</style>
