<template>
    <div class="loan_wrap">
        <h1 class="title">
            CARÁTULA DE CONTRATO DE CRÉDITO SIMPLE
        </h1>
        <h1>NÚMERO DE CRÉDITO: {{data.orderId}}</h1>
        <h1>NOMBRE DEL ACREDITADO: {{data.borrowerName}}</h1>
        <table border="1" cellspacing="0" cllpadding="0">
            <tr>
                <td colspan="5" class="sun_title weight">
                    <p>Nombre Comercial del Producto: {{data.prodCommercialName}}</p>
                    <p>Tipo de Crédito: {{data.creditType}}</p>
                    <p>Aplicación: {{data.appName}}</p>
                    <p>Acreditante: {{data.companyName}}</p>
                </td>
            </tr>
            <tr class="weight">
                <td>
                    <p>CAT (Costo Anual Total):</p>
                    <p>{{data.annualTotalCost}}</p>
                </td>
                <td>
                    TASAS DE INTERÉS FIJA
                </td>
                <td>
                    FECHA DE CORTE:
                </td>
                <td>
                    FECHA DE PAGO:
                </td>
                <td>
                    PERIODICIDAD DE PAGO:
                </td>
            </tr>
            <tr>
                <td class="weight">Sin IVA para fines informativos y de comparación.</td>
                <td class="weight">
                    <p>Tasa Ordinaria: {{data.annualInterestRate}} anual fija.</p>
                    <p>Tasa Moratoria: 2 veces la Tasa Ordinaria</p>
                </td>
                <td>{{data.borrowingDate}}</td>
                <td>{{data.repayDate}}</td>
                <td>{{data.paymentFrequency}}</td>
            </tr>
            <tr class="weight">
                <td>MONTO NETO OTORGADO</td>
                <td colspan="2">MONTO DEL CRÉDITO</td>
                <td colspan="2">MONTO TOTAL A PAGAR</td>
            </tr>
            <tr class="weight">
                <td>${{data.loan}} ( * PESOS 00/100 MN)</td>
                <td colspan="2">${{data.loanInterest}} ( * PESOS 00/100 MN)</td>
                <td colspan="2">${{data.shouldAmount}} ( * PESOS 00/100 MN)</td>
            </tr>
            <tr>
                <td colspan="5" class="sun_title weight">COMISIONES</td>
            </tr>
            <tr class="weight">
                <td>COMISIÓN</td>
                <td colspan="2">CANTIDAD</td>
                <td colspan="2">PERIODICIDAD</td>
            </tr>
            <tr>
                <td>Comisión</td>
                <td colspan="2">
                    <span>{{data.serviceFee ? '$' : ''}}</span>
                    {{data.serviceFee}} (CIENTO SESENTA PESOS, 00/100 MN)
                </td>
                <td colspan="2">{{data.serviceTimes}}</td>
            </tr>
            <tr>
                <td colspan="5" style="text-align: left;">
                    <p class="weight">ADVERTENCIAS:</p>
                    <p>• Incumplir tus obligaciones de pago, te puede generar comisiones e intereses moratorios.</p>
                    <p>• Contratar créditos que excedan tu capacidad de pago, afecta tu historial crediticio.</p>
                </td>
            </tr>
            <tr class="weight">
                <td colspan="5" style="text-align: left;">Seguros: No aplica</td>
            </tr>
            <tr class="weight tr_one">
                <td colspan="5" style="text-align: left;">
                    <p>ESTADO DE CUENTA/CONSULTA DE MOVIMIENTOS:</p>
                    <div>
                        <p>Envío a domicilio: NO</p>
                        <p>Envío correo electrónico: SI</p>
                    </div>
                </td>
            </tr>
            <tr class="weight tr_two">
                <td colspan="3">Nombre completo del ACREDITADO:<br />{{data.borrowerName}}</td>
                <td colspan="2">Número telefónico del ACREDITADO:<br />{{data.borrowerMobile}}</td>
            </tr>
            <tr class="weight tr_three">
                <td rowspan="2" colspan="3">Domicilio del ACREDITADO:<br />{{data.borrowerAddress}}</td>
                <td colspan="2">Correo electrónico del ACREDITADO:<br />{{data.borrowerEmail}}</td>
            </tr>
            <tr class="weight tr_three">
                <td colspan="2">Firma del ACREDITADO:<br />{{data.borrowerSignature}}</td>
            </tr>
        </table>
        <div class="container">
            <p class="p_title">CONTRATO DE CRÉDITO SIMPLE</p>
            <p>QUE CELEBRAN, POR UNA PARTE MOBENE DIGITAL TECH SERVICES S.A. DE C.V., REPRESENTADA POR SU APODERADO A
                QUIEN
                EN LO SUCESIVO SE LE DENOMINARÁ COMO EL <span class="weight">“<span
                        class="weight">ACREDITANTE</span>”</span>, Y POR LA OTRA, BAJO
                EL CARÁCTER DE <span class="weight">“ACREDITADO”</span>,
                LA
                PERSONA CUYO NOMBRE APARECE EN LA APLICACIÓN SEGÚN DICHO TÉRMINO SE DEFINE MÁS ADELANTE Y EN LA CARÁTULA
                DEL
                PRESENTE CONTRATO, DE CONFORMIDAD CON LOS ANTECEDENTES, DECLARACIONES Y CLÁUSULAS SIGUIENTES:</p>
            <p class="p_title">A N T E C E D E N T E S</p>
            <p><span class="weight">PRIMERO.</span> EL <span class="weight">ACREDITADO</span> requirió al <span
                    class="weight">ACREDITANTE</span>,
                el otorgamiento de un crédito
                simple en moneda nacional
                (en
                lo sucesivo el <span class="weight">“CRÉDITO”</span>).
            </p>
            <p><span class="weight">SEGUNDO</span>. El <span class="weight"><span
                    class="weight">ACREDITANTE</span></span>, previa revisión y
                análisis de la información y
                documentación que fuera
                proporcionada
                y presentada por el <span class="weight">ACREDITADO</span> por medio del
                aplicación, página web o página
                de internet, así como
                cualquier
                subdominio o página referenciada propiedad del <span class="weight">ACREDITANTE</span> cuyo nombre de
                dominio es
                https://www.rapidayuda.com/, (la <span class="weight">“APLICACIÓN”</span>), autorizó la Solicitud de
                crédito a favor del <span class="weight">ACREDITADO</span>.
            </p>
            <p class="p_title">D E C L A R A C I O N E S</p>
            <p><span class="weight">PRIMERA</span>. Declara el <span class="weight"><span
                    class="weight">ACREDITANTE</span></span> por conducto de
                su apoderado bajo protesta de
                decir verdad que:</p>
            <p>a.Es una Sociedad Anónima, legalmente constituida en términos de las leyes de los Estados Unidos
                Mexicanos
                (en lo sucesivo <span class="weight">“MÉXICO”</span>).</p>
            <p>b.En su carácter de apoderado se encuentra debidamente facultado para celebrar el presente contrato en
                nombre
                y representación del <span class="weight">ACREDITANTE</span> y que dichas
                facultades no le han sido
                revocadas, restringidas a esta
                fecha. </p>
            <p>c.Está interesado en otorgarle al <span class="weight">ACREDITADO</span> un <span
                    class="weight">“CRÉDITO”</span> sujeto a los términos
                y condiciones que aquí se
                señalan</p>
            <p>d.Señala como su domicilio, para los efectos relacionados en el Contrato, el bien inmueble ubicado en
                calle
                Versalles 15, interior 202, 203 y 204, colonia Juárez, Alcaldía Cuauhtémoc, Ciudad de México, México.
            </p>
            <p>e.La disposición de recursos provenientes del <span class="weight">“CRÉDITO”</span> otorgado en favor del
                <span class="weight">ACREDITADO</span> se llevará a cabo
                conforme a la disponibilidad de los recursos de la Tesorería del <span class="weight">ACREDITANTE</span>
                en la fecha de disposición
            </p>
            <p><span class="weight">SEGUNDA.</span> Declara el <span class="weight">ACREDITADO</span> bajo protesta de
                decir verdad:</p>
            <p>a.Es una persona física, con capacidad jurídica suficiente, que interviene por su propio derecho en la
                celebración del Contrato, obligándose bajo los términos que se establecen en el mismo.</p>
            <p>b.Señala como su domicilio, para los efectos relacionados con el Contrato, el bien inmueble indicado en
                la
                Carátula.</p>
            <p>c.El <span class="weight">ACREDITANTE</span>, con anterioridad a la fecha de celebración del Contrato, le
                proporcionó la información
                del crédito al <span class="weight">ACREDITADO</span>.
            </p>
            <p>d.Es su intención que el <span class="weight">ACREDITANTE</span> le otorgue el
                <span class="weight">CRÉDITO</span>, conforme a las
                estipulaciones del Contrato.
            </p>
            <p>e.La información y documentación que proporcionó y presentó al <span class="weight">ACREDITANTE</span>,
                así como la información que
                asentó en la Aplicación, es verdadera y correcta.</p>
            <p>f.Detenta los recursos económicos para el cumplimiento de las obligaciones que, en términos del Contrato,
                contrae a su cargo, mismos que provienen y provendrán de fuentes lícitas.</p>
            <p>g.Para efectos de la Ley Federal para la Prevención e Identificación de Operaciones con Recursos de
                Procedencia Ilícita, y marco regulatorio relacionado (la <span class="weight">“LEY ANTILAVADO”</span>),
                ratifica que:</p>
            <div>
                <p>1.Toda la documentación e información exhibida al <span class="weight">ACREDITANTE</span> para
                    efectos de su identificación, es
                    verdadera, correcta y precisa, ratificando que es de su conocimiento que la misma es el motivo
                    determinante de la voluntad del <span class="weight">ACREDITANTE</span> para la celebración del
                    Contrato.</p>
                <p>2.Ratifica conocer los lineamientos de identificación, criterios, medidas y procedimientos del
                    <span class="weight">ACREDITANTE</span> los cuales entiende y acepta.
                </p>
                <p>3.No existe ni existirá Dueño Beneficiario o Beneficiario Controlador alguno, distinto a su persona,
                    en
                    cualquier operación relacionada con el Contrato.</p>
                <p>4.Es el único propietario real y verdadero dueño de los beneficios, derechos y obligaciones derivados
                    del
                    Contrato, ejerciendo en última instancia los derechos de uso, disfrute, aprovechamiento o
                    disposición de
                    recursos a los que tendrá acceso mediante la celebración del Contrato.</p>
                <p>5.No desempeña actualmente ni ha desempeñado en los últimos dos años anteriores a la celebración del
                    Contrato, actividades que lo categoricen como Persona Políticamente Expuesta, ni tiene relación de
                    parentesco por consanguinidad o afinidad hasta en segundo grado con alguna Persona Políticamente
                    Expuesta. En caso contrario, deberá de remitir la información correspondiente al
                    <span class="weight">ACREDITANTE</span>, en cuyo caso, su <span class="weight">CRÉDITO</span>
                    estará sujeto a revisión y autorización por el departamento de cumplimiento del <span
                            class="weight">ACREDITANTE</span>.
                </p>
                <p>6.Cuenta con los recursos económicos suficientes y necesarios para hacer frente a las obligaciones a
                    su
                    cargo establecidas en el Contrato, recursos que han sido y serán obtenidos exclusivamente de fuentes
                    lícitas.</p>
                <p>7.Los recursos que obtendrá en virtud del Contrato tendrán un fin lícito.</p>
            </div>
            <p>h.Autoriza al <span class="weight">ACREDITANTE</span> para realizar cualquier proceso ordinario u
                alternativo de evaluación
                crediticia,
                incluyendo los que se realizan a través de sociedades de información crediticia autorizadas para operar
                como
                tal de conformidad con la Ley para Regular las Sociedades de Información Crediticia.</p>
            <p>i.Recibió a su entera satisfacción, comprendiendo el alcance de los términos y condiciones del <span
                    class="weight">APLICACIÓN</span>
                (i)
                la cantidad total a pagar; (ii) los intereses y comisiones que se generarán; (iii) la totalidad de las
                obligaciones a su cargo en términos del Contrato; y (iv) el aviso de privacidad del <span
                        class="weight">ACREDITANTE</span>.</p>
            <p><span class="weight">TERCERA.</span> Exponen el <span class="weight">ACREDITANTE</span> y el <span
                    class="weight">ACREDITADO</span>,
                que:</p>
            <p>a.Se reconocen la capacidad jurídica, personalidad, así como la representación con las que,
                respectivamente,
                comparecen para celebrar el Contrato.</p>
            <p>b.Para el supuesto de intervenir de manera conjunta en diversa estipulación del Contrato, se les
                denominará
                como las <span class="weight">“PARTES”.</span></p>
            <p>c.Toda la información y documentación que el <span class="weight">ACREDITADO</span> proporcionó y
                presentó al <span class="weight">ACREDITANTE</span>, son
                integrantes, para los efectos conducentes, del Contrato.</p>
            <p>d.Las <span class="weight">PARTES</span> reconocen las comisiones que deban pagar al <span
                    class="weight">ACREDITANTE</span> como
                parte integrante del presente
                Contrato y por ende se obligan a pagar las mismas y/o autorizan al <span
                        class="weight">ACREDITANTE</span> a que retenga y/o
                compense
                dichas comisiones de los pagos realizados en el momento que sea conducente. Asimismo, el <span
                        class="weight">ACREDITANTE</span> ha
                informado al <span class="weight">ACREDITADO</span> del Costo Anual Total (CAT) de financiamiento
                expresado en términos porcentuales
                anuales que, para fines informativos de comparación, incorpora la totalidad de los costos y gastos
                inherentes al <span class="weight">CRÉDITO</span>.</p>
            <p>e.Las <span class="weight">PARTES</span> reconocen el alcance y efectos del Contrato, obligándose en
                todos y cada uno de los términos
                asentados en el mismo y, por lo tanto, el <span class="weight">ACREDITADO</span> reconoce que al aceptar
                electrónicamente este
                Contrato
                mediante el clic en el recuadro de aceptación, o a través de cualquier otro medio establecido por el
                <span class="weight">ACREDITANTE</span>, otorgará su conformidad a través de su firma electrónica y sus
                efectos. Se entiende como
                lugar
                y fecha, el de la firma del Contrato.
            </p>
            <p>En virtud de los ANTECEDENTES Y DECLARACIONES anteriores, las <span class="weight">PARTES</span> otorgan
                y se sujetan a las
                siguientes:
            </p>
            <p class="p_title">C L Á U S U L A S
                CAPÍTULO PRIMERO
                DEFINICIONES</p>
            <p class="weight">PRIMERA. DEFINICIONES</p>
            <p>Para efectos del Contrato, las <span class="weight">PARTES</span> asignan a los términos señalados a
                continuación, el significado que
                en
                cada caso se les atribuye, escritos con letra mayúscula inicial, a menos que el contexto en que se
                utilicen,
                implique un concepto diferente y así se indique, independientemente de su utilización en singular o en
                plural, mismos que serán aplicados en el Contrato.</p>
            <p><span class="weight">“Aplicación”</span>. Página web o página de internet, así como cualquier subdominio
                o página referenciada
                propiedad
                del <span class="weight">ACREDITANTE</span>, cuyo nombre de dominio es www.rapidayuda.com.</p>
            <p><span class="weight">“Carátula”</span>. Significa el documento integrante del Contrato, en el que se
                establecen, la información del Crédito, así como la información que determina el Contrato y que les es
                aplicable.
            </p>
            <p><span class="weight">“Costo Anual Total”</span>. En la Carátula del presente Contrato se hace del
                conocimiento del <span class="weight">ACREDITADO</span> el
                Costo
                Anual Total de financiamiento expresado en términos porcentuales anuales que, para fines informativos y
                de
                comparación, incorpora la totalidad de los costos y gastos inherentes a los créditos.</p>
            <p><span class="weight">“Desembolso”</span>. La acción de entregar el monto del Contrato motivo de este
                Contrato mediante transferencia
                electrónica al <span class="weight">ACREDITADO</span> a una cuenta bancaria a su nombre.</p>
            <p><span class="weight">“Día Hábil”</span>. Significa cualquier día de la semana, excluyendo los días sábado
                y domingo, así como los
                días en
                que, conforme a las disposiciones aplicables, las instituciones de c</p>
            <p><span class="weight">“Fecha Límite de Pago”</span>. Significa el Día Hábil en que el <span
                    class="weight">ACREDITADO</span> deberá
                pagar al <span class="weight">ACREDITANTE</span> el Monto
                Total a Pagar. En caso de que alguna Fecha de Pago no sea Día Hábil, el pago del Monto Total a Pagar,
                deberá
                efectuarse el Día Hábil inmediato siguiente. De igual forma y como se establece en la Carátula.</p>
            <p>“Firma”. Firma electrónica al calce de este Contrato que otorga la misma validez que la firma autógrafa
                del
                <span class="weight">ACREDITADO</span>, la cual se manifestará mediante el clic en el recuadro de
                aceptación.
            </p>
            <p><span class="weight">“IVA de la Tasa de Interés Ordinario del Crédito"</span>. Significa el Impuesto al
                Valor Agregado que, conforme
                a
                las disposiciones legales aplicables es causado sobre el importe de la Tasa de Interés Ordinario del
                <span class="weight">“CRÉDITO”</span>. El importe del IVA de la Tasa de Interés Ordinario del <span
                        class="weight">“CRÉDITO”</span> será pagado por parte del
                <span class="weight">ACREDITADO</span>.
            </p>
            <p>“IVA de la Tasa de Interés Moratorio del Crédito”. Significa el Impuesto al
                Valor Agregado que, conforme
                a
                las disposiciones legales aplicables es causado sobre el importe de la Tasa de Interés Moratorio del
                Crédito. El importe del IVA de la Tasa de Interés Moratorio del Crédito será pagado por parte del
                <span class="weight">ACREDITADO</span>.
            </p>
            <p><span class="weight">“Monto del Crédito”</span>. Significa el importe indicado en la Carátula del monto
                aprobado y fondeado por el
                <span class="weight">ACREDITANTE</span>. Sobre este monto se calcula la comisión de apertura indicada en
                la carátula.
            </p>
            <p><span class="weight">“Monto Neto Otorgado”</span>. Significa el importe indicado en la Carátula que,
                respecto del Crédito y, conforme
                al
                Contrato, será otorgado al <span class="weight">ACREDITADO</span> por el <span
                        class="weight">ACREDITANTE</span>, mismo que es el
                resultado de restarle al Monto
                del Crédito más comisiones e IVA.
            </p>
            <p><span class="weight">“Monto Total a Pagar”</span>. Significa la adición del importe de la Tasa de Interés
                Ordinario del Crédito, así como del importe del IVA de la Tasa de Interés Ordinario del <span
                        class="weight">“CRÉDITO”</span>, al Monto
                del Crédito. El Importe Total del Crédito será el importe que el <span class="weight">ACREDITADO</span>
                debe pagar al <span class="weight">ACREDITANTE</span>. El
                importe Total del <span class="weight">“CRÉDITO”</span> se indica en la Carátula.
            </p>
            <p><span class="weight">"Tasa de Interés Moratorio"</span>. Significa el porcentaje fijo, que será
                multiplicado sobre cualquier importe
                que
                el <span class="weight">ACREDITADO</span> no pague al <span class="weight">ACREDITANTE</span> en forma
                oportuna y/o completa
                conforme al Contrato y, cuyo
                importe
                resultante, deberá pagarse por el <span class="weight">ACREDITADO</span> al <span
                        class="weight">ACREDITANTE</span>, en forma
                inmediata, en Día Hábil y, según
                sea
                el caso, junto con el importe que complete el importe no pagado en forma completa. La Tasa de Interés
                Moratorio será aplicable en tanto no sea pagado el importe de que se trate. La explicación del cálculo
                del
                presente interés se incluye en la cláusula décima cuarta.</p>
            <p> <span class="weight">"Tasa de Interés Ordinario del Crédito"</span>. Significa el porcentaje fijo, que
                será multiplicado sobre el
                Monto
                del <span class="weight">“CRÉDITO”</span> y, cuyo importe resultante, deberá pagarse por el <span
                        class="weight">ACREDITADO</span> al
                <span class="weight">ACREDITANTE</span>, con motivo del
                fondeo del Monto del Crédito. El pago de la Tasa de Interés Ordinario del
                Crédito no podrá ser exigido
                por
                adelantado, sino únicamente por periodos vencidos. La explicación del cálculo del presente interés se
                incluye en la cláusula décima tercera siguiente. Lo anterior en el claro entendido de que, si el
                <span class="weight">ACREDITADO</span>
                tiene a su disposición cualquier cantidad no amortizada a partir del día uno del periodo, pagará todos
                los
                intereses correspondientes a dicho periodo, pudiendo ser cobrados estos intereses en cualquier día del
                periodo.
            </p>
            <p class="p_title">
                CAPÍTULO SEGUNDO
                TÉRMINOS Y CONDICIONES</p>
            <p class="weight">SEGUNDA: CONSENTIMIENTO EXPRESO / ACEPTACIÓN.</p>
            <p>El <span class="weight">ACREDITADO</span> reconoce que al dar clic en el recuadro de aceptación, otorga
                su conformidad a través de su
                firma electrónica, perfeccionándose el presente Contrato, de conformidad con lo establecido en el
                Artículo
                80 del Código de Comercio y en términos de lo dispuesto por el artículo 1803 del Código Civil Federal.
                En
                caso, de no aceptar en forma absoluta y completa los términos y condiciones de las Actividades, el
                <span class="weight">ACREDITADO</span> deberá abstenerse de dar clic en el recuadro de aceptación.
            </p>
            <p>El <span class="weight">ACREDITADO</span> está enterado de la obligación que tiene de descargar el
                presente contrato y guardarlo para
                ulteriores consultas y de esta forma comprobar que la información se encuentra integra e inalterada a
                partir
                de que se generó por primera vez. Asimismo, podrá entrar a su cuenta a través del APLICACIÓN y
                consultarlo
                cuando sea necesario.</p>
            <p class="weight"> TERCERA: OTORGAMIENTO DE CRÉDITO”.</p>
            <p>
                Como consecuencia del Contrato y, en la fecha de su celebración, el <span
                    class="weight">ACREDITANTE</span> otorga el <span class="weight">“CRÉDITO”</span> al
                <span class="weight">ACREDITADO</span>, cuyo importe, corresponderá al Monto del Crédito.
            </p>
            <p>Dentro del Monto del Crédito, no se encuentra comprendida la Tasa de
                Interés Ordinario del Crédito, así
                como
                el IVA de la Tasa de Interés Ordinario del Crédito y, cuyos importes,
                serán pagados por el <span class="weight">ACREDITADO</span> al
                <span class="weight">ACREDITANTE</span>, conforme a lo establecido en el presente Contrato y la Tabla de
                Amortización.
            </p>
            <p>CUARTA: DISPOSICIÓN DEL MONTO NETO OTORGADO.</p>
            <p>Las <span class="weight">PARTES</span> determinan que, respecto de la disposición del Monto Neto
                Otorgado, el <span class="weight">ACREDITADO</span>, a través
                del
                Contrato instruye, autoriza y faculta al <span class="weight">ACREDITANTE</span>, en forma expresa e
                irrevocable, para que el Monto
                Neto
                Otorgado sea entregado, a favor del <span class="weight">ACREDITADO</span> y, conforme a lo indicado en
                el presente Contrato
                mediante
                transferencia electrónica a la cuenta que ha sido señalada por el <span class="weight">ACREDITADO</span>
                y de la que es titular (el
                <span class="weight">“DESEMBOLSO”</span>). Por lo tanto, las <span class="weight">PARTES</span>
                determinan que la recepción de la
                transferencia en la cuenta
                indicada en la Carátula, se refutará como: (i) la disposición que el <span
                        class="weight">ACREDITADO</span> realice sobre el Monto
                del
                <span class="weight">“CRÉDITO”</span> y, consecuentemente, de su recepción por el <span
                        class="weight">ACREDITADO</span>; (ii) la
                disposición se refutará como la
                entrega, a cargo del <span class="weight">ACREDITANTE</span> y a favor del <span
                        class="weight">ACREDITADO</span>, del Monto del
                Crédito; y (iii) la disposición
                se
                considerará como cumplimiento del <span class="weight">ACREDITANTE</span>, en lo conducente, a las
                obligaciones que, a su cargo y a
                favor del <span class="weight">ACREDITADO</span>, se derivan del Contrato. Como consecuencia de la
                disposición que, respecto del
                Monto
                del <span class="weight">“CRÉDITO”</span>, realice o realiza el <span class="weight">ACREDITADO</span>,
                estará obligado a pagar al
                <span class="weight">ACREDITANTE</span>, el Monto Total a
                Pagar.
            </p>
            <p>El <span class="weight">ACREDITADO</span>, en este acto, suscribe a su cargo y a favor del <span
                    class="weight">ACREDITANTE</span>,
                un pagaré cuyo importe
                asciende
                al Monto Total a Pagar y, el que, a su vez, documenta la disposición que realice o realiza el <span
                        class="weight">ACREDITADO</span>
                sobre el Monto del Crédito (en lo sucesivo el “<span class="weight">PAGARÉ</span>”). El <span
                        class="weight">ACREDITANTE</span>, por
                conducto del Contrato,
                acusa la
                recepción del Pagaré, a su entera satisfacción.</p>
            <p class="weight">QUINTA: TIPO DE MONEDA.</p>
            <p>Todas las operaciones realizadas por y a través del <span class="weight">ACREDITANTE</span> deberán ser
                hechas en la moneda de curso
                legal en México.</p>
            <p class="p_title">CAPÍTULO TERCERO
                SUJETOS DEL CONTRATO</p>
            <p>SEXTA: DATOS DEL <span class="weight">ACREDITANTE</span>.</p>
            <p>Las personas físicas cuyos ID de usuarios registrados en el APLICACIÓN, son los indicados en la Carátula.
            </p>
            <p>El <span class="weight">ACREDITANTE</span>, manifiesta que sabe y acepta sin limitación alguna que MOBENE
                DIGITAL TECH SERVICES S.A.
                DE
                C.V. pueda consultar y almacenar sus contactos para completar automáticamente los datos durante el
                proceso
                de solicitud, manifestando que todos y cada uno de los contactos localizados en el teléfono móvil son
                señalados por el <span class="weight">ACREDITANTE</span> como sus referencias personales, por lo que
                autoriza que en caso de
                incumplimiento se pueda solicitar a las referencias crediticias que ha señalado, su colaboración para el
                pago del <span class="weight">CRÉDITO</span>. En este sentido, el <span
                        class="weight">ACREDITANTE</span> acepta que El <span class="weight">ACREDITANTE</span> recopile y
                monitoree su
                información de contactos que incluye nombre, número de teléfono, tipo de cuenta, contacto modificado por
                última vez, favoritos y otros datos opcionales como relación y dirección estructural. </p>
            <p class="weight">SÉPTIMA: DATOS DELACREDITADO.</p>
            <p>El <span class="weight">ACREDITADO</span> ha proporcionado al <span class="weight">ACREDITANTE</span> su
                nombre y domicilio, el cual
                ha quedado establecido en la
                solicitud de Crédito y Carátula del presente Contrato, manifestando en
                este acto que la información
                proporcionada es cierta y verdadera.</p>
            <p class="p_title">CAPÍTULO CUARTO
                COMISIONES Y TASAS</p>
            <p class="weight">OCTAVA: COMISIÓN POR APERTURA.</p>
            <p>El <span class="weight">ACREDITADO</span> se obliga a pagar al <span class="weight">ACREDITANTE</span>
                una comisión única por la
                apertura del Crédito, cuyo monto
                se
                establece en la Aplicación.</p>
            <p class="weight">NOVENA: TASA DE INTERÉS ORDINARIO.</p>
            <p>Es el porcentaje fijo indicado en la Carátula, que será multiplicado sobre el Monto del Crédito y, cuyo
                importe resultante, deberá pagarse por el <span class="weight">ACREDITADO</span> al <span
                        class="weight">ACREDITANTE</span> a lo
                largo del <span class="weight">CRÉDITO</span>. El pago de
                la
                Tasa de Interés Ordinario del Crédito no podrá ser exigido por adelantado,
                sino únicamente por periodos
                vencidos. Lo anterior, en el claro entendido de que, si el <span class="weight">ACREDITADO</span> tiene
                a su disposición cualquier
                cantidad no amortizada a partir del día uno del periodo, pagará todos los intereses correspondientes a
                dicho
                periodo, pudiendo ser cobrados estos intereses en cualquier día del periodo.</p>
            <p class="weight">DÉCIMA: TASA DE INTERÉS MORATORIO.</p>
            <p>Es el porcentaje fijo mensual, que será multiplicado sobre cualquier importe que el <span
                    class="weight">ACREDITADO</span> no pague
                al
                <span class="weight">ACREDITANTE</span> en forma oportuna y/o completa conforme al Contrato y, cuyo
                importe resultante, deberá
                pagarse
                por el <span class="weight">ACREDITADO</span> al <span class="weight">ACREDITANTE</span>, en forma
                inmediata, en Día Hábil y, según
                sea el caso, junto con el
                importe que complete el importe no pagado en forma completa. La Tasa de Interés Moratorio será aplicable
                en
                tanto no sea pagado el importe de que se trate.
            </p>
            <p class="weight">DÉCIMA PRIMERA: DESTINO DEL CRÉDITO.</p>
            <p>Las <span class="weight">PARTES</span> establecen que el Monto del Crédito
                será destinado por el <span class="weight">ACREDITADO</span> para cubrir sus
                necesidades
                personales. El <span class="weight">ACREDITADO</span> manifiesta que el destino del Monto del Crédito es
                y será lícito.</p>
            <p class="p_title">CAPÍTULO QUINTO
                MODIFICACIONES Y TERMINACIÓN DEL CONTRATO</p>
            <p class="weight">DÉCIMA SEGUNDA: MODIFICACIONES AL CONTRATO.</p>
            <p>El <span class="weight">ACREDITANTE</span> se reserva el derecho de realizar modificaciones al presente
                Contrato mediante aviso
                dirigido
                al <span class="weight">ACREDITADO</span>, a través del APLICACIÓN, o mediante correo electrónico que
                para tal efecto el <span class="weight">ACREDITADO</span>
                tenga registrado en el <span class="weight">APLICACIÓN</span>, con por lo menos, 30 (treinta) días
                naturales de anticipación a la
                fecha
                en que las modificaciones entren en vigor. En caso, de que el <span class="weight">ACREDITADO</span> no
                manifieste nada al respecto,
                se
                entiende que acepta las modificaciones tácitamente.</p>
            <p>De darse el supuesto en el que el <span class="weight">ACREDITADO</span> no esté de acuerdo con las
                modificaciones propuestas por el
                presente Contrato, podrá solicitar su cancelación hasta 30 (treinta) días naturales posteriores a la
                fecha
                del aviso mencionado, sin responsabilidad de su parte, debiendo cumplir, en su caso, cualquier adeudo
                que se
                hubiese generado a la fecha de la solicitud de terminación por parte del <span
                        class="weight">ACREDITADO</span>, bajo las
                condiciones
                anteriores a las modificaciones propuestas.</p>
            <p class="weight">DÉCIMA TERCERA: TERMINACIÓN ANTICIPADA.</p>
            <p>El <span class="weight">ACREDITANTE</span> podrá dar por terminado anticipadamente el Contrato y, por lo
                tanto, el <span class="weight">ACREDITADO</span> deberá
                pagar inmediatamente (o de lo contrario comenzará a correr la tasa de Interés Moratorio sobre las
                cantidades
                a continuación descritas) a favor del <span class="weight">ACREDITANTE</span>:</p>
            <div>
                <p>I.El saldo insoluto del Monto Total a Pagar;</p>
                <p>II.Cualquier otro importe que el <span class="weight">ACREDITADO</span> adeude al <span
                        class="weight">ACREDITANTE</span>
                    conforme al Contrato; y,</p>
                <div class="p_div">III.Los daños y perjuicios que, en su caso, le sean ocasionados al <span
                        class="weight">ACREDITANTE</span>, en el
                    caso que:
                    <p>a.El <span class="weight">CRÉDITO</span> no sea pagado en los términos del Contrato;</p>
                    <p>b.La falta de pago por el <span class="weight">ACREDITADO</span>, en la Fecha Límites de Pago,
                        así como de cualquier otro
                        importe
                        que
                        el <span class="weight">ACREDITADO</span> adeude al <span class="weight">ACREDITANTE</span>
                        conforme al Contrato;</p>
                    <div class="p_sun_div">c.La determinación de que alguna:
                        <p>i.De las declaraciones que corresponden al <span class="weight">ACREDITADO</span>; o,</p>
                        <p>ii.Información o documentación entregada por el <span class="weight">ACREDITADO</span>,
                            resulte ser falsa;</p>
                        <p>iii.El incumplimiento del <span class="weight">ACREDITADO</span> a cualquier obligación que,
                            en términos del Contrato, se
                            encuentra
                            establecida a su cargo;
                        </p>
                    </div>
                    <p>d.El estado de insolvencia del <span class="weight">ACREDITADO</span>;</p>
                    <p>e.El Importe del <span class="weight">“CRÉDITO”</span> se destine a actividades ilícitas;</p>
                    <p>f.El inicio de cualquier procedimiento contencioso, incluyendo aquellos de naturaleza civil,
                        mercantil, laboral, penal y/o administrativa, en contra del <span
                                class="weight">ACREDITADO</span>; y</p>
                    <p>g.La existencia de razones suficientes para que el <span class="weight">ACREDITANTE</span>
                        considere que el <span class="weight">ACREDITADO</span> no
                        podrá
                        hacer frente a sus obligaciones de pago conforme a este Contrato.</p>
                </div>
            </div>
            <p class="weight">DÉCIMA CUARTA: TERMINACIÓN.</p>
            <p>El <span class="weight">ACREDITADO</span>, podrá dar por terminado el presente Contrato, siempre y cuando
                liquide los adeudos
                pendientes
                que tenga con el <span class="weight">ACREDITANTE</span>, en tanto no se liquide la totalidad de los
                adeudos, la terminación del
                Contrato no surtirá efectos.</p>
            <p>Para dar terminación al presente Contrato, el <span class="weight">ACREDITADO</span> deberá realizar la
                totalidad de sus adeudos, en
                cuyo
                momento, podrá visualizar en el APLICACIÓN que su crédito está pagado en
                su totalidad, dejando sin
                efectos
                el Contrato.</p>
            <p>Es responsabilidad del <span class="weight">ACREDITADO</span> validar que no tenga adeudos motivo del
                presente Contrato para poder
                cancelarlo.</p>
            <p>No hay comisión ni penalización por la terminación del Contrato, y el <span
                    class="weight">ACREDITANTE</span> se obliga a informar a
                la
                Sociedad de Información Crediticia lo conducente, siempre y cuando el <span
                        class="weight">ACREDITADO</span> no tenga adeudos o, en
                su
                caso, los liquide.</p>
            <p class="weight">DÉCIMA QUINTA: VIGENCIA.</p>
            <p>La vigencia del Contrato iniciará a partir de su fecha de celebración validada con su firma electrónica
                al
                calce del contrato y terminará en la Fecha Límite de Pago o, en el día en que sean cumplimentadas, en su
                totalidad, las obligaciones que, en términos del Contrato, contrae el <span
                        class="weight">ACREDITADO</span> a su cargo.</p>
            <p class="p_title">CAPÍTULO SEXTO
                PAGOS</p>
            <p class="weight">DÉCIMA SEXTA: MEDIOS DE PAGOS.</p>
            <p>El Monto Total a Pagar deberá pagarse al <span class="weight">ACREDITANTE</span> por el <span
                    class="weight">ACREDITADO</span>
                mediante transferencia electrónica
                a
                las cuentas de las instituciones señaladas en la Plataforma, o

                En caso, de que el <span class="weight">ACREDITADO</span> pague mediante depósito en la cuenta del
                <span class="weight">ACREDITANTE</span>:
            </p>
            <p>a.Estará obligado a comprobar, en cualquier momento, al <span class="weight">ACREDITANTE</span>, los
                pagos que sean depositados; y,
            </p>
            <p>b.Deberá conservar los cupones o, los recibos de depósito que correspondan o, que se emitan, debidamente
                sellados o, emitidos y sellados, por la respectiva institución de crédito,
                por los que se acrediten los
                pagos que realice al <span class="weight">ACREDITANTE</span>, toda vez que los mismos serán el único
                medio para demostrar al
                <span class="weight">ACREDITANTE</span>, los pagos que sean realizados por el <span
                        class="weight">ACREDITADO</span>. Asimismo: (i)
                los importes de la Tasa de
                Interés Moratorio; y (ii) cualquier otro importe que el <span class="weight">ACREDITADO</span> adeude al
                <span class="weight">ACREDITANTE</span> conforme al
                Contrato, deberán pagarse al <span class="weight">ACREDITANTE</span> por el <span
                        class="weight">ACREDITADO</span> de la misma forma
                antes enunciada.
            </p>
            <p class="weight">DÉCIMA SEPTIMA: FECHA LÍMITE DE PAGO.</p>
            <p>El Monto Total a Pagar será pagado a cargo del <span class="weight">ACREDITADO</span> y a favor del
                <span class="weight">ACREDITANTE</span>, a través de lo
                establecido en la cláusula anterior y en las respectiva Fecha Límite de Pago que se estableció
                previamente.
                El <span class="weight">ACREDITADO</span> podrá visualizar su Fecha Límite de Pago a través de:
            </p>
            <p>i.La <span class="weight">APLICACIÓN</span> ingresando a su cuenta y consultando dicha información;</p>
            <p>ii.En caso de que la Fecha Límite de Pago coincida con un día inhábil bancario, el <span
                    class="weight">ACREDITADO</span> podrá
                efectuar
                el pago sin cargo alguno, el día hábil bancario siguiente.</p>
            <p class="weight">DÉCIMA OCTAVA: APLICACIÓN DE PAGOS.</p>
            <p>Cualquier pago que, en términos del Contrato, sea realizado por el <span
                    class="weight">ACREDITADO</span>, será aplicado por el
                <span class="weight">ACREDITANTE</span>, para cubrir, en primer lugar y hasta donde alcance, cualquier
                comisión que pertenezca al
                <span class="weight">ACREDITANTE</span> y que se establece en la Carátula, el saldo insoluto del importe
                de la Tasa de Interés
                Moratorio
                de que se trate, en caso de que exista y, por último y hasta donde alcance, el saldo insoluto del Monto
                Total a Pagar de que se trate, iniciando por el saldo insoluto de cualquier interés ordinario y
                finalmente
                del principal.
            </p>
            <p>En caso de que el <span class="weight">ACREDITADO</span> realice su pago sin notificarlo al <span
                    class="weight">ACREDITANTE</span>,
                y que dicho pago requiera de
                notificación para poder conciliarlo de acuerdo con las cuentas disponibles en la Plataforma y según este
                contrato, el pago será efectivamente aplicado hasta enviar la notificación y comprobante
                correspondiente,
                generando intereses moratorios (si los hubiere) hasta la fecha de aplicación.</p>
            <p class="weight">DÉCIMA NOVENA: PAGO ANTICIPADO.</p>
            <p>Siempre que el <span class="weight">ACREDITADO</span> se encuentre al corriente en el cumplimiento de las
                obligaciones de pago que
                establece el Contrato y sus anexos, podrá pagar anticipadamente al <span
                        class="weight">ACREDITANTE</span>, la totalidad del Monto del Crédito que al momento se encuentre
                insoluto siempre y cuando realice el depósito de la liquidación cuando
                menos 5 (cinco) días antes de su Fecha de Pago, y realice el depósito vía transferencia electrónica
                únicamente por el monto exacto que se muestra en la APLICACIÓN a la cuenta designada para ello a través
                de
                la <span class="weight">APLICACIÓN</span>.
            </p>
            <p class="p_title">CAPÍTULO SÉPTIMO
                ATENCIÓN AL CLIENTE</p>
            <p class="weight">VIGÉSIMA: CONTRASEÑAS Y NOMBRES DE USUARIO.</p>
            <p>La contraseña, se considera personal e intransferible. Debido a lo anterior el <span
                    class="weight">ACREDITADO</span> deberá observar
                lo
                siguiente:</p>
            <p>i.Ingresar a la <span class="weight">APLICACIÓN</span> con su usuario.</p>
            <p>ii.La contraseña con la que ingrese es personal e intransferible, es decir, no debe compartirse con
                terceras
                personas ni difundirse, deslindando al <span class="weight">ACREDITANTE</span> de cualquier mal uso que
                el <span class="weight">ACREDITADO</span> dé a su
                contraseña.</p>
            <p>iii.En caso, de que el <span class="weight">ACREDITADO</span> olvide su contraseña o le sea robada,
                deberá solicitar una nueva a
                través
                del procedimiento que el <span class="weight">ACREDITANTE</span> ha establecido en el <span
                        class="weight">APLICACIÓN</span> para
                tales efectos.</p>
            <p>iv.El <span class="weight">ACREDITANTE</span> recomienda cambiar la contraseña cada 3 meses, y se reserva
                el derecho a exigirle al
                <span class="weight">ACREDITANTE</span> que así lo haga.
            </p>
            <p>v.El <span class="weight">ACREDITANTE</span> no será responsable por el mal uso que el <span
                    class="weight">ACREDITADO</span> le
                otorgue a la contraseña.</p>
            <p class="weight">VIGÉSIMA PRIMERA: CONSULTA DE SALDO.</p>
            <p>El <span class="weight">ACREDITADO</span>, podrá consultar a través de su cuenta en la <span
                    class="weight">APLICACIÓN</span> del
                <span class="weight">ACREDITANTE</span>, su saldo, en el
                momento en que desee.
            </p>
            <p class="p_title">CAPÍTULO OCTAVO
                DISPOSICIONES GENERALES</p>
            <p class="weight">VIGÉSIMA SEGUNDA: NOTIFICACIONES Y AVISOS.</p>
            <p><span class="weight">Para</span> efectos de todas las notificaciones, avisos y en general cualquier
                comunicación que las <span class="weight">PARTES</span>
                deban
                hacerse con motivo del presente Contrato, éstas se realizarán por medios electrónicos, teléfonos
                fijos
                y/o
                móvil que las PARTES señalan en la <span class="weight">APLICACIÓN</span>. Asimismo, en caso de
                cualquier contingencia operativa
                el
                <span class="weight">ACREDITANTE</span> le informará por los medios antes mencionados al <span
                        class="weight">ACREDITADO</span>,
                cómo se actuará, ante el caso
                en
                concreto.
            </p>
            <p class="weight">VIGÉSIMA TERCERA: AUTORIZACIÓN PARA INTERCAMBIAR INFORMACIÓN.</p>
            <p>El <span class="weight">ACREDITADO</span> está de acuerdo en que, de llevarse a cabo intercambios de
                información con fines
                mercadotécnicos o publicitarios, el consentimiento para la utilización de su información deberá ser
                expresado mediante una constancia emitida por el <span class="weight">ACREDITANTE</span> en el
                momento
                en que se lleve a cabo dicho
                intercambio.</p>
            <p>Por otro lado, el <span class="weight">ACREDITADO</span> en este acto autoriza expresamente al
                <span class="weight">ACREDITANTE</span>, para que lleve a cabo
                investigaciones sobre su comportamiento crediticio en las sociedades de información crediticia (SIC)
                que
                estime convenientes el <span class="weight">ACREDITANTE</span>.
            </p>
            <p class="weight">VIGÉSIMA CUARTA: LEY APLICABLE Y JURISDICCIÓN.</p>
            <p>Para todo lo relativo a la interpretación y cumplimiento de este Contrato, las <span
                    class="weight">PARTES</span> se someten a
                las
                leyes
                de los Estados Unidos Mexicanos y a la jurisdicción de los tribunales competentes de la Ciudad de
                México,
                renunciando el <span class="weight">ACREDITADO</span>, a cualquier fuero que por razón de su
                domicilio
                presente o futuro pudiera
                presentar.</p>
            <p class="weight">VIGÉSIMA QUINTA: OBLIGACIONES FISCALES Y LICITUD DE LOS RECURSOS.</p>
            <p>El <span class="weight">ACREDITADO</span> declara y garantiza que cumplirá con todas las obligaciones
                fiscales que le resulten
                aplicables
                por virtud de las actividades y que los recursos económicos utilizados en relación con las
                actividades
                provienen y provendrán de fuentes lícitas y serán utilizados para actividades lícitas en todo
                momento, a
                sabiendas de que en caso contrario el <span class="weight">ACREDITANTE</span> procederá a realizar
                todas
                las acciones a que haya
                lugar,
                quedando además obligado el <span class="weight">ACREDITADO</span>, a indemnizar y sacar en paz y a
                salvo al <span class="weight">ACREDITANTE</span> de
                cualquier
                daño, perjuicio, demanda y/o acción que dicho incumplimiento le provoque.</p>
            <p class="weight">VIGÉSIMA SEXTA: DOMICILIOS.</p>
            <p>Las <span class="weight">PARTES</span> señalan como sus domicilios para los efectos de notificaciones
                de carácter extrajudicial o
                judicial que se relacionen con el Contrato, los que refiere, respectivamente, el capítulo de
                DECLARACIONES.
                Sin embargo, las <span class="weight">PARTES</span> podrán cambiar o señalar en el futuro cualquier
                otro domicilio, mediante
                notificación realizada a la otra parte con por lo menos 5 (cinco) Días Hábiles de anticipación, en
                el
                entendido que, de no proporcionarse tal aviso de cambio, todas y cada una de las notificaciones que
                se
                le
                hicieren a la parte de que se trate, en el domicilio que ahora señala, se considerarán legalmente
                realizadas
                en su perjuicio. Las notificaciones extrajudiciales que tengan que realizar las <span
                        class="weight">PARTES</span>, serán por
                escrito,
                dirigidas a la contraparte, mediante entrega personal con acuse de recibo en el respectivo domicilio
                y
                serán
                entregadas en la fecha en que sean recibidas por la parte a quien hayan sido dirigidas.</p>
            <p>Sin perjuicio de lo establecido en los párrafos anteriores de la presente cláusula, las
                notificaciones
                extrajudiciales que sean relativas al requerimiento del o de los pagos del <span
                        class="weight">CRÉDITO</span> al <span class="weight">ACREDITADO</span>, podrán
                realizarse por el <span class="weight">ACREDITANTE</span>, en cualquier domicilio del <span
                        class="weight">ACREDITADO</span> que sea
                del conocimiento del
                <span class="weight">ACREDITANTE</span>, por escrito y, entregadas en forma personal, sin acuse de
                recibo.
            </p>
            <p class="weight">VIGÉSIMA SÉPTIMA: DATOS PERSONALES.</p>
            <p>Las <span class="weight">PARTES</span> acuerdan que el <span class="weight">ACREDITANTE</span>
                fungirá como Responsable de los
                Datos
                Personales que de los
                particulares se recaben y de su Tratamiento en términos del Aviso de Privacidad del <span
                        class="weight">ACREDITANTE</span>
                disponible
                en la <span class="weight">APLICACIÓN</span>. Para efectos de lo pactado en esta cláusula, se
                entenderá por los términos “Datos
                Personales”, “Responsable” y “Tratamiento” la definición que para dichos términos establece la Ley
                Federal
                de Protección de Datos Personales en Posesión de los Particulares.</p>
            <p class="weight">VIGÉSIMA OCTAVA: AUTORIZACIONES ADICIONALES</p>
            <p>Las <span class="weight">PARTES</span> acuerdan que el <span class="weight">ACREDITANTE</span> y/o la
                persona que designe, podrá
                realizar llamadas a cualquier
                teléfono y/o visitas a cualquier domicilio del <span class="weight">ACREDITADO</span>, además de en
                días
                hábiles, en días que sean
                declarados como inhábiles en horarios de 7:00 a 22:00 horas, para el efecto de requerirle el o los
                pagos
                del Crédito. De igual forma se hace constar que tanto el <span class="weight">ACREDITANTE</span>
                como
                cualquier tercero designado por
                él,
                podrá, a nombre y por cuenta del <span class="weight">ACREDITANTE</span>, iniciar cualquier tipo de
                procedimiento contencioso en
                contra
                del <span class="weight">ACREDITADO</span>, en caso de incumplimiento a cualquiera de los términos y
                condiciones del presente
                Contrato.
            </p>
            <p class="weight">VIGÉSIMA NOVENA: CESIÓN DE DERECHOS</p>
            <p>El <span class="weight">ACREDITADO</span> no podrá ceder, transmitir gravar o comercializar de manera
                alguna en todo o en partes los
                derechos y obligaciones que se le deriven del Contrato, sin la previa autorización por escrito del
                <span class="weight">ACREDITADO</span>.
            </p>
            <p>El <span class="weight">ACREDITANTE</span> podrá ceder o trasmitir de cualquier forma, en todo o en
                parte, los derechos y
                obligaciones
                que se le deriven del Contrato, sin necesidad de notificar al <span class="weight">ACREDITADO</span>, ya
                que este manifiesta en esta
                su
                conformidad con las cesiones que realice el <span class="weight">ACREDITANTE</span>.</p>
            <p class="weight">TRIGÉSIMA: CONFIDENCIALIDAD</p>
            <p>
                El <span class="weight">ACREDITADO</span> (o la “Parte Receptora”) acuerda que toda la información
                que
                haya recibido o reciba del
                Acreditante (o la “Parte Propietaria”) con motivo de la negociación
                previa,
                celebración y ejecución del
                presente Contrato y cuya divulgación o exposición, cualquiera que sea su forma, perjudique de forma
                alguna a
                la Parte Propietaria se considerará como información confidencial (la “Información Confidencial”) y
                será
                tratada en términos de la legislación aplicable.
            </p>
            <p>Las PARTES reconocen el alcance y efectos del Contrato, obligándose en todos y cada uno de los
                términos
                asentados en el mismo y, por lo tanto, el <span class="weight">ACREDITADO</span> al ingresar su
                contraseña y firmar electrónicamente
                este Contrato, otorgará su conformidad con el mismo y sus efectos. Se considera el Lugar y fecha
                para
                todos
                los efectos legales el de la firma del Contrato.</p>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'

    export default {
        name: 'loan',
        data() {
            return {
                activeNav: 'loan',
                //apiHead: 'http://124.71.151.243:9090/h5/',
                apiHead: 'https://apih5.rapidayuda.com/h5/',
                access_token: '',
                originLoanAmount: null,
                loanPeriod: null,
                loanEntrance: null,
                isReloan: null,
                couponId: 0,
                productId: 0,
                data: {}
            }
        },
        mounted() {
            this.getAndroid()
            this.getContractGet()
        },
        methods: {
            getAndroid() {
                let getloanDays = this.$store.state.common.loanDays;
                let useloanDays = JSON.parse(getloanDays);

                let getcoupons = this.$store.state.common.coupons;
                let setcoupons = null;
                if(getcoupons){
                    setcoupons = JSON.parse(getcoupons);
                }

                let usecoupons = null;

                if(setcoupons && setcoupons.couponIdStr){
                    usecoupons = setcoupons.couponIdStr
                }


                console.log(usecoupons)
                console.log(useloanDays.idStr)
                this.access_token = this.$store.state.common.accessToken;
                this.originLoanAmount = useloanDays.max_amount; //获取原始贷款金额
                this.loanPeriod = useloanDays.max_period; //获取贷款期限
                //this.loanEntrance = window.android.getLoanEntrance() //借款协议入口 1首贷 2复贷 3降额
                this.loanEntrance = 2;
                this.isReloan = 1; //首贷还是复贷0(首贷) 1（复贷）
                this.couponId = usecoupons;  //优惠券ID
                this.productId = useloanDays.idStr  //产品ID
            },
            //用户获取合同
            getContractGet() {
                let postData = {};
                if(this.couponId){
                    postData = {
                        access_token: this.access_token,
                        originLoanAmount: Number(this.originLoanAmount),
                        loanPeriod: Number(this.loanPeriod),
                        loanEntrance: Number(this.loanEntrance),
                        isReloan: Number(this.isReloan),
                        couponId: this.couponId,
                        productId: this.productId,
                        sysCode: "Rapidayuda"
                    }
                } else {
                    postData = {
                        access_token: this.access_token,
                        originLoanAmount: Number(this.originLoanAmount),
                        loanPeriod: Number(this.loanPeriod),
                        loanEntrance: Number(this.loanEntrance),
                        isReloan: Number(this.isReloan),
                        productId: this.productId,
                        sysCode: "Rapidayuda"
                    }
                }
                axios.post(this.apiHead + 'v2/account/contract/get', postData).then(res => {
                    console.log(res)
                    if (res.data.code == 0) {
                        this.data = res.data.data
                    }
                })
            }
        }
    }
</script>

<style>


    .loan_wrap{
        display: flex;
        min-height: 100vh;
        flex-direction: column;
        max-width: 100vw;
        position: relative;
        padding: .25rem;
        box-sizing: border-box;
        overflow-y: auto;
    }
    .loan_wrap h1 {
        font-size: .2rem;
    }

    .loan_wrap .title {
        text-align: center;
        margin-bottom: .25rem;
    }

    .loan_wrap table {
        margin-top: .25rem;
    }

    .loan_wrap tr,
    .loan_wrap p,
    .loan_wrap .p_div {
        font-size: .14rem;
    }

    .loan_wrap td {
        text-align: center;
    }

    .loan_wrap .sun_title {
        text-align: left;
        background: #9cc2e5;
        color: #fff;
    }

    .loan_wrap .weight {
        font-weight: 600;

    }

    .loan_wrap .tr_one div {
        display: flex;
    }

    .loan_wrap .tr_one div p:nth-child(1) {
        margin-right: 2rem;
    }

    .loan_wrap .tr_two td {
        text-align: left;
        height: 1rem;
    }

    .loan_wrap .tr_three td {
        text-align: left;
        height: 1rem;
    }

    .loan_wrap .container {
        padding: 0 .2rem;
    }

    .p_title {
        text-align: center;
        font-weight: 600;
        margin: .2rem 0;
    }
    .p_title_new {
        text-align: center;
        font-weight: 600;
        margin: 0 auto;
    }

    .p_div p,
    .p_div .p_sun_div,
    .p_sun_div p {
        margin-left: .2rem;
    }
    .cont-left{
        box-sizing: border-box;
        padding-left: 10px;
    }
    .loan_wrap table p{
        box-sizing: border-box;
        padding: 0.1rem 0 0 0.1rem;
    }
</style>
