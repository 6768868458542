<template>
  <div class="headNav">
    <div class="head">
      <div class="mainContent">
        <img
          @click="getActive('Inicio')"
          class="logo"
          src="@/assets/image/logo.png"
          alt=""
        />
        <ul class="nav">
          <li :class="activeNav == 'Inicio' ? 'active' : ''">
            <a @click="getActive('Inicio')">Inicio</a>
          </li>
          <li :class="activeNav == 'sobre' ? 'active' : ''">
            <a @click="getActive('sobre')">Sobre nosotros</a>
          </li>
          <li :class="activeNav == 'Aviso' ? 'active' : ''">
            <a @click="getActive('Aviso')">Aviso de privacidad</a>
          </li>
          <li :class="activeNav == 'ayuda' ? 'active' : ''">
            <a @click="getActive('ayuda')">Ayuda</a>
          </li>
          <li :class="activeNav == 'loan' ? 'active' : ''">
            <a @click="getActive('loan')">Términos y condiciones</a>
          </li>
          <li
            :class="activeNav == 'transparencia' ? 'active' : ''"
            class="li_blog"
          >
              <el-dropdown
                      style="float: left;"
                      trigger="click"
                      :append-to-body="false"
                      @command="handleCommand"
              >
                <a class="el-dropdown-link">
                  Blog <img src="../assets/image/transparenciaTwo_six.png" />
                </a>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                          v-for="(item, index) in list"
                          :key="index"
                          :command="index"
                          :class="active_blog == index ? 'active_color' : ''"
                  >
                    {{ item }}
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import "./scss/nav.scss";

export default {
  name: "headNav",
  props: ["activeNav"],
  data() {
    return {
      // active: "Inicio",
      active_blog: 0,
      list: [
        "Transparencia",
        "Derechos y obligaciones de cobranza",
        "Aviso importante",
      ],
    };
  },
  created() {
    if (sessionStorage.getItem("tip")) {
      this.list.forEach((item, index) => {
        if (index == sessionStorage.getItem("tip")) {
          this.active_blog = sessionStorage.getItem("tip");
        }
      });
    }
  },
  methods: {
    getActive(val, index) {
      let url = "";
      switch (val) {
        case "Inicio":
          url = "/home?dialogVisible=1";
          break;
        case "sobre":
          url = "/about";
          break;
        case "Aviso":
          url = "/notice";
          break;
        case "ayuda":
          url = "/help";
          break;
        case "loan":
          url = "/loan";
          break;
        case "transparenciaOne":
          url = "/transparenciaOne";
          break;
        case "transparenciaTwo":
          url = "/transparenciaTwo";
          break;
        case "transparenciaThree":
          url = "/transparenciaThree";
          break;
      }
      this.$router.push(url);
      sessionStorage.setItem("tip", index);
    },
    handleCommand(command) {
      if (command == 0) {
        this.getActive("transparenciaOne", command);
      } else if (command == 1) {
        this.getActive("transparenciaTwo", command);
      } else {
        this.getActive("transparenciaThree", command);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.li_blog {
  display: flex;
  align-items: center;
  .el-dropdown-link {
    display: flex;
    align-items: center;
    cursor: pointer;
    img {
      width: 12px;
      height: 8px;
      margin-left: 10px;
    }
  }
}
.el-dropdown-menu {
  padding: 0;
  li {
    width: 320px;
    background: #fff;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
    text-align: center;
    padding: 20px 0;
    box-sizing: border-box;
    &:first-child {
      border-bottom: 1px solid #edecec;
    }
    &:hover {
      color: #1c83ff;
    }
  }
  .active_color {
    color: #1c83ff;
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
  }
}
</style>
