
<template>
    <div>
        <div class="mobile_wrap" v-if="topNavShow">
            <mobNav :activeNav="activeNav"></mobNav>
        </div>

        <div class="mainview_itoken_wrap">
            <div class="bank_cont_view">
                <div class="bank_conttop">

                    <div class="bank_ctitems" @click="onCheckitem('1')">
                        <div class="bank_ctitem" :class="isCheck == '1' ? 'bank_ctitem_active' : ''">
                            <div class="bank_ctileft">
                                <template v-if="bankoneval == null">
                                    Elija el tipo de cuenta
                                </template>
                                <template v-else>
                                    {{bankoneval.text}}
                                </template>
                            </div>
                            <div class="bank_ctiright">
                                <div class="bank_ctirview"><img src="@/assets/mobimg/comf_rparrow.png" alt=""></div>
                            </div>
                        </div>
                    </div>




                    <div class="bank_ctitems" @click="onCheckitem('2')">
                        <div class="bank_ctitem" :class="isCheck == '2' ? 'bank_ctitem_active' : ''">
                            <div class="bank_ctileft">
                                <template v-if="banktwoval == null">
                                    Nombre de banco
                                </template>
                                <template v-else>
                                    {{banktwoval.text}}
                                </template>
                            </div>
                            <div class="bank_ctiright">
                                <div class="bank_ctirview"><img src="@/assets/mobimg/comf_rparrow.png" alt=""></div>
                            </div>
                        </div>
                    </div>
                    <div class="bank_ctitems" @click="onCheckitem('3')">
                        <div class="bank_ctitem" :class="isCheck == '3' ? 'bank_ctitem_active' : ''">
                            <input v-model="bank_no" class="bank_code" type="number" />
                        </div>
                    </div>
                </div>
                <div class="bank_contmiddle">
                    <p style="line-height: 18px;">sugerencia:</p>
                    <p>Por favor complete los datos correctos de su tarjeta bancaria</p>
                    <p>El monto final de su aprobación de préstamo finalmente se cargará a la cuenta bancaria</p>
                </div>
                <div class="bank_contdown" @click="onConfirm">
                    <van-button round color="#00CA88" block>
                        <span style="font-size: 0.444444rem; font-weight: bold;">Solicitar</span>
                    </van-button>
                </div>
            </div>
        </div>


        <van-popup v-model="bankoneShow" position="bottom">
            <div class="bankpop_wrap">
                <van-picker
                        title="Elija el tipo de cuenta"
                        show-toolbar
                        :columns="onecolumns"
                        @confirm="onConfirmOne"
                        @cancel="onCancelOne"
                        confirm-button-text="confirmación"
                        cancel-button-text="cancelación"
                />
            </div>
        </van-popup>

        <van-popup v-model="banktwoShow" position="bottom">
            <div class="bankpop_wrap">
                <van-picker
                        title="Nombre de banco"
                        show-toolbar
                        :columns="twocolumns"
                        @confirm="onConfirmTwo"
                        @cancel="onCancelTwo"
                        confirm-button-text="confirmación"
                        cancel-button-text="cancelación"
                />
            </div>
        </van-popup>


    </div>
</template>

<script>

    import mobNav from "@/components/mobNav.vue";
    import scrollUpdown from "@/mixins/index";

    import { bankList, updateCard } from '@/api/loan';

    import { loadingShow, loadingHide } from '@/utils/loading'


    export default {
        name: 'compoundbank',
        components: { mobNav },
        mixins: [scrollUpdown],
        data() {
            return {
                activeNav: 'living',
                isCheck: '1',
                bankoneShow: false,
                bankoneval: {
                    type: 1,
                    text: 'CLABE'
                },
                onecolumns: [
                    {
                        type: 1,
                        text: 'CLABE'
                    },
                    {
                        type: 2,
                        text: 'Tarjeta de débito'
                    },
                    {
                        type: 3,
                        text: 'Spin Clabe'
                    }
                ],
                banktwoShow: false,
                banktwoval: null,
                twocolumns: [],
                bank_no: null,
                bankmessage: null,
            }
        },
        mounted() {
            let getbankData = this.$store.state.common.bankData;
            let usebankData = null;
            if(getbankData){
                usebankData = JSON.parse(getbankData);
                this.bankmessage = usebankData;
                this.bank_no = usebankData.bank_no;

                console.log(usebankData)
                console.log(usebankData.bank_card_type)
                let useonecolumns = this.onecolumns;

                for(let i=0; i<useonecolumns.length; i++){
                    if(useonecolumns[i].type == usebankData.bank_card_type){
                       this.bankoneval = useonecolumns[i]
                    }
                }
            }


            this.getBankList();


        },
        methods: {
            getBankList() {
                let _this = this;
                let usebankmessage = this.bankmessage;
                let usebank_name = null;
                if(usebankmessage && usebankmessage.bank_name){
                    usebank_name = usebankmessage.bank_name;
                }

                let useaccessToken = this.$store.state.common.accessToken;
                let usepageType = this.$store.state.common.pageType;
                let postData = {
                    access_token: useaccessToken,
                    "source":"Rapidayuda",
                    channel: usepageType,
                    bankType: _this.bankoneval.type,
                };

                bankList(postData).then(res => {
                    console.log(res)
                    if(res.code == 0){
                        let useData = res.data;
                        let bankList = useData.bankList;
                        if(bankList && bankList.length){
                            for(let i=0; i<bankList.length; i++){
                                bankList[i].text = bankList[i].bankName;

                                if(usebank_name){
                                    if(bankList[i].bankName == usebank_name){
                                        _this.banktwoval = bankList[i];
                                    }
                                }
                            }
                            _this.twocolumns = bankList;
                        } else {
                            _this.twocolumns = [];
                        }
                    }
                })
            },
            onCheckitem(type){
                this.isCheck = type;
                if(type == '1'){
                    this.bankoneShow = true;
                } else if(type == '2'){
                    this.banktwoShow = true;
                }
            },
            onConfirmOne(value) {
                this.bankoneval = value;
                this.bankoneShow = false;
                this.getBankList();
            },
            onCancelOne() {
                this.bankoneval = null;
                this.bankoneShow = false;
            },
            onConfirmTwo(value) {
                this.banktwoval = value;
                this.banktwoShow = false;
            },
            onCancelTwo() {
                this.banktwoval = null;
                this.banktwoShow = false;
            },
            onConfirm() {
                let _this = this;
                console.log(this.bankoneval)
                console.log(this.banktwoval)
                console.log(this.bank_no)

                let bank_card_type = this.bankoneval.type;
                let bank_name = this.banktwoval.bankName;
                let bank_no = this.bank_no;


                let useaccessToken = this.$store.state.common.accessToken;
                let usepageType = this.$store.state.common.pageType;
                let postData = {
                    access_token: useaccessToken,
                    "source":"Rapidayuda",
                    channel: usepageType,
                    bank_card_type: String(bank_card_type),
                    bank_name,
                    bank_no,
                    reconfirm: "1",
                };

                loadingShow()

                updateCard(postData).then(res => {
                    console.log(res)
                    loadingHide();
                    if(res.code == 0){
                        _this.$router.push({
                            path: '/compoundloan/confirmloan'
                        })
                    }
                },error => {
                    loadingHide()
                })

            }
        }
    }
</script>

<style>
    .bank_cont_view{
        width: 100%;
        height: auto;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        padding: 0.888889rem 0.444444rem 0 0.444444rem;
    }
    .bank_conttop{
        width: 100%;
        height: auto;
    }
    .bank_ctitems{
        width: 100%;
        height: 1.555556rem;
    }
    .bank_ctitem{
        width: 100%;
        height: 1.111111rem;
        box-sizing: border-box;
        background: #ffffff;
        border-radius: 0.333333rem;
        border: 1px solid #DEE0E6;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .bank_ctitem_active{
        border: 1px solid #00CA88;
    }
    .bank_ctileft{
        width: auto;
        height: 14px;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        padding-left: 0.444444rem;
        font-size: 13px;
        font-weight: 500;
        color: #1A1A1A;
    }
    .bank_ctiright{
        width: auto;
        height: 14px;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        padding-right: 0.444444rem;
    }
    .bank_ctirview{
        width: 10px;
        height: 14px;
    }
    .bank_ctirview img{
        display: block;
        width: 10px;
        height: 14px;
    }
    .bank_ctitem_active .bank_ctileft{
        color: #1A1A1A;
    }
    .bank_ctitem .bank_code{
        display: block;
        width: 90%;
        height: 0.8rem;
        outline: none;
        border: none;
        background: #ffffff;
        border-radius: 0.333333rem;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        padding-left: 0.444444rem;
        font-size: 13px;
        font-weight: 500;
        color: #1A1A1A;
    }
    .bank_ctitem_active .bank_code{
        color: #1A1A1A;
    }
    .bank_contmiddle{
        width: 100%;
        height: auto;
    }
    .bank_contmiddle p{
        width: 100%;
        height: auto;
        font-size: 12px;
        font-weight: 500;
        color: #808080;
        line-height: 16px;
    }
    .bank_contdown{
        width: 100%;
        height: auto;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        padding: 0 0.444444rem 0.472222rem 0.444444rem;
        position: fixed;
        left: 0;
        bottom: 0;
    }


</style>
