
<template>
    <div class="detail_wrap">
        <div class="detail_cont">
            <div class="detail_cview">
                <div class="detail_cvtitle">
                    Referencia de pago
                </div>
                <div class="detail_cvcopys">
                    <div class="detail_cvcopy">
                        <div class="detail_cvcopyleft">
                            <span>{{code}}</span>
                        </div>
                        <div class="detail_cvcopyright" @click="copy(code)">Copiar</div>
                    </div>
                </div>
                <div class="detail_cvqrccode">
                    <div class="detail_cvqrccode_view">
                        <svg id="barcode"></svg>
                    </div>
                </div>

                <div class="detail_cvttitle">Monto designado</div>
                <div class="detail_cvtamount">
                    <div class="detail_cvtamitems">
                        <div class="detail_cvtamileft">Pendiente de pago</div>
                        <div class="detail_cvtamiright">$<span v-if="onlineinfo">{{onlineinfo.unpaid_amount}}</span></div>
                    </div>
                    <div class="detail_cvtamitems">
                        <div class="detail_cvtamileft">Comisión</div>
                        <div class="detail_cvtamiright">$<span v-if="onlineinfo">{{onlineinfo.handling_fee}}</span></div>
                    </div>
                </div>

                <div class="detail_cvtamawran">
                    <div class="repayment_mtthr">
                        <div class="repayment_mtthr_cont detail_mtthr_cont">
                            <div class="repayment_mtthr_cleft">
                                <div class="repayment_mtthr_clicon">
                                    <img src="@/assets/mobimg/online_warn_icon.png" alt="">
                                </div>
                            </div>
                            <div class="repayment_mtthr_cright" v-if="onlineinfo">
                                {{onlineinfo.offline_des}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="detail_cview detail_cview_new" style="padding: 16px 0;" v-if="onlineinfo">
                <img :src="onlineinfo.support_type_pic" alt="">
            </div>
            <div class="detail_cvtitle_nres"></div>
        </div>
    </div>
</template>

<script>

    function dateFormatL(fmt, date) {
        let ret;
        const opt = {
            "Y+": date.getFullYear().toString(), // 年
            "m+": (date.getMonth() + 1).toString(), // 月
            "d+": date.getDate().toString(), // 日
            "H+": date.getHours().toString(), // 时
            "M+": date.getMinutes().toString(), // 分
            "S+": date.getSeconds().toString() // 秒
            // 有其他格式化字符需求可以继续添加，必须转化成字符串
        };
        for (let k in opt) {
            ret = new RegExp("(" + k + ")").exec(fmt);
            if (ret) {
                fmt = fmt.replace(ret[1], (ret[1].length == 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")))
            }
        }
        return fmt;
    }

    import axios from 'axios'

    import JsBarcode from 'jsbarcode'

    import { loadingShow, loadingHide } from '@/utils/loading'


    export default {
        name: 'repaymentdetail',
        data() {
            return {
                activeNav: 'repaymentdetail',
                apiHead: 'https://api.rapidayuda.com/iris/', //生产admin
                data: {},
                code: '',
                msg: '',
                onlineinfo: null,
            }
        },
        mounted() {
            this.getPayCode()
        },
        filters: {
            formatDateHms: function(time) {
                if (time != null && time != ""&& time != 0) {
                    var date = new Date(time);
                    return dateFormatL("HH:MM dd/mm/YYYY", date);
                } else {
                    return "";
                }
            },
            hidePhone(val) {
                if (val) {
                    return `${val.substring(0, 6)}***${val.substring(val.length - 4)}`;
                }
            }
        },
        methods: {
            getQueryVariable(variable) {
                var query = window.location.search.substring(1);
                var vars = query.split("&");
                for (var i = 0; i < vars.length; i++) {
                    var pair = vars[i].split("=");
                    if (pair[0] == variable) { return pair[1]; }
                }
                return (false);
            },
            getPayCode() {
                /*let code = this.getQueryVariable(code);
                console.log(code)
                this.code = code;*/

                let onlinecode = this.$store.state.common.onlinecode;

                if(onlinecode){
                    let useonlinecode = JSON.parse(onlinecode);

                    console.log(useonlinecode)

                    this.code = useonlinecode.repay_code;
                    this.onlineinfo = useonlinecode;

                    let options = {
                        widht: 100,
                        height: 70,
                        background: 'rgba(255,255,255,.0)',
                    }

                    if(useonlinecode.repay_code){
                        JsBarcode('#barcode', useonlinecode.repay_code, options)
                    }

                }



                /*axios.get(this.apiHead + 'custom/pay_code?code=' + code).then(res => {
                    this.code = res.data.code
                    if (res.data.code == 0) {
                        this.data = res.data.data
                        let options = {
                            widht: 100,
                            height: 70,
                            background: 'rgba(255,255,255,.0)',
                        }
                        if (this.data.is_online == 2) {
                            JsBarcode('#barcode', this.data.pay_code, options)
                        }
                    } else {
                        this.msg = res.data.msg
                    }
                })*/


            },
            copy(value) {
                var domUrl = document.createElement("input");
                domUrl.value = value;
                domUrl.id = "creatDom";
                document.body.appendChild(domUrl);
                domUrl.select(); // 选择对象
                document.execCommand("Copy"); // 执行浏览器复制命令
                let creatDom = document.getElementById("creatDom");
                creatDom.parentNode.removeChild(creatDom);
                this.$toast("Copia con éxito");
                return;
            },
            //去借款
            goLink() {
                window.location.href = this.data.app_url
            }
        }
    }
</script>

<style>
    .detail_wrap{
        width: 100%;
        height: auto;
        background: #F6F6F6 url(../../assets/mobimg/detail_banner.png) no-repeat top right;
        background-size: 100%;
    }
    .detail_cview_new{
        width: 100%;
        margin: 0.444444rem auto 0 auto;
    }
    .detail_cvtitle_nres{
        width: 100%;
        height: 20px;
        background: #F6F6F6;
    }
</style>
