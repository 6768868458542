<template>
  <div class="Sobre">
    <headNav :activeNav="'sobre'"></headNav>
    <div class="p1">
        <img src="@/assets/image/2_banner.png" alt="">
      </div>
      <div style="margin:0 auto; ">
        <div class="bigContent">

      <div class="content clearfix">
        <div class="left">
          <div class="title">Perfil de la empresa</div>
          <span><u>Rapidayuda</u></span> es una plataforma que ofrece préstamos en línea rápidos, accesibles y seguros. Regístrate en segundos, aplica para un préstamo y recibe tu efectivo en minutos.Vemos el mundo diferente. Estamos motivados por una creencia fundamental en las personas, y trabajamos juntos para demostrar su potencial. Asumimos riesgos que otros no tomarían para poner más poder en las manos de nuestros clientes.
        </div>
        <div class="right">
          <img src="@/assets/image/about_img.png" alt="">
        </div>
      </div>

    </div>
      </div>
      <div class="p6">
      <div class="mainContent" style="text-align:center">
        <div class="title">
          <img src="../assets/image/kf_ico.png" alt="" />Contáctanos
        </div>
        <p class="item-title">
          Si necesitas ayuda, envíanos un correo o llamar a nuestro teléfono de
          atención al cliente.
        </p>
        <p class="item-title">
          INTERVALO FINANZAS, SOCIEDAD ANÓNIMA DE CAPITAL VARIABLE
        </p>
        <ul>
          <li>
            <img src="../assets/image/sj_ico.png" alt="">
            <div>
              <p>Atención al Cliente：</p>
              <p style="color:#333">5585265290</p>
            </div>
          </li>
           <li>
            <img src="../assets/image/ico_2.png" alt="">
            <div>
              <p>Email：</p>
              <p style="color:#333">rapidatc@gmail.com</p>
            </div>
          </li>
           <li>
            <img src="../assets/image/tm_ico.png" alt="">
            <div>
              <p>Tiempo laboral：</p>
              <p style="color:#333">De lunes a viernes 9:00-18:40</p>
              <p style="color:#333">De sábado a domingo 9:00-14:40</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <footPage></footPage>

  </div>
</template>

<script>
import headNav from "@/components/headNav.vue";
import footPage from "@/components/foot.vue";

import "../components/scss/sobre.scss";

export default {
  name: "Sobre",
  components: { headNav, footPage },
  data() {
    return {

    }
  }
}
</script>

<style>

</style>
